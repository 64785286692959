import trainingTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...trainingTypes };

//my-appeals

export const getMyRequests = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get("/api/training/my-requests");

      dispatch({
        type: types.SET_TRAINING_REQUESTS,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getSingleTrainingRequest = (
  referenceNo,
  setLoading,
  setShowTrainingRequestDetails
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/requests/" + referenceNo);

      dispatch({ type: types.SET_TRAINING_REQUEST, data });
      setLoading(false);
      setShowTrainingRequestDetails(true);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
};

export const downloadTrainingAuthorization = (
  referenceNo,

  lastName,
  firstName,
  setIsDownloading
) => {
  return async (dispatch) => {
    setIsDownloading(true);
    try {
      const { data } = await axios.get(
        "/api/training/download-authorization/" + referenceNo,
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `Training Authorization - ${lastName.toUpperCase()} ${firstName}(${referenceNo}).pdf`,
        ".pdf"
      );

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};
