import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  login,
  selectModule,
  resetPassword,
  logout,
  selectEntitySector,
} from "../store/common/actions";

import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

import { isEmpty } from "lodash";
import WelcomePage from "./dashboard/pages/Dashboard";
import WelcomePageMenuBar from "./dashboard/components/MenuBar";
import axios from "axios";

const Home = (props) => {
  const {
    user,
    loading,
    ippisModules,
    login,
    resetPassword,
    logout,
    selectModule,
    selectEntitySector,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);
 

  useEffect(
    () => {
      // if( !isEmpty(user)  )  history.push('/home');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
    ipAddress: '',
  });

  const onLogin = async (e) => {
    e.preventDefault();
    login(
      { email: credentials.email, password: credentials.password,ipAddress: credentials.ipAddress },
      history
    );
  };
  useEffect(() => {
    getClientIp();
  }, []);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);
      setCredentials({
        ...credentials,
        ipAddress: data,
      })
    } catch (error) {}
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (credentials.email.length)
      resetPassword(credentials.email, (loading, status) => {
        setOnWait(loading);
        setWasReset(status);
      });
  };

  return (
    <React.Fragment>
      {isEmpty(user) && (
        <div className="container-fluid px-0">
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          </div>

          <div
            className="row justify-content-center pt-3 mx-0 px-0 text-center"
            style={{
              marginTop: "-142px",
            }}
          >
            {!forgotPassword && (
              <div
                className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                <form onSubmit={onLogin}>
                  <h1 className="h3 mb-3 font-weight-normal">Login</h1>

                  <FormControl fullWidth variant="outlined" className="mb-3">
                    <InputLabel htmlFor="email">Enter your email</InputLabel>
                    <OutlinedInput
                      // size="small"
                      style={{
                        // height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      id="email"
                      placeholder="Email"
                      type="text"
                      name="email"
                      value={credentials.email}
                      onChange={(e) => {
                        const email = e.target.value;
                        setCredentials({ ...credentials, email });
                      }}
                      label="Enter your email"
                    />
                  </FormControl>

                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      // size="small"
                      style={{
                        // height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      id="password"
                      placeholder="Password"
                      name="password"
                      type={credentials.showPassword ? "text" : "password"}
                      value={credentials.password}
                      onChange={(e) => {
                        const password = e.target.value;
                        setCredentials({ ...credentials, password });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setCredentials({
                                ...credentials,
                                showPassword: !credentials.showPassword,
                              })
                            }
                            edge="end"
                          >
                            {credentials.showPassword ? (
                              <span className="material-icons">
                                visibility_off
                              </span>
                            ) : (
                              <span className="material-icons">visibility</span>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>

                  <div className="text-right">
                    <Button
                      onClick={() => setForgotPassword(true)}
                      className="mb-3 pr-0"
                    >
                      Forgot password?
                    </Button>
                  </div>

                  {!loading && (
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      disableElevation
                      className="btn btn-block"
                      size="large"
                    >
                      Login
                    </Button>
                  )}

                  {loading && (
                    <Button
                      variant="contained"
                      disableElevation
                      className="btn btn-block"
                      size="large"
                    >
                      Connecting...
                    </Button>
                  )}

                  <p className="mt-4 mb-3 text-muted">
                    If you don't remember your email. <br />
                    Contact your HR for help.
                  </p>
                </form>
              </div>
            )}

            {forgotPassword && (
              <div className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6">
                <h1 className="h3 mb-3 font-weight-normal">Reset password</h1>
                {wasReset && (
                  <Alert severity="success" className="mb-3">
                    <AlertTitle>
                      Please, check your email to continue.
                    </AlertTitle>
                    The link to reset your password was sent to your email.
                  </Alert>
                )}

                {!wasReset && (
                  <form onSubmit={handleReset}>
                    <TextField
                      // size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      label="Enter your email"
                      placeholder="Email"
                      className="mb-3"
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value,
                        })
                      }
                      disabled={loading || onWait}
                    />

                    {!onWait && (
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || credentials.email.length === 0}
                      >
                        Reset password
                      </Button>
                    )}

                    {onWait && (
                      <Button
                        type="button"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || onWait}
                      >
                        Connecting...
                      </Button>
                    )}
                  </form>
                )}

                <Button
                  onClick={() => {
                    setForgotPassword(false);
                    setOnWait(false);
                    setWasReset(false);
                  }}
                  className="pr-0 mt-3"
                  disabled={loading || onWait}
                >
                  <span className="material-icons">
                    keyboard_double_arrow_left
                  </span>{" "}
                  Back to Login
                </Button>

                {!wasReset && (
                  <p className="mt-4 text-muted">
                    If you don't remember your email. <br />
                    Contact your HR for help.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {/* {!isEmpty(user) && (
        <>
          <WelcomePageMenuBar />
          <WelcomePage />
        </>
      )} */}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, isFetchingUser, loading, ippisModules }) => {
  return { user, isFetchingUser, loading, ippisModules };
};
export default connect(mapStateToProps, {
  login,
  resetPassword,
  logout,
  selectModule,
  selectEntitySector,
})(Home);
