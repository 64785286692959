import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";


import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/home" component={Dashboard} />
        <Route
          path="/home"
          component={() => <Redirect to="/home" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
