import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { approveResponseToExplanationLetter } from "../../../store/discipline/actions";
import {
  getFaultDictionaryFormat,
  textCapitalized,
} from "../../common/components/Utils";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SignLetter from "../../Documents/LetterFormat/SignLetter";
import DisciplinaryRespondToExplanationLetter from "../../Documents/LetterFormat/DisciplinaryRespondToExplanationLetter";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
const maxCharLength = 1000;
const RespondExplanationLetterDialog = (props) => {
  const {
    loading,
    showRespondToExplanationLetter,
    setShowRespondToExplanationLetter,
    caseRequest,
    faults,
    caseRequestFaults,
    closeModal,
    approveResponseToExplanationLetter,
  } = props;
  const [showCaseApproverForm, setShowCaseApproverForm] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(true);
  const [pkiPassword, setPkiPassword] = useState("");
  const [defaultContents2, setDefaultContents2] = useState("");
  const textareaRef = useRef(null);
  const formattingFaults = (faults, caseRequestFaults) => {
    let tmpFaults = [];

    faults.forEach((fault) => {
      if (!!caseRequestFaults.find(({ faultId }) => fault.id === faultId)) {
        tmpFaults.push(fault);
      }
    });
    return tmpFaults
      .map((fault, index) => `\n${index + 1}. ${fault.faultName}`)
      .join("");
  };

  const [defaultContents, setDefaultContents] = useState(
    `I am writing in response to your letter dated ${moment(
      caseRequest?.updatedOn || new Date()
    ).format("LL")} (Ref No: ${
      caseRequest?.requestExplanationDocLetterNo || ""
    }), requesting an explanation regarding the alleged administrative fault(s):${formattingFaults(
      faults,
      caseRequestFaults
    )}.`
  );
  const [formData, setFormData] = useState({
    caseId: caseRequest?.id || "",
    subject: "Response to Request for Explanation",
    contents: defaultContents,
    attachedFiles: [],
  });

  useEffect(() => {
    if (!!faults.length) {
      const defaultContents = `I am writing in response to your letter dated ${moment(
        caseRequest?.updatedOn || new Date()
      ).format("LL")} (Ref No: ${
        caseRequest?.requestExplanationDocLetterNo || ""
      }), requesting an explanation regarding the alleged administrative fault(s):${formattingFaults(
        faults,
        caseRequestFaults
      )}. 
..........................................................................................................................................................................
..........................................................................................................................................................................

Thank you for your attention to this matter.`;

      setDefaultContents(defaultContents);
      setDefaultContents2(
        `I am writing in response to your letter dated ${moment(
          caseRequest?.updatedOn || new Date()
        ).format("LL")} (Ref No: ${
          caseRequest?.requestExplanationDocLetterNo || ""
        }), requesting an explanation regarding the alleged administrative fault(s):${formattingFaults(
          faults,
          caseRequestFaults
        )}.`
      );
      setFormData({
        ...formData,
        contents: defaultContents,
      });
      // setFilteredFaults(_tmpFaults);
    }
  }, [caseRequest, faults]);

  useEffect(() => {
    // Initial height adjustment if there's any content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  const [errors, setErrors] = useState({
    subjectError: false,
    hasError: false,
    docContentHasError: false,
    docContentHasError2: false,
    docContentHasError3: false,
  });

  const formValidator = () => {
    const error = {
      subjectError: false,
      hasError: false,
      docContentHasError: false,
      docContentHasError2: false,
      docContentHasError3: false,
    };
    if (!formData.subject) {
      error.subjectError = true;
      error.hasError = true;
    }

    if (!formData.contents) {
      error.docContentHasError = true;
      error.hasError = true;
    }

    if (formData.contents === defaultContents) {
      error.docContentHasError2 = true;
      error.hasError = true;
    }
    if (formData.contents === defaultContents2) {
      error.docContentHasError2 = true;
      error.hasError = true;
    }
    if (formData.contents.length > maxCharLength) {
      error.docContentHasError3 = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill the required field");
      return true;
    }
    return false;
  };

  const onCloseAll = () => {
    setShowRespondToExplanationLetter(false);
    closeModal();
  };
  const onSave = () => {
    if (!formValidator()) {
      approveResponseToExplanationLetter(formData, onCloseAll);
    }
  };
  const onClose = () => {
    setShowRespondToExplanationLetter(false);
  };

  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRespondToExplanationLetter}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>My Explanation Letter</span> /{" "}
            <span className="text-primary">
              {textCapitalized(caseRequest?.lastName)}
              {textCapitalized(caseRequest?.firstName)}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <DisciplinaryRespondToExplanationLetter
              recipientAddress={caseRequest.address}
              recipientName={caseRequest.lastName + " " + caseRequest.firstName}
              recipientEmail={caseRequest.email}
              recipientPhone={caseRequest.phoneNumber}
              institutionName={caseRequest.entityName}
              enableToFillLetter={enableToFillLetter || false}
              subject={formData.subject}
              contents={formData.contents}
              headOfInstitutionPosition={
                caseRequest.approverPosition2 === "Minister"
                  ? caseRequest.approverPosition
                  : caseRequest.approverPosition2
              }
              headOfInstitutionEntity={
                caseRequest.approverPosition2 === "Minister"
                  ? ""
                  : caseRequest.approverEntity
              }
              subTitle={"Dear " + caseRequest.approverPosition2}
              headOfInstitutionAddress={caseRequest.approverLocation}
              subjectInput={
                <>
                  <Input
                    autoFocus
                    error={!!errors.subjectError}
                    value={formData.subject}
                    style={{ width: "90%" }}
                    inputProps={{
                      "aria-label": "add subject",
                    }}
                    onChange={(e) => {
                      const subject = e.target.value;
                      setFormData({
                        ...formData,
                        subject,
                      });

                      setErrors({
                        ...errors,
                        subjectError: false,
                      });
                    }}
                  />
                </>
              }
              contentsInput={
                <>
                  <TextField
                    autoFocus
                    error={
                      !!errors.docContentHasError ||
                      !!errors.docContentHasError2 ||
                      !!errors.docContentHasError3
                    }
                    value={formData.contents}
                    style={{ width: "100%" }}
                    inputProps={{
                      "aria-label": "add contents",
                    }}
                    inputRef={textareaRef}
                    multiline
                    rows={10}
                    onChange={(e) => {
                      const contents = e.target.value;
                      setFormData({
                        ...formData,
                        contents,
                      });

                      setErrors({
                        ...errors,
                        docContentHasError: false,
                        docContentHasError2: false,
                        docContentHasError3: false,
                      });
                      // Adjust height of the textarea
                      if (textareaRef.current) {
                        textareaRef.current.style.height = "auto";
                        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                      }
                    }}
                    onClick={(e) => {
                      const contents = e.target.value;
                      if (defaultContents === contents) {
                        setFormData({
                          ...formData,
                          contents: defaultContents2,
                        });
                      }

                      // setErrors({
                      //   ...errors,
                      //   docContentHasError: false,
                      //   docContentHasError2: false,
                      // });
                    }}
                  />
                  {!!errors.docContentHasError2 && (
                    <div className="text-left mt-1">
                      <span className="text-danger">
                        Please change the default contents in the letter
                      </span>
                    </div>
                  )}
                  {!!errors.docContentHasError3 && (
                    <div className="text-left mt-1">
                      <span className="text-danger">
                        This above content character(s) should not great than{" "}
                        {maxCharLength}
                      </span>
                    </div>
                  )}
                </>
              }
              // effectiveDate={moment(formData.effectiveOn).format("LL")}
              competentAuthorityName={
                caseRequest.lastName + " " + caseRequest.firstName
              }
              competentAuthorityPosition={caseRequest.positionName}
              competentAuthorityUnit={caseRequest.unitName}
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        position: "relative",

                        width: "20%",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {!enableToFillLetter ? (
                        <>
                          <SignLetter />
                        </>
                      ) : (
                        <>
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </>
                      )}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
            />
            <div className="mt-2">

            <WidgetUploadComponent
              onSuccess={onUploadSuccess}
              onErrors={onUploadErrors}
              multiple={true}
              acceptedFileSize="1074176"
              acceptedFileTypes="pdf"
              label="Upload supporting document *(pdf)"
            />
            
            </div>
           
          </Box>
        </DialogContent>

        <DialogActions className="d-flex flex-row justify-content-center py-2">
          {enableToFillLetter && (
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={loading}
              onClick={() => {
                if (!formValidator()) {
                  setEnableToFillLetter(false);
                }
              }}
              className="btn btn-xs"
            >
              Next
            </Button>
          )}
          {!enableToFillLetter && (
            <>
              <span>
                <Button
                  variant="contained"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (textareaRef.current) {
                      textareaRef.current.style.height = "auto";
                      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                    }
                    setEnableToFillLetter(true);
                  }}
                  className="btn btn-xs ml-2"
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (!formValidator()) {
                      setShowCaseApproverForm(true);
                    }
                  }}
                  className="btn btn-xs ml-2"
                >
                  Sign
                </Button>
              </span>
            </>
          )}
        </DialogActions>

        {showCaseApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showCaseApproverForm}
            message={
              <>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={setShowCaseApproverForm}
            onYes={() => {
              if (!formData.subject) {
                showError("Subject is required");
                return false;
              }
              formData.password = pkiPassword;
              formData.caseStatusId = 5;
              const payload = new FormData();
              const keys = Object.keys(formData);
              for (const key of keys) {
                if (formData.hasOwnProperty(key)) {
                  if (
                    key === "attachedFiles" &&
                    formData[key] &&
                    !!formData[key].length
                  )
                    formData[key].forEach((row, i) => {
                      payload.append(key, row.file);
                    });
                  else payload.append(`${key}`, `${formData[key]}`);
                }
              }

              approveResponseToExplanationLetter(payload, onCloseAll);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  approveResponseToExplanationLetter,
})(RespondExplanationLetterDialog);
