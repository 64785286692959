import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import { getMyAppeals, setAppeal } from "../../../store/e-appeal/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import ReactPaginate from "react-paginate";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { textCapitalized } from "../../common/components/Utils";
import ViewAppealDialog from "../components/ViewAppealDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../common/components/TableSkeleton";
import ContentLayout from "../../common/components/ContentLayout";

const AppealCard = (props) => {
  const { appeal, setViewAppeal, setSelectedAppeal } = props;
  return (
    <>
      <tr
        className={"appeal-hovered elevated"}
        onClick={() => {
          setSelectedAppeal(appeal);
          setViewAppeal(true);
        }}
      >
        <td className="text-left">
          <strong> {appeal.referenceNo} </strong>
        </td>

        <td className="text-left text-truncate">{appeal.subjectL1}</td>
        <td className="text-left text-truncate">
          {textCapitalized(
            appeal.entityNameL2 ? appeal.entityNameL2 : appeal.entityNameL1
          )}
        </td>
        <td className="text-right">
          {moment(
            appeal.createdOnL2 ? appeal.createdOnL2 : appeal.createdOnL1
          ).format("lll")}
        </td>
        <td className="text-center">
          {appeal.levelL1 && appeal.levelL2 && (
            <>
              {" "}
              <span className="text-success ml-3 px-2 fw-bold">
                Second level
              </span>
              {appeal.decisionL2 && !!appeal.isValidL2 && (
                <>
                  <span className={`badge px-2 badge-success `}>Is Valid</span>
                </>
              )}
              {appeal.decisionL2 && !appeal.isValidL2 && (
                <>
                  <span className={`badge px-2 badge-danger `}>
                    Is Not Valid
                  </span>
                </>
              )}
            </>
          )}
          {appeal.levelL1 && !appeal.levelL2 && (
            <>
              <span className="text-primary ml-3 px-2 fw-bold">
                First level
              </span>
              {appeal.decisionL1 && !!appeal.isValidL1 && (
                <>
                  <span className={`badge px-2 badge-success `}>Is Valid</span>
                </>
              )}
              {appeal.decisionL1 && !appeal.isValidL1 && (
                <>
                  <span className={`badge px-2 badge-danger `}>
                    Is Not Valid
                  </span>
                </>
              )}
            </>
          )}
        </td>
        <td className="text-center">
          <span
            className={`badge px-2  ${
              appeal.statusIdL1 === 1 || appeal.statusIdL2 === 1
                ? "badge-warning"
                : appeal.statusIdL1 === 3 && appeal.statusIdL2 === 4
                ? "badge-success"
                : [2, 5].includes(appeal.statusIdL1) ||
                  [2, 5].includes(appeal.statusIdL2)
                ? "badge-primary"
                : "badge-danger"
            }`}
          >
            {appeal.statusNameL2 ? appeal.statusNameL2 : appeal.statusNameL1}
          </span>
        </td>
        
        <td className="text-right">
          <button type="button" className="btn btn-success rounded m-2">
            <OpenInNewIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

const Appeals = (props) => {
  const { myAppeals, getMyAppeals, appeals, setAppeal } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [viewAppeal, setViewAppeal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const theme = useTheme();

  const dispatch = useDispatch();
  const [filteredMyAppeals, setFilteredMyAppeals] = useState([...myAppeals]);
  const [filterTypes, setFilterTypes] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  useEffect(() => {
    dispatch({
      type: "SET_APPEAL",
      data: null,
    });
    getMyAppeals(setShowLoader);
  }, []);

  useEffect(() => {
    const filtered = [...myAppeals].filter(
      ({
        messageL1,
        messageL2,
        subjectL1,
        appealTypeNameL1,
        statusNameL1,
        statusNameL2,
      }) =>
        (messageL1 &&
          messageL1.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (messageL2 &&
          messageL2.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (subjectL1 &&
          subjectL1.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (appealTypeNameL1 &&
          appealTypeNameL1.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (statusNameL1 &&
          statusNameL1.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (statusNameL2 &&
          statusNameL2.toLowerCase().includes(searchTeam.toLowerCase()))
    );
    setFilteredMyAppeals(filtered);
  }, [searchTeam, myAppeals]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredMyAppeals);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, appeals, filteredMyAppeals]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredMyAppeals.length || 0;

    setItemOffset(newOffset);
  };

  const filterAppealTypes = () => {
    let tempFilteredAppealTypes = [];
    myAppeals.forEach((appeal) => {
      if (
        !tempFilteredAppealTypes.find(
          ({ name }) => name === appeal.appealTypeNameL1
        )
      )
        tempFilteredAppealTypes.push({
          id: appeal.appealTypeIdL1,
          name: appeal.appealTypeNameL1,
        });
    });

    return tempFilteredAppealTypes;
  };
  //filterAppealStatus

  const filterAppealStatus = () => {
    let tempFilteredAppealStatus = [];
    myAppeals.forEach((appeal) => {
      if (
        !tempFilteredAppealStatus.find(
          ({ name }) =>
            name === appeal.statusNameL2 || name === appeal.statusNameL1
        )
      )
        tempFilteredAppealStatus.push({
          id: appeal.statusIdL2 ? appeal.statusIdL2 : appeal.statusIdL1,
          name: appeal.statusNameL2 ? appeal.statusNameL2 : appeal.statusNameL1,
        });
    });

    return tempFilteredAppealStatus;
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-lg-4">
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>E-Appeal</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                My Appeals ({myAppeals.length})
              </span>
            </Typography>
          </div>

          <div className="col-lg-8 mt-2">
            <div className="row">
              <div className="col-lg-4 mt-1">
                <Autocomplete
                  size="small"
                  id="filterStatus"
                  disabled={!myAppeals.length}
                  defaultValue={null}
                  value={filterStatus}
                  options={filterAppealStatus()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, filterStatus) => {
                    setFilterStatus(filterStatus || null);
                    setSearchTeam("");
                    if (filterStatus) setSearchTeam(filterStatus.name);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, filterStatus) => (
                    <Box component="li" {...props} key={filterStatus.id}>
                      {filterStatus.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      {...params}
                      label="Filter By Appeal Status"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "filterStatus", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 mt-1">
                <Autocomplete
                  size="small"
                  id="filterTypes"
                  disabled={!myAppeals.length}
                  defaultValue={null}
                  value={filterTypes}
                  options={filterAppealTypes()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, filterTypes) => {
                    setFilterTypes(filterTypes || null);
                    setSearchTeam("");
                    if (filterTypes) setSearchTeam(filterTypes.name);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, filterTypes) => (
                    <Box component="li" {...props} key={filterTypes.id}>
                      {filterTypes.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      {...params}
                      label="Filter By Appeal Type"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "filterTypes", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 mt-1">
                <SearchBox
                  disabled={!myAppeals.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
        >
          <table className="table-striped table-hover table-sm  fixTableHead">
            
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    Ref. No
                  </th>

                  <th scope="col" className="text-left">
                    Subject
                  </th>

                  <th scope="col" className="text-left">
                    Institution
                  </th>
                  <th scope="col" className="text-right">
                    Appealed at
                  </th>

                  <th scope="col" className="text-center">
                        Level
                      </th>
                      <th scope="col" className="text-right">
                        Status
                      </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3">Actions</span>
                  </th>
                </tr>
              </thead>
           

            {showLoader ? (
              <TableSkeleton cols={7} />
            ) : (
              <tbody>
                {paginatedItems.map((appeal, index) => (
                  <AppealCard
                    appeal={appeal}
                    index={index}
                    setViewAppeal={setViewAppeal}
                    setSelectedAppeal={setAppeal}
                    key={index}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        <NoRecordMessage
          isEmpty={!filteredMyAppeals.length && !showLoader}
          title="No Appeals found"
        />

        {viewAppeal && (
          <ViewAppealDialog
            setViewAppeal={setViewAppeal}
            viewAppeal={viewAppeal}
          />
        )}

        {!showLoader &&
          !!paginatedItems.length &&
          myAppeals.length >= itemsPerPage && (
            <div
              className="justify-content-center mt-2"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, myAppeals }) => {
  return { user, loading, myAppeals };
};
export default connect(mapStateToProps, { getMyAppeals, setAppeal })(Appeals);
