import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import moment from "moment";

const StatusComentDialog = (props) => {
  const { showStatusComent, setShowStatusComent, selectedRequest } = props;

  const onClose = () => {
    setShowStatusComent(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showStatusComent}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          {/* <Typography variant="overline" display="block">
            Applicant Letter
          </Typography> */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="row">
            <div className="col-lg-12 d-flex flex-wrap">
              <table>
                <tbody>
                  <tr>
                    <th scope="row">Institution</th>
                    <td>{selectedRequest.entityName}</td>
                  </tr>
                  <tr>
                    <th scope="row">Exit request</th>
                    <td>
                      <strong className="text-primary">
                        {selectedRequest.exitType}
                      </strong>
                      {selectedRequest.exitTypeId === 0 && (
                        <>
                          <span className="text-capitalize d-block">To</span>
                          <div className="text-capitalize d-block">
                            <div>{selectedRequest.toUnitName}</div>
                            <strong className="text-dark">
                              ({selectedRequest.toPositionName})
                            </strong>
                          </div>
                        </>
                      )}
                      {selectedRequest.exitTypeId === 6 && (
                        <>
                          <div className="text-capitalize d-block">
                            <strong className="text-primary">
                              {selectedRequest.toEntityName}
                            </strong>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Reason</th>
                    <td>{selectedRequest.reason}</td>
                  </tr>
                  <tr>
                    <th scope="row">Comment</th>
                    <td>{selectedRequest.statusComment}</td>
                  </tr>
                  <tr>
                    <th scope="row">Effective on</th>
                    <td>
                      {moment(selectedRequest.effectiveDate).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Requested on</th>
                    <td>{moment(selectedRequest.createdOn).format("LLL")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {selectedRequest.statusName === 1 && (
                        <span className="badge badge-secondary">Pending</span>
                      )}
                      {selectedRequest.statusName === "Approved" && (
                        <span className="badge badge-success">Approved</span>
                      )}
                      {selectedRequest.statusName === "Rejected" && (
                        <span className="badge badge-danger">Rejected</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StatusComentDialog;
