const types = {
  SET_FAULTS: "SET_FAULTS",
  SET_SANCTIONS: "SET_SANCTIONS",
  SET_MY_CASES: "SET_MY_CASES",
  SET_CASE: "SET_CASE",
  SET_CASE_ATTACHMENTS: "SET_CASE_ATTACHMENTS",
  UPDATE_DISCIPLINARY_CASE:"UPDATE_DISCIPLINARY_CASE"
};

export default types;
