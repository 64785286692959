import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { attachMoreFiles } from "../../../store/e-appeal/actions";

import { showError } from "../../../app/toastify";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import ActionDialog from "../../common/components/ActionDialog";
import attachment from "../../assets/icons/attachment.svg";
const AttachMoreFiles = (props) => {
  const {
    loading,
    showAttachExtraFiles,
    setShowAttachExtraFiles,
    level,
    appealId,
    referenceNo,
    attachMoreFiles,
  } = props;

  const onClose = () => {
    setShowAttachExtraFiles(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    attachedFiles: [],
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    hasError: false,
  });

  const onSave = () => {
    const error = {
      fileHasError: false,
      hasError: false,
    };
    if (!formData.attachedFiles.length) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);
    if (error.hasError) {
      showError("Supporting document was not provided");
    }

    if (!errors.fileHasError) {

      formData.level = level;
      formData.appealId = appealId;
      formData.referenceNo = referenceNo;
      const payload = new FormData();
     
      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachedFiles" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append(key, row.file);
            });
          else payload.append(`${key}`, `${formData[key]}`);
        }
      }

      attachMoreFiles(payload, setFormData, setIsSubmitting, onClose);
    }
  };
  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAttachExtraFiles}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-11">
              <h5
                style={{
                  // background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {" "}
                <img
                  src={attachment}
                  style={{
                    height: "18px",
                  }}
                />{" "}
                Attach more files.
              </h5>
            </div>
            <div className="col-1">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-12 mt-0">
              <WidgetUploadComponent
                onSuccess={onUploadSuccess}
                onErrors={onUploadErrors}
                multiple={true}
                acceptedFileSize="1074176"
                acceptedFileTypes="pdf"
                label="Upload supporting document *(pdf)"
              />
              <ErrorMessage
                hasError={errors.fileHasError && !formData.attachedFiles.length}
                message="Supporting document is required"
              />
            </div>
          </div>

          {isSubmitting && (
            <ActionDialog showAction={isSubmitting} action="Uploading.." />
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading || isSubmitting}
          >
            {isSubmitting ? "Uploading..." : "Upload"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  attachMoreFiles,
})(AttachMoreFiles);
