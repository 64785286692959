import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MyExitRequests from "./pages/MyExitRequests";
import Rehabilitations from "./pages/Rehabilitations";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/exit/user-guide" component={UserGuide} />
        <Route path="/exit/my-requests" component={MyExitRequests} />
        <Route path="/exit/rehabilitations" component={Rehabilitations} />

        <Route
          path="/exit"
          component={() => <Redirect to="/exit/my-requests" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
