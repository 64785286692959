import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...structure };

function arrangingWithholds(data) {
  let tempRes = [...data];

  let temWithholds1 = [];
  let temWithholds2 = [];
  let temWithholds3 = [];
  tempRes.forEach((e) => {
    if (e.isRequest && e.isRequestRejected) temWithholds1.push({ ...e });
    if (e.isRequest && !e.isRequestRejected) temWithholds2.push({ ...e });
    if (!e.isRequest) temWithholds3.push({ ...e });
  });

  return [...temWithholds1, ...temWithholds2, ...temWithholds3];
}

//getPayHistories

export const getPayHistories = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      dispatch({
        type: types.SET_PAY_HISTORIES,
        data: [],
      });
      const { data } = await axios.get("/api/payroll/pay-histories");
      dispatch({
        type: types.SET_PAY_HISTORIES,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const downloadPaySlip = (payrollId, onClose) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.get(
        "/api/payroll/employee-payslip?payrollId=" + payrollId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Payslip-${payrollId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      onClose();
    } catch (error) {
      onClose();
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getSalary = (employeePositionId, employeeGroupId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/payroll/salary/" + employeePositionId + "/" + employeeGroupId
      );
      dispatch({
        type: types.SET_SALARY,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveEmployeeCreditor = (
  formData,
  setFormData,
  setCreditor,
  setShowAddWithHold
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/employee-withholds",
        formData
      );

      dispatch({ type: types.END_LOADING });

      showSuccess("Submitted successfully");

      dispatch({
        type: types.ADD_EMPLOYEE_POSITION_WITHHOLDS,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setShowAddWithHold(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteEmployeeWithHolds = (
  selectedEmployeePayrollWithHold,
  setConfirmRemoveEmployeeCreditor,
  setSelectedEmployeePayrollWithHold
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/payroll/employee-withholds/" + selectedEmployeePayrollWithHold.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_POSITION_WITHHOLDS,
        data: selectedEmployeePayrollWithHold,
      });

      setConfirmRemoveEmployeeCreditor(false);
      setSelectedEmployeePayrollWithHold(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//

export const downloadPayHistory = (
  employeeId,
  setShowDownloadPayHistory,
  query = ""
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowDownloadPayHistory(true);
      const { data } = await axios.get(
        "/api/payroll/employee-pay-history/" + employeeId + query,
        { responseType: "blob" }
      );

      download(new Blob([data]), "PAY_HISTORY.pdf", ".pdf");

      dispatch({ type: types.END_LOADING });
      setShowDownloadPayHistory(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      setShowDownloadPayHistory(false);
    }
  };
};

export const downloadSalary = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/download/salary", {
        responseType: "blob",
      });

      download(new Blob([data]), "SALARY.pdf", ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEmployeeBankAccounts = (employeeId, setShowLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_BANK_ACCOUNTS,
        data: [],
      });

      setShowLoader(true);

      const { data } = await axios.get(
        "/api/payroll/employee-bank-accounts/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_BANK_ACCOUNTS,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getEmployeeWithholds = (setShowLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLDS,
        data: [],
      });
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/payroll/employee-withholds"
      );

      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLDS,
        data: arrangingWithholds(data),
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const saveEmployeeWithhold = (
  formData,
  setFormData,
  setAdd,
  setCreditor
  // canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/employee-withholds",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_EMPLOYEE_WITHHOLD,
        data: data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        creditorId: "",
        amount: 0,
        rate: 0,
        totalAmount: 0,
        startDate: null,
        // id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteEmployeeWithHold = (
  selectedEmployeeCreditor,
  setConfirmRemoveEmployeeCreditor,
  setSelectedEmployeeCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/payroll/employee-withholds/" + selectedEmployeeCreditor.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_WITHHOLD,
        data: selectedEmployeeCreditor,
      });

      setConfirmRemoveEmployeeCreditor(false);
      setSelectedEmployeeCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getEmployeeAllowances = (employeePositionId, setShowLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data: [],
      });
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/payroll/employee-allowances/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getEmployeeArrears = (employeePositionId, setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/payroll/temp-employee-position-arrears/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_ARREARS,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getSingleActiveContract = (employeePositionId, setShowLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data: {},
      });
      setShowLoader(true);

      const { data } = await axios.get(
        "/api/payroll/contractuals/active/" + employeePositionId
      );

      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getBasePayrollComponents = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/base-payroll-components");

      dispatch({
        type: types.SET_BASE_PAYROLL_COMPONENTS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const getCalculationMethods = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/calculation-methods");

      dispatch({
        type: types.SET_CALCULATION_METHODES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getCreditors = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/creditors");
      dispatch({
        type: types.SET_CREDITORS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getBanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/banks");

      dispatch({
        type: types.SET_BANKS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
export const downloadEmployeeDeductionsReport = (
  
) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.get(
        "/api/payroll/employee-deductions",
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `My-Deductions.pdf`,
        ".pdf"
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};
