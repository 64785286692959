import {
  Dialog,
  Box,
  CircularProgress,
  DialogContent,
} from "@mui/material";


const DownloadPayHistoryDialog = (props) => {
  const {
    showDownloadPayHistory
  } = props;



  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showDownloadPayHistory}
        maxWidth="sm"
      >
  
        <DialogContent  >
          <div>
              
                <Box
                  className="justify-content-center "
                  sx={{ display: "flex" }}
                >
                  <CircularProgress />
                  <h5 className="mt-2 ml-2">Downloading ...</h5>
                </Box>
          
           
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default DownloadPayHistoryDialog;
