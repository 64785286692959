import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getPostponedLeaveDetails } from "../../../store/leave/actions";

const ViewPostponedLeaveDetails = (props) => {
  const { leavePlan, setLeavePlan, getPostponedLeaveDetails } = props;
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [postponedDetails, setPostponedDetails] = useState([]);

  useEffect(() => {
    if (leavePlan) {
      getPostponedLeaveDetails(leavePlan.id, (status, data) => {
        setLoadingDetails(status);
        if (!status) {
          setPostponedDetails(data);
        }
      });
    }

    return () => {
      setLoadingDetails(true);
      setPostponedDetails([]);
    };
  }, []);

  return (
    <Dialog
      onClose={() => setLeavePlan(null)}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          Postponed Leave Details
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => setLeavePlan(null)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent className="py-1">
        <TableContainer>
          <Table size="small" aria-label="Your annual leave balance">
            <TableHead>
              <TableRow
                style={{
                  borderLeft: "2px solid #e9ecef",
                  borderRight: "2px solid #e9ecef",
                }}
              >
                <TableCell>#</TableCell>
                <TableCell>Starting On</TableCell>
                <TableCell>Ending On</TableCell>
                <TableCell>Planned Days</TableCell>
                <TableCell>Taken Days</TableCell>
                <TableCell>Planned On</TableCell>
                <TableCell>Postponed On</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            {loadingDetails ? (
              <TableSkeleton cols={8} isMUI />
            ) : (
              <TableBody>
                {postponedDetails.map((plan, index) => (
                  <TableRow key={plan.id} hover>
                    <TableCell>
                      {!!plan.id ? (
                        index
                      ) : (
                        <span
                          className="badge badge-primary px-1"
                          style={{
                            fontSize: "9px",
                          }}
                        >
                          MAIN
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{plan.startDate}</TableCell>
                    <TableCell>{plan.endDate}</TableCell>
                    <TableCell>{plan.numOfDays}</TableCell>
                    <TableCell>{plan.numOfTakenDays}</TableCell>
                    <TableCell>{plan.createdOn}</TableCell>
                    <TableCell>{plan.postponedOn}</TableCell>
                    <TableCell>
                      <span
                        className={`badge ${
                          plan.statusId === 2 || plan.statusId === 9
                            ? "badge-primary"
                            : plan.statusId === 4
                            ? "badge-info"
                            : plan.statusId === 5
                            ? "badge-light"
                            : plan.statusId === 6
                            ? "badge-success"
                            : plan.statusId === 7
                            ? "badge-danger"
                            : "badge-secondary"
                        }`}
                      >
                        <span
                          className="material-icons-round"
                          style={{
                            fontSize: "0.8rem",
                            verticalAlign: "middle",
                            marginRight: "0.2rem",
                          }}
                        >
                          {plan.statusId === 2
                            ? "done"
                            : plan.statusId === 3
                            ? "done_all"
                            : plan.statusId === 4
                            ? "done_all"
                            : plan.statusId === 5
                            ? "fast_forward"
                            : plan.statusId === 6
                            ? "stop"
                            : plan.statusId === 7
                            ? "close"
                            : plan.statusId === 9
                            ? "next_plan"
                            : "alarm_on"}
                        </span>
                        <span>{plan.statusName}</span>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
                {!postponedDetails.length && (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center">
                      No leave plan found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  getPostponedLeaveDetails,
})(ViewPostponedLeaveDetails);
