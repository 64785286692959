import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Button,
  Tabs,
  Tab,
  AlertTitle,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import defaultPofile from "../../assets/default-profile.jpg";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  employmentCategories,
  expectedTrainingAwards,
  highestAcademicLevels,
  openCenteredPopup,
  textCapitalized,
  trainingAreas,
  trainingCategories,
  trainingInitiators,
  requestStatusColor,
} from "../../common/components/Utils";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PdfViewer from "../../common/components/PdfViewer";
import ViewAttachmentTrainingRequestDialog from "./ViewAttachmentTrainingRequestDialog";
import {
  downloadTrainingAuthorization
} from "../../../store/training/actions";
import { a11yProps } from "../../common/components/TabPanel";

import ViewTrainingRequestLogs from "./ViewTrainingRequestLogs";
import ViewTrainingRequestApprovals from "./ViewTrainingRequestApprovals";

import ActionDialog from "../../common/components/ActionDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const ViewTrainingRequestDialog = (props) => {
  const {
    loading,
    user,
    showTrainingRequestDetails,
    setShowTrainingRequestDetails,
    trainingRequest,
    downloadTrainingAuthorization,
  } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [viewAuthPermit, setViewAuthPermit] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowTrainingRequestDetails(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showTrainingRequestDetails}
        fullWidth
        maxWidth="xl"
      >
         {viewAuthPermit && trainingRequest?.request && (
        <PreviewPdfUrl
          showDocument={viewAuthPermit}
          setShowDocument={setViewAuthPermit}
          document={{
            name: `View Training Authorization Permit`,
            url:process.env.REACT_APP_FILES_URL +"/preview/" +trainingRequest?.request.docAuthPermitReferenceId,
          }}
        />
      )} 
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-10">
              <h5>
                {trainingRequest?.request?.ministryEntityName && (
                  <>
                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="d-flex justify-content-start">
                          <small
                            className="text-dark fw-bold text-truncate "
                            style={{ fontSize: "14px" }}
                          >
                            My Training Request
                          </small>{" "}
                          <small
                            style={{
                              fontSize: "16px",
                              position: "relative",
                              bottom: 3,
                            }}
                            className="ml-2 mt-0"
                          >
                            @
                          </small>
                          <small
                            className="ml-1 fw-bold text-truncate text-info"
                            style={{ fontSize: "12px" }}
                          >
                            {textCapitalized(
                              trainingRequest?.request?.ministryEntityName
                            )}
                          </small>{" "}
                          {trainingRequest?.request && (
                            <span
                              style={{ fontSize: "12px" }}
                              className={`text ml-2 text-${requestStatusColor(
                                [
                                  {
                                    className: "success",
                                    statusIds: [
                                      trainingRequest?.status?.APPROVED,
                                    ],
                                  },
                                  {
                                    className: "danger",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MINISTRY,
                                      trainingRequest?.status?.REJECTED_BY_RDB,
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MINEDUC,
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MIFOTRA,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.VERIFIED_SENT_TO_MINISTRY_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_RDB_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_MINEDUC_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_MIFOTRA_APPROVAL,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.PENDING_RDB_VERIFICATION,
                                      trainingRequest?.status
                                        ?.PENDING_MINEDUC_VERIFICATION,
                                      trainingRequest?.status
                                        ?.APPROVED_SENT_TO_MIFOTRA,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status?.NEW,
                                      trainingRequest?.status
                                        ?.SENT_TO_MINISTRY_VERIFIER,
                                    ],
                                  },
                                ],
                                trainingRequest?.request?.statusId
                              )}`}
                            >
                              ({trainingRequest?.request?.trainingStatusName})
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </h5>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className="text-capitalize"
                  label="Details"
                  {...a11yProps(0)}
                />
                <Tab
                  className="text-capitalize"
                  label="Attachments
             "
                  {...a11yProps(1)}
                />

                <Tab
                  className="text-capitalize"
                  label="Comments"
                  {...a11yProps(2)}
                />

                <Tab
                  className="text-capitalize"
                  label="CD Plan"
                  {...a11yProps(3)}
                />
              </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: "600px" }}>
        {tabValue === 0 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <div className="card service-card valid mb-2 mt-1">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      I. Identification
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                          <div className="row mt-0">
                            <div className="col-3 justify-content-center text-uppercase text-center">
                              <div
                                className="card mb-4 pb-2"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-center">
                                  <div
                                    style={{
                                      height: "auto",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <>
                                      {trainingRequest?.profilePicture && (
                                        <img
                                          className="mt-0 rounded  text-center"
                                          src={
                                            "data:image/jpg;base64," +
                                            trainingRequest?.profilePicture
                                          }
                                          style={{
                                            border: "1px solid #a6d3df",
                                            height: "128px",
                                            // width: "130px",
                                            overflow: "hidden",
                                            // borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                      {!trainingRequest?.profilePicture && (
                                        <img
                                          className="mt-0  rounded text-center"
                                          src={defaultPofile}
                                          style={{
                                            // border: "1px solid #a6d3df",
                                            height: "128px",
                                            width: "100px",
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </>
                                  </div>

                                  {trainingRequest?.request?.isAvailable && (
                                    <span
                                      className="text-success font-weight-bolder"
                                      style={{
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    >
                                      <span
                                        className="material-icons d-block"
                                        style={{ fontSize: "48px" }}
                                      >
                                        check_circle_outline
                                      </span>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="mt-n1 d-block"
                                      >
                                        Available
                                      </span>
                                    </span>
                                  )}
                                  {!trainingRequest?.request?.isAvailable && (
                                    <>
                                      <span
                                        className="text-danger font-weight-bolder"
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                        }}
                                      >
                                        <span
                                          className="material-icons d-block"
                                          style={{ fontSize: "48px" }}
                                        >
                                          highlight_off
                                        </span>
                                        <span
                                          style={{ fontSize: "12px" }}
                                          className="mt-n1 d-block"
                                        >
                                          Not available
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <div
                                className="card"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-left">
                                  <div className="row">
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              Employee Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.employeeId
                                                }{" "}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Names:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {
                                                  trainingRequest?.request
                                                    ?.employeeTitle
                                                }
                                                .
                                                <span className="ml-1">
                                                  {textCapitalized(
                                                    trainingRequest?.request
                                                      ?.lastName
                                                  )}{" "}
                                                  {textCapitalized(
                                                    trainingRequest?.request
                                                      ?.firstName
                                                  )}
                                                </span>
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              DOB:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {moment(
                                                  trainingRequest?.request
                                                    ?.dateOfBirth
                                                ).format("ll")}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              National Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.idNumber
                                                }
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Gender:{" "}
                                              <span className="contact_span">
                                                {trainingRequest?.request
                                                  ?.gender === "M"
                                                  ? "Male"
                                                  : "Female"}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Civil Status:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.civilStatus
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              PhoneNumber:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.phoneNumber
                                                }{" "}
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              Personal Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  trainingRequest?.request
                                                    ?.email
                                                }
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Other Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  trainingRequest?.request
                                                    ?.professionalEmail
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {trainingRequest?.request?.hasPendingExitRequest && (
                            <ErrorMessage
                              hasError={true}
                              message={<>Employee has pending exist request!</>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      II. Employment Information
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employer (Institution)
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.entityName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Job position
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.positionName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employment category
                            </legend>
                            <div className="form-group1 mb-0">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="employmentCategory-label"
                                  name="employmentCategory"
                                  value={
                                    trainingRequest?.request
                                      ?.employmentCategory || ""
                                  }
                                >
                                  {employmentCategories.map(
                                    (employmentCategory, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          disableTypography={true}
                                          readOnly={true}
                                          value={employmentCategory}
                                          name="employmentCategory"
                                          label={employmentCategory}
                                          control={<Radio size="small" />}
                                          checked={
                                            employmentCategory ===
                                            trainingRequest?.request
                                              ?.employmentCategory
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      III. Educational Background
                    </div>
                    <div className="card-body">
                      <fieldset className="form-group border p-2">
                        <legend
                          className="w-auto px-2"
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Highest academic level of the public servant
                        </legend>
                        <div className="form-group1 mb-0">
                          <div className="row">
                            <div className="col-9">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="highestAcademicLevel-label"
                                  name="highestAcademicLevel"
                                  value={
                                    trainingRequest?.request
                                      ?.highestAcademicLevel || ""
                                  }
                                >
                                  {highestAcademicLevels.map(
                                    (highestAcademicLevel, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          style={{ cursor: "none" }}
                                          value={highestAcademicLevel}
                                          readOnly={true}
                                          name="highestAcademicLevel"
                                          control={<Radio size="small" />}
                                          checked={
                                            highestAcademicLevel ===
                                            trainingRequest?.request
                                              ?.highestAcademicLevel
                                              ? true
                                              : false
                                          }
                                          label={highestAcademicLevel}
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div className="col-3">
                              
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      IV. Training details
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Title of the training
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.trainingTitle}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Areas
                            </legend>
                            <div className="form-group1 mb-0 overflow-hidden">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="trainingArea-label"
                                      name="trainingArea"
                                      value={
                                        trainingRequest?.request
                                          ?.trainingAreas || ""
                                      }
                                    >
                                      {trainingAreas.map(
                                        (trainingArea, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={trainingArea}
                                              name="trainingArea"
                                              label={trainingArea}
                                              readOnly={true}
                                              control={<Radio size="small" />}
                                              checked={
                                                trainingRequest?.request
                                                  ?.trainingAreas ===
                                                trainingArea
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                              {trainingRequest?.request?.trainingAreas &&
                                trainingRequest?.request?.trainingAreas ===
                                  "Other" && (
                                  <fieldset className="form-group border p-2 mt-2">
                                    <legend
                                      className="w-auto px-2"
                                      style={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        fontFamily:
                                          '"Roboto","Helvetica","Arial",sans-serif',
                                        fontWeight: 400,
                                        fontSize: "0.82rem",
                                        // lineHeight: "1em",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      If other, specify
                                    </legend>
                                    <div className="form-group1 mb-0">
                                      {
                                        trainingRequest?.request
                                          ?.otherTrainingAreas
                                      }
                                    </div>
                                  </fieldset>
                                )}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training category
                            </legend>
                            <div className="form-group1">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="trainingCategory-label"
                                  name="trainingCategory"
                                  value={
                                    trainingRequest?.request
                                      ?.trainingCategory || ""
                                  }
                                >
                                  {trainingCategories.map(
                                    (trainingCategory, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          value={trainingCategory.name}
                                          name="trainingCategory"
                                          label={trainingCategory.name}
                                          readOnly={true}
                                          control={<Radio size="small" />}
                                          checked={
                                            trainingCategory.name ===
                                            trainingRequest?.request
                                              ?.trainingCategory
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              The training location
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-12 mt-1">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingInsideCountry-label"
                                      name="isTrainingInsideCountry"
                                    >
                                      <FormControlLabel
                                        value="In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          trainingRequest?.request
                                            ?.isTrainingInsideCountry
                                        }
                                      />
                                      <FormControlLabel
                                        value="Not In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="Not In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          !trainingRequest?.request
                                            ?.isTrainingInsideCountry
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training duration
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    From:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.trainingDurationFrom
                                        ? moment(
                                            trainingRequest?.request
                                              ?.trainingDurationFrom
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    To:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.trainingDurationTo
                                        ? moment(
                                            trainingRequest?.request
                                              ?.trainingDurationTo
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Departure date:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request?.departureDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.departureDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Returning date:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request?.returningDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.returningDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training place
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Country:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.countryForTraining
                                      }
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Town:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.townForTraining
                                      }
                                    </span>
                                  </p>
                                </div>

                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Institute/University:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.universityForTraining
                                      }
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Funds Provider
                            </legend>
                            {!!trainingRequest?.trainingFundProviders
                              .length && (
                              <div className="form-group1 mb-0 overflow-hidden">
                                <div className="row">
                                  <div className="col-12">
                                    <FormControl className="w-100">
                                      <RadioGroup
                                        aria-labelledby="trainingFundsProvider-label"
                                        name="trainingFundsProvider"
                                      >
                                        {trainingRequest?.trainingFundProviders.map(
                                          (trainingFund, index) => (
                                            <div key={index}>
                                              <FormControlLabel
                                                className="w-100"
                                                value={trainingFund.provider}
                                                name="trainingFund"
                                                label={trainingFund.provider}
                                                readOnly={true}
                                                control={
                                                  <Checkbox size="small" />
                                                }
                                                checked={true}
                                              />
                                              {trainingFund.provider ===
                                                "Government" && (
                                                <div className="w-100">
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If Government, specify the
                                                      institution
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}

                                              {trainingFund.provider ===
                                                "Development Partner" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If development partner,
                                                      specify
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}
                                              {trainingFund.provider ===
                                                "Other" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If other, specify
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}

                                              {trainingFund.provider ===
                                                "Self-Sponsored" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will Self-Sponsored
                                                      cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            )}
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Expected training award
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="expectedTrainingAward-label"
                                      name="expectedTrainingAward"
                                      value={
                                        trainingRequest?.request
                                          ?.expectedTrainingAward || ""
                                      }
                                    >
                                      {expectedTrainingAwards.map(
                                        (expectedTrainingAward, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={expectedTrainingAward}
                                              name="expectedTrainingAward"
                                              label={expectedTrainingAward}
                                              readOnly={true}
                                              control={<Radio size="small" />}
                                              checked={
                                                expectedTrainingAward ===
                                                trainingRequest?.request
                                                  ?.expectedTrainingAward
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Is the training captured in the capacity
                              development plan of the institution?
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingHasCdPlan-label"
                                      name="isTrainingHasCdPlan"
                                      value={
                                        trainingRequest?.request
                                          ?.isTrainingHasCdPlan || ""
                                      }
                                    >
                                      {["Yes", "No"].map(
                                        (isTrainingHasCdPlan, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={isTrainingHasCdPlan}
                                              name="isTrainingHasCdPlan"
                                              label={isTrainingHasCdPlan}
                                              control={<Radio size="small" />}
                                              checked={
                                                isTrainingHasCdPlan ===
                                                trainingRequest?.request
                                                  ?.isTrainingHasCdPlan
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          {trainingRequest?.request?.isTrainingHasCdPlan &&
                            trainingRequest?.request?.isTrainingHasCdPlan ===
                              "No" && (
                              <fieldset className="form-group border p-2">
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  If no, who initiated the training?
                                </legend>
                                <div className="form-group1 mb-2 overflow-hidden">
                                  <div className="row">
                                    <div className="col-12">
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="trainingInitiator-label"
                                          name="trainingInitiator"
                                          value={
                                            trainingRequest?.request
                                              ?.trainingInitiator || ""
                                          }
                                        >
                                          {trainingInitiators.map(
                                            (trainingInitiator, index) => (
                                              <div key={index}>
                                                <FormControlLabel
                                                  value={trainingInitiator}
                                                  name="trainingInitiator"
                                                  label={trainingInitiator}
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  checked={
                                                    trainingInitiator ===
                                                    trainingRequest?.request
                                                      ?.trainingInitiator
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                  {trainingRequest?.request
                                    ?.trainingInitiator &&
                                    trainingRequest?.request
                                      ?.trainingInitiator === "Other" && (
                                      <fieldset className="form-group border p-2 mt-2">
                                        <legend
                                          className="w-auto px-2"
                                          style={{
                                            color: "rgba(0, 0, 0, 0.6)",
                                            fontFamily:
                                              '"Roboto","Helvetica","Arial",sans-serif',
                                            fontWeight: 400,
                                            fontSize: "0.82rem",
                                            lineHeight: "1em",
                                            letterSpacing: "0.00938em",
                                          }}
                                        >
                                          If other, specify
                                        </legend>
                                        <div className="form-group1 mb-0">
                                          {
                                            trainingRequest?.request
                                              ?.otherTrainingInitiator
                                          }
                                        </div>
                                      </fieldset>
                                    )}
                                </div>
                              </fieldset>
                            )}
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              What gaps were identified to be addressed by the
                              training
                            </legend>
                            <div className="form-group1 mb-0">
                              {trainingRequest?.request?.trainingGapsIdentified}
                            </div>
                          </fieldset>
                          {trainingRequest?.request
                            ?.coveredRelatedCostsDetails && (
                            <fieldset className="form-group border p-2">
                              <legend
                                className="w-auto px-2"
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Who will cover all the training costs?
                              </legend>
                              <div className="form-group1 mb-0">
                                {
                                  trainingRequest?.request
                                    ?.coveredRelatedCostsDetails
                                }
                              </div>
                            </fieldset>
                          )}

                          {/* {!!trainingRequest?.approvals.length && (
                            <ViewTrainingRequestApprovals
                              approvals={trainingRequest.approvals}
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </React.Fragment>
            </Box>
          )}
          {tabValue === 1 &&
            !!trainingRequest?.trainingRequestAttachments.length && (
              <Box sx={{ width: "100%" }} className="mt-1">
                <React.Fragment>
                  <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                    <ViewAttachmentTrainingRequestDialog
                      attachments={trainingRequest.trainingRequestAttachments}
                    />
                  </Typography>
                </React.Fragment>
              </Box>
            )}

          {tabValue === 2 && (
            <Box sx={{ width: "100%" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewTrainingRequestLogs
                     logs={trainingRequest.logs}
                  />
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {tabValue === 3 && (
            <Box sx={{ width: "100%" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  {trainingRequest?.cdPlan ? (
                    <div
                      className="card service-card mb-3 mt-0"
                      style={{ borderColor: "#e0edf9!important" }}
                    >
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem", width: "100%" }}
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <AttachFileIcon />
                            View cd plan for fiscal year of
                            {trainingRequest?.cdPlan.fiscalYear.name}
                          </div>
                        </div>
                      </div>
                      <div>
                      <PdfViewer
                          url={process.env.REACT_APP_FILES_URL +'/preview/'+ trainingRequest.request.documentReferenceId} 
                        />
                      </div>
                    </div>
                  ) : (
                    <Alert severity="info">
                      <AlertTitle>
                        The training request does not require a cd plan
                      </AlertTitle>
                    </Alert>
                  )}
                </Typography>
              </React.Fragment>
            </Box>
          )}
        </DialogContent>
        <DialogActions className="py-3 d-flex justify-content-center">
        
          {trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.APPROVED && (

                <button
                type="button"
                onClick={() =>setViewAuthPermit(true)
                  // openCenteredPopup(
                  //   process.env.REACT_APP_IPPIS_FILES_URL +
                  //     trainingRequest?.request.attachedAuthorizationPermit,
                  //   800,
                  //   800
                  // )
                }
                className="btn btn-sm my-2 btn-primary"
              >
                <PreviewIcon className="text-white mr-1" />
                View Training Authorization Letter
              </button>
            )}

      
          {(isDownloading) && (
            <ActionDialog
              showAction={isDownloading}
              action="Downloading..."
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, trainingRequest }) => {
  return {
    user,
    loading,
    trainingRequest,
  };
};
export default connect(mapStateToProps, {
  downloadTrainingAuthorization,
})(ViewTrainingRequestDialog);
