import React, { useEffect } from "react";

import { connect } from "react-redux";
import { getEntitySectors } from "../../../store/structure/actions";
import Appeals from "./Appeals";

const MyAppeal = (props) => {
  const { getEntitySectors, entitySectors } = props;

  useEffect(() => {
    document.title = "Employee Portal - E-Appeal | My Appeals";
  });
  useEffect(
    () => {
      if (!entitySectors.length) getEntitySectors();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Appeals />
    </>
  );
};

const mapStateToProps = ({ entitySectors }) => {
  return { entitySectors };
};
export default connect(mapStateToProps, {
  getEntitySectors,
})(MyAppeal);
