import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMyCases } from "../../../store/discipline/actions";
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import NewAppealDialog from "../components/NewAppealDialog";
const loaderData = [1, 2, 3, 4, 5, 6];

const ViewDisciplineCaseForAppeal = (props) => {
  const { myCases, getMyCases } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [addNewAppealForm, setAddNewAppealForm] = useState(false);
  const [selectedAppealType, setSelectedAppealType] = useState(null);
  const [filteredCases, setFilteredCases] = useState([
    ...[...myCases].filter(({ caseStatusId }) => caseStatusId === 13),
  ]);
  useEffect(() => {
    getMyCases(setShowLoader);
  }, []);

  return (
    <>
      {!showLoader && (
        <>
          {addNewAppealForm && (
            <NewAppealDialog
              addNewAppealForm={addNewAppealForm}
              setAddNewAppealForm={setAddNewAppealForm}
              selectedAppealType={selectedAppealType}
            />
          )}

          {filteredCases.map((row, index2) => (
              <div
                style={{ marginLeft: "-20px" }}
                className="col-sm-12 services-wrapper "
                key={index2}
              >
                <ul className="bullet-line-list mb-3 mt-2">
                  <li>
                    <p className="sub-service ">
                      <a
                        onClick={() => {
                          row.isDisciplineCase = true;
                          setSelectedAppealType(row);
                          setAddNewAppealForm(true);
                        }}
                        className="text-black link-hovered"
                      >
                        <Card
                          className={` w-100 rounded hoverable-card border-bottom pb-1`}
                          elevation={0}
                          variant="elevation"
                          // style={{marginLeft:'-10px'}}
                        >
                          <div className="p-2">
                            <span className="text-primary">
                              {row.caseSanctionName}
                            </span>{" "}
                            <br /> {row.entityName}
                          </div>
                        </Card>
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            ))}

          {filteredCases.length === 0 && (
            <>
              <div className="col-sm-12 services-wrapper " key={"a0"}>
                <ul className="bullet-line-list mb-3 mt-2">
                  <li>
                    <p className="sub-service">
                      <NoRecordMessage
                        isEmpty={true}
                        message={"There is no disciplinary case to appeal."}
                      />
                    </p>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      )}

      {showLoader && !myCases.length && (
        <>
          {loaderData.map((row, index) => (
            <div className="col-lg-4 mt-4" key={index}>
              <Card sx={{ maxWidth: "100%", m: 2 }}>
                <CardHeader
                  avatar={
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  }
                  title={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    <Skeleton animation="wave" height={10} width="40%" />
                  }
                />
                <CardContent>
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="70%" />
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="50%" />

                  <Skeleton animation="wave" height={10} width="70%" />
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="50%" />
                  <Skeleton animation="wave" height={10} width="70%" />
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="50%" />
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                </CardContent>
              </Card>
            </div>
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, myCases }) => {
  return { user, loading, myCases };
};
export default connect(mapStateToProps, { getMyCases })(
  ViewDisciplineCaseForAppeal
);
