import axios from "axios";
import download from "downloadjs";
import { showSuccess, showError } from "../../app/toastify";
import rbmTypes from "./action-types";
import leaveTypes from "../leave/action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...rbmTypes, ...leaveTypes };

// patrick
export const getMyContracts = (fiscalYearId, cb) => {
  cb(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_MY_CONTRACTS,
        data: [],
      });

      const { data } = await axios.get(`/api/rbm/my-contracts/${fiscalYearId}`);

      // dispatch({
      //   type: types.CLEAN_STATE,
      //   data: [],
      // });

      dispatch({
        type: types.SET_MY_CONTRACTS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      cb(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
      cb(false);
    }
  };
};

export const getContractItems = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: [],
      });

      const { data } = await axios.get(`/api/rbm/contract-items/${contractId}`);

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSubmissions = ({ assignmentId, itemTypeId }, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SUBMISSIONS,
        data: [],
      });

      setIsLoading(true);

      const { data } = await axios.get(
        `/api/rbm/submissions/${assignmentId}/${itemTypeId}`
      );

      setIsLoading(false);

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data,
      });
    } catch (error) {
      showError(error);
      setIsLoading(false);
    }
  };
};

export const submitAssignmentReport = (
  reportData,
  setIsSubmitting,
  resetFormData
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/submissions", reportData);

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.item,
      });

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data.submissions,
      });

      dispatch({
        type: types.UPDATE_CONTRACT,
        data: data.contract,
      });

      setIsSubmitting(false);

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      resetFormData();

      showSuccess("Report submitted successfully");
    } catch (error) {
      setIsSubmitting(false);
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const removeSubmission = (
  { submissionId, assignmentId, contractId, itemId, itemTypeId },
  setConfirmRemoveSubmission
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/rbm/delete-submission`, {
        submissionId,
        assignmentId,
        contractId,
        itemId,
        itemTypeId,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.DELETE_SUBMISSION,
        id: submissionId,
      });

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.item,
      });

      dispatch({
        type: types.UPDATE_CONTRACT,
        data: data.contract,
      });

      setConfirmRemoveSubmission(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getFiscalYears = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/rbm/fiscal-years`);

      dispatch({
        type: types.SET_FISCAL_YEARS,
        data: data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const downloadEmployeePerformanceReport = (employeeId, fiscalYearId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm-reports/employee/${employeeId}/${fiscalYearId}`,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `EMPLOYEE_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadContractPerformanceReport = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm-reports/contract/" + contractId,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `RBM_CONTRACT_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const selectFiscalYear = (fiscalYear, cb) => {
  return async (dispatch) => {
    try {
      cb(true);
      await axios.post("/api/rbm/select-fiscal-year", {
        id: fiscalYear.id,
      });

      dispatch({
        type: types.SET_MY_CONTRACTS,
        data: [],
      });

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: [],
      });

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: [],
      });

      dispatch({
        type: types.SET_MY_LEAVE_PLANS,
        data: [],
      });

      dispatch({
        type: types.SET_MY_OTHER_LEAVE_REQUESTS,
        data: [],
      });

      dispatch({
        type: types.SET_SELECTED_FISCAL_YEAR,
        data: { ...fiscalYear },
      });
      cb(false);
    } catch (error) {
      cb(false);
      showError(error);
    }
  };
};