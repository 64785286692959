import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center ">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/rbm/my-contracts"
          className={`px-1 rounded  ${
            location.pathname.includes("/rbm/my-contracts") ? "active" : " "
          }`}
        >
          <span className="menu-item">MyContracts</span>
        </Link>
      </div>
    </>
  );
};

export default Breadcrumb;
