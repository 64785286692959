import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const Breadcrumb = () => {
  const location = useLocation();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center ">
        {/* <>
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/leave/home"
            className={` px-1 rounded   ${
              location.pathname.includes("/leave/home") ? " active  " : " "
            }`}
          >
            <span className="text-truncate menu-item">Home</span>
          </Link>
        </> */}

        <>
          {/* <HorizontalRuleIcon className="rotated" /> */}
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/leave/leave-plans"
            className={`px-1 rounded ${
              location.pathname.includes("/leave/leave-plans") ? "active" : " "
            }`}
          >
            <span className="text-truncate menu-item">Leave plans</span>
          </Link>
        </>

        <>
          <HorizontalRuleIcon className="rotated" />

          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/leave/other-leaves"
            className={`px-1 rounded ${
              location.pathname.includes("/leave/other-leaves") ? "active" : " "
            }`}
          >
            <span className="text-truncate menu-item">Other Leaves</span>
          </Link>
        </>
      </div>
    </>
  );
};

export default Breadcrumb;
