import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { selectModule } from "../../../store/common/actions";

const ModulesMenu = (props) => {
  const { selectModule, user, ippisModules, selectedModule } = props;

  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className="d-flex modules-menu  align-items-center flex-wrap ">
        {ippisModules.map((mod, index) => (
          <React.Fragment key={index}>
            <button
              className={`mr-1 py-0 btn btn-link btn-sm  ${
                location.pathname.includes(`${mod.to}`) ? " active  " : " "
              }`}
              onClick={() => selectModule(mod, history)}
            >
              <span className="d-inline-flex align-items-center">
                <img
                  src={require(`../../assets/icons/${mod.icon}`)}
                  width="24"
                  alt=""
                  className="mr-1"
                />
                {mod.shortName}
              </span>
            </button>
          </React.Fragment>
        ))}
        <button
          className={`mr-1 py-0 btn btn-link btn-sm   ${
            location.pathname.includes("/user/profile") ? " active  " : " "
          }`}
          onClick={() => {
            selectModule(
              {
                ...selectedModule,
                shortName: "My Profile",
                fullName: "My Profile",
                to: "/user/profile",
              },
              history
            );

            history.push("/user/profile");
          }}
        >
          <span className="d-inline-flex align-items-center py-0">
            <span className="material-icons ">account_circle</span>
            My Profile
          </span>
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, ippisModules, selectedModule }) => {
  return {
    user,
    ippisModules,
    selectedModule,
  };
};
export default connect(mapStateToProps, { selectModule })(ModulesMenu);
