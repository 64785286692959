import { defaultState } from "./state";
import leaveActionTypes from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...leaveActionTypes };

// public holidays reducer
const publicHolidays = (
  publicHolidaysState = defaultState.publicHolidays,
  action
) => {
  switch (action.type) {
    case types.SET_PUBLIC_HOLIDAYS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.publicHolidays;

    default:
      return publicHolidaysState;
  }
};

// Annual Leave Plans reducer
const myLeavePlans = (
  myLeavePlansState = defaultState.myLeavePlans,
  action
) => {
  switch (action.type) {
    case types.SET_MY_LEAVE_PLANS:
      return action.data;

    case types.CANCEL_LEAVE_PLAN:
      return myLeavePlansState.filter(
        (leavePlan) => leavePlan.id !== action.data
      );

    case types.CHANGE_LEAVE_PLAN_STATUS: {
      if (!action.data) {
        return myLeavePlansState;
      }
      let index = myLeavePlansState.findIndex(
        (leavePlan) => leavePlan.id === action.data.id
      );
      myLeavePlansState[index] = action.data;
      return myLeavePlansState;
    }

    case types.CLEAN_STATE:
      return defaultState.myLeavePlans;

    default:
      return myLeavePlansState;
  }
};

// Leave Types reducer
const leaveTypes = (leaveTypesState = defaultState.leaveTypes, action) => {
  switch (action.type) {
    case types.SET_LEAVE_TYPES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.leaveTypes;
    default:
      return leaveTypesState;
  }
};

// My Other Leave Requests reducer
const myOtherLeaveRequests = (
  myOtherLeaveRequestsState = defaultState.myOtherLeaveRequests,
  action
) => {
  switch (action.type) {
    case types.SET_MY_OTHER_LEAVE_REQUESTS:
      return action.data;

    case types.CANCEL_OTHER_LEAVE_REQUEST:
      return myOtherLeaveRequestsState.filter(
        (request) => request.id !== action.data
      );

    // case types.CHANGE_OTHER_LEAVE_REQUEST_STATUS: {
    //   if (!action.data) {
    //     return myOtherLeaveRequestsState;
    //   }
    //   let index = myOtherLeaveRequestsState.findIndex(
    //     (request) => request.id === action.data.id
    //   );
    //   myOtherLeaveRequestsState[index] = action.data;
    //   return myOtherLeaveRequestsState;
    // }

    case types.CLEAN_STATE:
      return defaultState.myOtherLeaveRequests;

    default:
      return myOtherLeaveRequestsState;
  }
};

export default {
  publicHolidays,
  myLeavePlans,
  leaveTypes,
  myOtherLeaveRequests,
};
