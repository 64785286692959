import { CardContent } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
const ContentLayout = ({children}) => {

  const theme = useTheme();

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) || window.innerWidth <= 767 ?true:false;
  }
  
  return (
    <>
      <div className="w-100">
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <div className={`${isMobileDevice()?'ml-1 mr-1':"container"} mt-4 mb-4`}>
            <div
              style={{
                padding: theme.spacing(0.4),
                height: "auto",
                maxHeight: "auto",
                margin: "1.2em",
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                overflow: "hidden",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              }}
            >
              <CardContent
                style={{
                  padding: ".1em",
                  height: "auto",
                  minHeight: "auto",
                  overflow: "hidden",
                }}
              >
                {children}
              </CardContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentLayout;
