import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const Breadcrumb = () => {
  const location = useLocation();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center ">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/e-appeal/new-appeal"
          className={` px-1 rounded   ${
            location.pathname.includes("/e-appeal/new-appeal")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">New Appeal</span>
        </Link>
        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/e-appeal/my-appeals"
          className={`  px-1 rounded  ${
            location.pathname.includes("/e-appeal/my-appeals") ? "active" : " "
          }`}
        >
          <span className="text-truncate  menu-item">My Appeals</span>
        </Link>
      </div>
    </>
  );
};

export default Breadcrumb;
