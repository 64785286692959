import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { saveRehabilitation } from "../../../store/exit/actions";
import { showError } from "../../../app/toastify";

const AddRehabilitationDialog = (props) => {
  const {
    user,
    loading,
    addRequestForm,
    setAddRequestForm,
    saveRehabilitation,
  } = props;

  const onClose = () => {
    setAddRequestForm(false);
  };

  const [formData, setFormData] = useState({
    blacklistId: user.blacklist.id,
    supportingDocument: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    supportingDocumentHasError: false,
    messageHasError: false,
  });

  const formValidator = () => {
    const error = {
      supportingDocumentHasError: false,
      messageHasError: false,
      effectiveOnHasError: false,
    };

    if (!formData.supportingDocument) {
      error.supportingDocumentHasError = true;
      error.hasError = true;
    }
    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const changeFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFile = (event) => {
    if (event.target.value) {
      const file = event.target.files[0];
      setFormData({ ...formData, supportingDocument: file || null });
    }
  };

  const onSave = () => {
    if (!formValidator()) {
      if (
        !!formData &&
        !!formData.supportingDocument &&
        formData.supportingDocument.size > 1048576
      )
        return showError(`File size cannot exceed 1MB.`);

      const rehabilitaionData = new FormData();
      rehabilitaionData.append("blacklistId", formData.blacklistId);
      rehabilitaionData.append("message", formData.message);
      rehabilitaionData.append("file", formData.supportingDocument);

      saveRehabilitation(rehabilitaionData, setFormData, setAddRequestForm);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addRequestForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Request Rehabilitation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="m-2">
            <TextField
              required
              id="outlined-required"
              label="Comments..."
              fullWidth
              onChange={(e) => {
                const message = e.target.value;
                setFormData({ ...formData, message });
                const messageHasError = false;
                setErrors({ ...errors, messageHasError });
              }}
            />
            {errors.messageHasError && (
              <div className="text-danger mt-1">Message is required </div>
            )}
          </div>
          <div className="m-2">
            Add supporting document
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-paperclip"></i>
                </span>
              </div>

              <input
                type="file"
                name="file"
                className="form-control"
                accept="application/pdf, application/msword"
                placeholder="Select file"
                onChange={(e) => {
                  uploadFile(e);
                  const supportingDocumentHasError = false;
                  setErrors({ ...errors, supportingDocumentHasError });
                }}
              />
            </div>
            {errors.supportingDocumentHasError && (
              <div className="text-danger mt-1">
                Supporting document is required
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  saveRehabilitation,
})(AddRehabilitationDialog);
