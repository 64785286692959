import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Typography,
  IconButton,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import MyActivities from "./MyActivities";
import {
  downloadContractPerformanceReport,
  downloadEmployeePerformanceReport,
  getMyContracts,
} from "../../../store/rbm/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import CustomProgressLine from "../components/CustomProgressLine";

const MyContracts = (props) => {
  const {
    myContracts,
    user,
    getMyContracts,
    downloadContractPerformanceReport,
    downloadEmployeePerformanceReport,
  } = props;

  const containerRef = useRef(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [loadingContracts, setLoadingContracts] = useState(true);

  useEffect(() => {
    document.title = `IPPIS - RBM My Contracts | ${user.firstName} ${user.lastName}`;
  }, []);

  useEffect(() => {
    if (!!user?.RBM_fiscalYear?.id)
      getMyContracts(user?.RBM_fiscalYear.id, setLoadingContracts);
    return () => {
      setLoadingContracts(true);
    };
  }, [user?.RBM_fiscalYear]);

  const breadcrumbs = selectedContract
    ? [
        <Button
          variant="text"
          key="1"
          color="inherit"
          type="button"
          onClick={() => setSelectedContract(null)}
        >
          <span className="text-uppercase text-primary font-weight-bold">
            My Contracts
          </span>
        </Button>,
        <Typography key="2" color="text.primary" className="truncate">
          {`${selectedContract.fiscalYear} - ${selectedContract.positionName} - ${selectedContract.contractType} contract`}
        </Typography>,
      ]
    : [
        <Typography
          key="1"
          className="text-uppercase text-primary font-weight-bold"
        >
          My Contracts
        </Typography>,
      ];

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <div
            className="d-flex mt-2 justify-content-between align-items-center mb-1"
            ref={containerRef}
          >
            <Breadcrumbs
              separator={<NavigateNextRoundedIcon fontSize="small" />}
              aria-label="breadcrumb"
              className="mb-2 mt-1"
            >
              {breadcrumbs}
            </Breadcrumbs>
            {selectedContract ? (
              <Button
                variant="outlined"
                startIcon={<ArrowBackRoundedIcon fontSize="inherit" />}
                size="small"
                color="inherit"
                onClick={() => setSelectedContract(null)}
              >
                Back
              </Button>
            ) : (
              <div className="d-flex align-items-center py-1">
                {/* {loadingContracts ? (
                  <Skeleton
                    variant="rounded"
                    width={110}
                    height={40}
                    className="mr-2"
                  />
                ) : (
                  <FormControl
                    sx={{ minWidth: 135 }}
                    size="small"
                    className="mr-2"
                  >
                    <InputLabel id="select-fiscal-year">Fiscal year</InputLabel>
                    <Select
                      labelId="select-fiscal-year"
                      id="fiscal-year-select"
                      value={user?.RBM_fiscalYear?.id || ""}
                      label="Fiscal year"
                      onChange={(e) => handleSelectedFiscalYear(e.target.value)}
                    >
                      {fiscalYears.map((fiscalYear) => (
                        <MenuItem key={fiscalYear.id} value={fiscalYear.id}>
                          {fiscalYear.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )} */}
                {loadingContracts ? (
                  <Skeleton
                    variant="rounded"
                    width={110}
                    height={40}
                    className="mr-2"
                  />
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<i className="fas fa-download"></i>}
                    onClick={() =>
                      downloadEmployeePerformanceReport(
                        user.id,
                        user?.RBM_fiscalYear?.id
                      )
                    }
                    disabled={
                      loadingContracts ||
                      myContracts.length === 0 ||
                      !user?.RBM_fiscalYear?.id
                    }
                  >
                    Report
                  </Button>
                )}
              </div>
            )}
          </div>

          {selectedContract ? (
            <MyActivities selectedContract={selectedContract} />
          ) : (
            <>
              <TableContainer>
                <Table size="small" aria-label="IPPIS RBM Employee Contracts">
                  <TableHead>
                    <TableRow
                      style={{
                        borderLeft: "2px solid #e9ecef",
                        borderRight: "2px solid #e9ecef",
                      }}
                    >
                      <TableCell>#</TableCell>
                      <TableCell>Year</TableCell>
                      <TableCell>Post</TableCell>
                      <TableCell>Entity</TableCell>
                      <TableCell>Contract</TableCell>
                      <TableCell align="center">Overview</TableCell>
                      <TableCell>Score</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell className="text-center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {loadingContracts ? (
                    <TableSkeleton cols={9} isMUI />
                  ) : (
                    <TableBody>
                      {myContracts.map((contract, index) => (
                        <ContractItemCard
                          key={index}
                          index={index}
                          contract={contract}
                          setSelectedContract={setSelectedContract}
                          downloadReport={downloadContractPerformanceReport}
                        />
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <NoRecordMessage
                isEmpty={!myContracts.length && !loadingContracts}
                title="No record found"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ myContracts, loading, user }) => {
  return {
    loading,
    user,
    myContracts,
  };
};
export default connect(mapStateToProps, {
  getMyContracts,
  downloadContractPerformanceReport,
  downloadEmployeePerformanceReport,
})(MyContracts);

const ContractItemCard = (props) => {
  const { contract, index, setSelectedContract, downloadReport } = props;

  const [contractScore, setContractScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setContractScore(contract.totalScore);
    }, 500);

    return () => {
      setContractScore(0);
    };
  }, [contract]);

  return (
    <TableRow
      key={index + contract.id}
      hover
      sx={{
        cursor: "pointer",
      }}
      className="tr-hovered"
    >
      <TableCell onClick={() => setSelectedContract(contract)} scope="row">
        {index + 1}
      </TableCell>
      <TableCell onClick={() => setSelectedContract(contract)}>
        {contract.fiscalYear}
      </TableCell>
      <TableCell onClick={() => setSelectedContract(contract)}>
        {contract.positionName}
      </TableCell>
      <TableCell onClick={() => setSelectedContract(contract)}>
        {contract.entityName}
      </TableCell>
      <TableCell onClick={() => setSelectedContract(contract)}>
        {contract.contractType}
      </TableCell>
      <TableCell onClick={() => setSelectedContract(contract)} align="center">
        <div className="d-flex mb-2 align-items-center">
          <span
            style={{ color: "#088dce", minWidth: "90px" }}
            className="text-uppercase text-right btn-link"
          >
            {contract.numOfActivities} Item
            {contract.numOfActivities > 1 ? "s" : ""}
          </span>

          <span
            className="ml-2 badge badge-secondary text-left  text-uppercase"
            style={{ minWidth: "80px" }}
          >
            <span
              className="badge badge-light bg-secondary text-light"
              style={{ fontSize: "11px" }}
            >
              {contract.ontrackActivities}
            </span>{" "}
            Ontrack
          </span>

          <span
            className="ml-2 badge badge-warning text-left text-uppercase"
            style={{ minWidth: "80px" }}
          >
            <span
              className="badge badge-light bg-warning text-light"
              style={{ fontSize: "11px" }}
            >
              {contract.dueActivities}
            </span>{" "}
            Overdue
          </span>

          <span
            className="ml-2 badge badge-info  text-uppercase"
            style={{ minWidth: "80px" }}
          >
            <span
              className="badge badge-light bg-info text-light"
              style={{ fontSize: "11px" }}
            >
              {contract.reportedActivities}
            </span>{" "}
            Reported
          </span>
          <span
            className="ml-2 badge badge-success text-left  text-uppercase"
            style={{ minWidth: "80px" }}
          >
            <span
              className="badge badge-light bg-success text-light"
              style={{ fontSize: "11px" }}
            >
              {contract.scoredActivities}
            </span>{" "}
            Scored
          </span>
          <span
            className="ml-2 badge badge-danger text-left text-uppercase"
            style={{ minWidth: "80px" }}
          >
            <span
              className="badge badge-light bg-danger text-light"
              style={{ fontSize: "11px" }}
            >
              {contract.rejectedActivities}
            </span>{" "}
            Rejected
          </span>
        </div>
        <CustomProgressLine height={10} hidePercentage value={contractScore} />
      </TableCell>
      <TableCell align="right" onClick={() => setSelectedContract(contract)}>
        <span
          className={` font-weight-bold text-${
            contractScore < 50
              ? "danger"
              : contractScore < 70
              ? "warning"
              : contractScore < 90
              ? "primary"
              : "success"
          }`}
        >
          {contractScore.toFixed(2)}%
        </span>
      </TableCell>

      <TableCell onClick={() => setSelectedContract(contract)}>
        {contract.isActive ? "Active" : "Closed"}
      </TableCell>
      <TableCell>
        <span className="d-inline-flex">
          <Button
            onClick={() => setSelectedContract(contract)}
            variant="text"
            size="small"
          >
            View
          </Button>
          <IconButton
            disabled={contract.numOfActivities === 0}
            color="primary"
            size="small"
            onClick={() => downloadReport(contract.id)}
          >
            <i className="fas fa-download"></i>
          </IconButton>
        </span>
      </TableCell>
    </TableRow>
  );
};
