import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
const ViewAppealResolved = (props) => {
  const { contents, showAppealResolved, setAppealResolved } = props;

  const onClose = () => {
    setAppealResolved(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showAppealResolved}
      fullWidth
    >
      <DialogTitle className="text-primary">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>{contents}</DialogContent>
    </Dialog>
  );
};

export default ViewAppealResolved;
