import React, { useState, useEffect } from "react";
import { getApplealTypes } from "../../../store/e-appeal/actions";
import { connect } from "react-redux";

import SearchBox from "../../common/components/SearchBox";

import NoRecordMessage from "../../common/components/NoRecordMessage";

import NewAppealDialog from "../components/NewAppealDialog";
import { getEntitySectors } from "../../../store/structure/actions";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ContentLayout from "../../common/components/ContentLayout";
import ViewDisciplineCaseForAppeal from "./ViewDisciplineCaseForAppeal";
const loaderData = [1, 2, 3, 4, 5, 6];
const NewAppeal = (props) => {
  const { applealTypes, getEntitySectors, getApplealTypes, entitySectors } =
    props;
  const theme = useTheme();
  const [filteredApplealTypes, setfilteredApplealTypes] = useState([
    ...applealTypes,
  ]);
  const [showLoader, setShowLoader] = useState(false);
  const [searchTeam, setSearchTeam] = useState("");

  const [addNewAppealForm, setAddNewAppealForm] = useState(false);
  const [selectedAppealType, setSelectedAppealType] = useState(null);

  useEffect(() => {
    document.title = "Employee Portal - E-Appeal | New Appeal";
  });

  useEffect(
    () => {
      if (!entitySectors.length) getEntitySectors();
      if (!applealTypes.length) getApplealTypes(setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...applealTypes].filter(
      ({ moduleId, moduleName, appealTypes }) =>
        moduleId.toLowerCase().includes(searchTeam.toLowerCase()) ||
        moduleName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        [...appealTypes].find(({ name }) =>
          name.toLowerCase().includes(searchTeam.toLowerCase())
        )
    );

    setfilteredApplealTypes(filtered);
  }, [searchTeam, applealTypes]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className={"col-lg-7"}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>E-Appeal</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                Choose Appeal Type
              </span>
            </Typography>
          </div>
          <div className={"col-lg-5 mt-2"}>
            <SearchBox
              style={{
                marginLeft: "18px!important",
              }}
              disabled={!applealTypes.length}
              onSearch={onSearch}
              placeholder="Search…"
            />
          </div>
        </div>

        <div className="row mb-5 mt-3">
          <div className="col-12">
            <NoRecordMessage
              isEmpty={!filteredApplealTypes.length && !showLoader}
              title="Service not Found"
              message={"Your search did not match any service."}
            />

            {addNewAppealForm && (
              <NewAppealDialog
                addNewAppealForm={addNewAppealForm}
                setAddNewAppealForm={setAddNewAppealForm}
                selectedAppealType={selectedAppealType}
              />
            )}
          </div>
          {filteredApplealTypes.map((row, index) => (
            <div className="col-lg-4 mb-4" key={index}>
              <div className="border p-2 h-100 rounded bg-light elevated">
                <h2
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: "1.24",
                    letterSpacing: "-.9px",
                    color: "#39485a",
                  }}
                  className="border-bottom pb-1"
                >
                  <img
                    src={require(`../../assets/icons/${row.moduleIcon}`)}
                    width="24"
                    alt=""
                    className="mr-1"
                  />
                  {row.moduleName}
                </h2>

                <div className="row">
                  {"DISCIPLINE" === row.moduleId ? (
                    <>
                      <ViewDisciplineCaseForAppeal />
                    </>
                  ) : (
                    <>
                      {row.appealTypes.map((appealType, index2) => (
                        <div
                          style={{ marginLeft: "-20px" }}
                          className="col-sm-12 services-wrapper align-items-start justify-content-start"
                          key={index + "-" + index2}
                        >
                          <ul className="bullet-line-list mb-3 mt-2">
                            <li>
                              <p className="sub-service text-left">
                                <a
                                  onClick={() => {
                                    appealType.isDisciplineCase=false;
                                    setSelectedAppealType(appealType);
                                    setAddNewAppealForm(true);
                                  }}
                                  className="text-black text-left link-hovered"
                                >
                                  {appealType.name}
                                </a>
                              </p>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
          {showLoader && !applealTypes.length && (
            <>
              {loaderData.map((row, index) => (
                <div className="col-lg-4 mt-4" key={index}>
                  <Card sx={{ maxWidth: "100%", m: 2 }}>
                    <CardHeader
                      avatar={
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={40}
                          height={40}
                        />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={
                        <Skeleton animation="wave" height={10} width="40%" />
                      }
                    />
                    {/* <Skeleton
                          sx={{ height: 190 }}
                          animation="wave"
                          variant="rectangular"
                        /> */}
                    <CardContent>
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="70%" />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="50%" />

                      <Skeleton animation="wave" height={10} width="70%" />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="50%" />
                      <Skeleton animation="wave" height={10} width="70%" />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="50%" />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                    </CardContent>
                  </Card>
                </div>
              ))}
            </>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, applealTypes, entitySectors }) => {
  return { user, loading, applealTypes, entitySectors };
};
export default connect(mapStateToProps, {
  getApplealTypes,
  getEntitySectors,
})(NewAppeal);
