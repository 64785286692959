import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const NoRecordMessage = (props) => {
  const { isEmpty, title, message, onClickLink, textLink } = props;

  return (
    <>
      {isEmpty && (
        <div
          style={{
            width: "100%",
            marginTop: "auto",
            background: "#f5faff",
            borderColor: "#e0f0ff",
          }}
          role="alert"
        >
          <div className="notify--info">
            <div className="notify__icon-holder">
              <InfoOutlinedIcon
                style={{ color: "rgb(7, 142, 206)", fontSize: "25px" }}
              />
            </div>

            <div className="notify__content-wrapper">
              <div className="notify__content">
                <div className="notify__message">
                  {title && <span>{title}</span>}{" "}
                  {message && <span>{message}</span>}{" "}
                  {textLink && (
                    <a href="#" onClick={onClickLink} className="text--link">
                      {textLink}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoRecordMessage;
