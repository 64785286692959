import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";

import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import { getUser } from "../../store/common/actions";

import MenuBar from "./components/MenuBar";
import UserGuide from "./pages/UserGuide";
import MyContracts from "./pages/MyContracts";

const Layout = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = props;

  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);

  return (
    <React.Fragment>
      {!isEmpty(user) && (
        <>
          <MenuBar />

          <div
            className={`container-fluid ${
              location.pathname === "/rbm/user-guide"
                ? "pt-0 px-0"
                : "pt-2 px-4"
            } `}
            id="main-container"
          >
            <Switch>
              <Route path="/rbm/my-contracts" component={MyContracts} />

              <Route path="/rbm/user-guide" component={UserGuide} />

              <Route
                path="/rbm"
                component={() => <Redirect to="/rbm/my-contracts" />}
              />
            </Switch>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, loading, env }) => {
  return {
    user,
    loading,
    env,
  };
};
export default connect(mapStateToProps, {
  getUser,
})(Layout);
