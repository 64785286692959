import disciplineTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
// import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...disciplineTypes };

export const getSanctionsWithFaultCategory = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/sanctions");

      dispatch({
        type: types.SET_SANCTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getFaultWithSanction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/faults");

      dispatch({
        type: types.SET_FAULTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMyCases = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/my-cases");

      dispatch({
        type: types.SET_MY_CASES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const getCase = (caseId, setLoading, setShowCaseDetail) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setLoading(true);
      const { data } = await axios.get("/api/discipline/case/" + caseId);

      dispatch({
        type: types.SET_CASE,
        data,
      });

      setLoading(false);
      setShowCaseDetail(true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoading(false);
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const getCaseAttachments = (caseId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/discipline/case-attachments/" + caseId
      );

      dispatch({
        type: types.SET_CASE_ATTACHMENTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};

export const approveResponseToExplanationLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-response-to-explanation-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      // if (isSessionExpired(error)) {
      //   dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      // }
      showError(error);
    }
  };
};