import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { connect } from "react-redux";
import { logout } from "../../../store/common/actions";

const Drawer = (props) => {
  const { drawerState, setDrawerState, user, logout, selectedModule } = props;

  const location = useLocation();
  const history = useHistory();



  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed  ${drawerState ? "active" : ""}`}
      >
        <div>
          <div
            className="drawer-user "
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
         {user.profileImage && (
              <img
              style={{width:'100px',height:'120px'}}
                src={`data:image/png;base64,${user.profileImage}`}
                className="img-fluid rounded mb-2"
                alt={user.firstName +"'s profile image"}
              />
            )}
            {!user.profileImage && (
              <img
              style={{width:'100px',height:'100px'}}
                src={defaultProfile}
                className="img-fluid rounded mb-2"
                alt={user.firstName +"'s profile image"}
              />
            )}
            
            <div>
              <Tooltip title="Go to your profile">
                <Link
                  to="/profile/info"
                  style={{ color: "#b8ff07" }}
                  className="font-weight-bold text-uppercase"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  {user.firstName} {user.lastName}
                </Link>
              </Tooltip>
            </div>
            {user.currentEmployeePosition && 
              <small className=" " style={{ color: "#ff0" }}>
                {user.currentEmployeePosition.positionName}
              </small>
             }
            <br />
            <small style={{ color: "#fff" }}>
              <>
               {user.currentEmployeePosition && user.currentEmployeePosition.unitName}
              </>
            </small>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>
          <List>
          
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/e-appeal/new-appeal")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons-round">post_add</span>
                </ListItemIcon>
                <Link to="/e-appeal/new-appeal" className="text-dark">
                  <ListItemText
                    primary="New Appeal"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

         
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/e-appeal/my-appeals")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons-round">list_alt</span>
                </ListItemIcon>
                <Link to="/e-appeal/my-appeals" className="text-dark">
                  <ListItemText
                    primary="My Appeals"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/e-appeal/user-guide") ? "active" : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">local_library</span>
                </ListItemIcon>
                <Link to="/e-appeal/user-guide" className="text-dark">
                  <ListItemText
                    primary="User-Guide"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Button
          className="btn btn-block mb-3 "
          color="error"
          style={{ position: "absolute", bottom: "0" }}
          onClick={() => logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${drawerState ? "d-block" : "d-none"} `}
        onClick={() => {
          setDrawerState(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule }) => {
  return { user, selectedModule };
};
export default connect(mapStateToProps, { logout })(Drawer);
