import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Salary from "./pages/Salary";

import PayHistory from "./pages/PayHistory";

import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import loading from "../assets/loading.gif";
import UserGuide from "./pages/UserGuide";
import { getUser } from "../../store/common/actions";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/payroll/salary" component={Salary} />
        {/* <Route path="/payroll/pay-history" component={PayHistory} /> */}

        <Route path="/payroll/user-guide" component={UserGuide} />
        <Route
          path="/payroll"
          component={() => <Redirect to="/payroll/salary" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
