import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import * as arrayToTree from "array-to-tree";

import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

function arrangingPostions(data) {
  let tempRes = [...data];

  let temPositions1 = [];
  let temPositions2 = [];
  let temPositions3 = [];

  tempRes.forEach((e) => {
    if (e.isHeadOfInstitution || e.isHeadOfUnit) temPositions1.push({ ...e });
    else if (!e.isOnStructure || e.isShared) temPositions3.push({ ...e });
    else temPositions2.push({ ...e });
  });

  return [...temPositions1, ...temPositions2, ...temPositions3];
}

function arrangingEmployees(data) {
  let tempEmployees = [...data];
  let inServiceEmployees = [];
  let isActingEmployees = [];
  let notInserveceEmployees = [];
  let notRequiredDataOfEmployees = [];

  let notPlaced = [];
  let shared = [];

  tempEmployees.forEach((e) => {
    if (e.statusId !== 0 && !e.isActing) notInserveceEmployees.push(e);
    else if (e.statusId === 0 && e.isActing) isActingEmployees.push(e);
    else if (
      !e.positionId ||
      e.idNumber.length !== 21 ||
      !e.rssbNumber ||
      !e.phoneNumber ||
      !e.email
    )
      notRequiredDataOfEmployees.push(e);
    else if (!!!e.positionId) notPlaced.push(e);
    else if (e.isShared) {
      shared.push(e);
    } else inServiceEmployees.push(e);
  });

  return [
    ...isActingEmployees,
    ...inServiceEmployees,
    ...shared,
    ...notPlaced,
    ...notRequiredDataOfEmployees,
    ...notInserveceEmployees,
  ];
}

export const getUnits = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/units");

      dispatch({
        type: types.SET_UNITS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//unit-types
export const getUnitTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/unit-types");

      dispatch({
        type: types.SET_UNIT_TYPES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getTreeUnits = (unitId, setExpandedNodes) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/units/" + unitId);

      const treeData = arrayToTree(data, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: data,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      data.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const saveOrUpdateUnit = (
  unit,
  setFormData,
  setIsEditing,
  setShowUnitForm,
  setExpandedNodes,
  units,
  isEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const formData = {
        unitName: unit.unitName,
        parentUnitId: unit.parentUnitId,
        unitTypeId: unit.unitTypeId,
        isOnStructure: unit.isOnStructure,
      };

      const { data } = !isEditing
        ? await axios.post("/api/structure/units", formData)
        : await axios.put("/api/structure/units/" + unit.unitId, formData);

      dispatch({ type: types.END_LOADING });
      showSuccess(!isEditing ? "Saved successfully" : "Updated successfully");

      setFormData({
        unitId: null,
        unitName: "",
        parentUnitId: "",
        unitTypeId: 0,
        isOnStructure: false,
      });
      setShowUnitForm(false);
      setIsEditing(false);

      const tmpUnits = [...units];
      if (isEditing) {
        const index = tmpUnits.findIndex(
          ({ unitId }) => unitId === data.unitId
        );
        tmpUnits[index] = data;
      } else tmpUnits.push(data);

      //units

      const treeData = arrayToTree(tmpUnits, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: tmpUnits,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      tmpUnits.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//saveOrUpdateQualification

export const saveQualification = (
  formData,
  setFormData,
  setShowQualificationForm,
  setSelectedQualification,
  setDegree,
  setQualification,
  setCurrentQualifications
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-qualifications",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setShowQualificationForm(false);
      setSelectedQualification(null);
      setDegree(null);
      setQualification(null);
      setCurrentQualifications([]);

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_QUALIFICATION,
        data: data,
      });

      setFormData({
        qualificationId: null,
        jobClassificationId: formData.jobClassificationId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveJcExperience = (
  formData,
  setFormData,
  setShowExperienceForm,
  setJobField,
  setExperience
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-experiences",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setShowExperienceForm(false);
      setJobField(null);
      setExperience(null);

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_EXPERIENCE,
        data: data,
      });

      setFormData({
        jobFieldId: null,
        yearsOfExperience: null,
        jobClassificationId: formData.jobClassificationId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveJcCompetency = (
  formData,
  setFormData,
  setShowCompetencyForm,
  setCompetency
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-competencies",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setShowCompetencyForm(false);
      setCompetency(null);

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_COMPETENCY,
        data: data,
      });

      setFormData({
        competencyId: null,
        jobClassificationId: formData.jobClassificationId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//saveJcCertificate

export const saveJcCertificate = (
  formData,
  setFormData,
  setShowCertificateForm,
  setCertificateField,
  setCertificate,
  setCurrentCertificates
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-certificates",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setShowCertificateForm(false);
      setCertificate(null);
      setCertificateField(null);
      setCurrentCertificates([]);

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_CERTIFICATE,
        data: data,
      });

      setFormData({
        certificateId: null,
        jobClassificationId: formData.jobClassificationId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//saveJobField
export const saveJobField = (formData, setFormData, setAddJobFieldForm) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/job-fields", formData);

      // dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      setAddJobFieldForm(false);
      dispatch({
        type: types.ADD_JOB_FIELD,
        data: data,
      });

      setFormData({
        name: null,
      });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveProfessionalCertificate = (
  formData,
  setFormData,
  setShowProfessionalCertificateForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/professional-certificate",
        formData
      );

      dispatch({ type: types.END_LOADING });

      showSuccess("Saved successfully");

      setShowProfessionalCertificateForm(false);

      dispatch({
        type: types.ADD_PROFESSIONAL_CERTIFICATE,
        data: { ...data, ...formData },
      });

      setFormData({
        certificateTypeId: null,
        experience: null,
        professionalCertificateTypeId: null,
        positionId: null,
        professionalCertificateType: null,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//delete-position-qualification

export const deleteJcQualification = (
  selectedQualification,
  setConfirmRemoveQualification,
  setSelectedQualification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-qualifications/" +
          selectedQualification.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_QUALIFICATION,
        data: selectedQualification,
      });

      setConfirmRemoveQualification(false);
      setSelectedQualification(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteJcExperience = (
  selectedExperience,
  setConfirmRemoveExperience,
  setSelectedExperience
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-experiences/" + selectedExperience.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_EXPERIENCE,
        data: selectedExperience,
      });

      setConfirmRemoveExperience(false);
      setSelectedExperience(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteJcCompetency = (
  selectedCompetency,
  setConfirmRemoveCompetency,
  setSelectedCompetency
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-competencies/" +
          selectedCompetency.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_COMPETENCY,
        data: selectedCompetency,
      });

      setConfirmRemoveCompetency(false);
      setSelectedCompetency(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//deleteJcCertificate

export const deleteJcCertificate = (
  selectedCertificate,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-certificates/" +
          selectedCertificate.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_CERTIFICATE,
        data: selectedCertificate,
      });

      setConfirmRemoveCertificate(false);
      setSelectedCertificate(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const deleteUnit = (
  unitToBeRemoved,
  setConfirmRemoveUnit,
  setUnitToBeRemoved,
  setExpandedNodes,
  units
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/units/" + unitToBeRemoved.unitId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemoveUnit(false);

      const tmpUnits = [...units];
      const index = tmpUnits.findIndex(
        ({ unitId }) => unitId === unitToBeRemoved.unitId
      );
      tmpUnits.splice(index, 1);

      const treeData = arrayToTree(tmpUnits, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: tmpUnits,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      tmpUnits.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
      setUnitToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const setSelectedUnit = (unit) => {
  return (dispatch) => {
    dispatch({
      type: types.SELECTED_UNIT,
      data: unit,
    });
  };
};

export const setSearchUnit = (searchTeam, units, setExpandedNodes) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_UNIT,
      data: "",
    });

    let data = [...units].filter(({ unitName }) =>
      unitName.toLowerCase().includes(searchTeam.toLowerCase())
    );
    const treeData = arrayToTree(data, {
      parentProperty: "parentUnitId",
      childrenProperty: "children",
      customID: "unitId",
    });

    dispatch({
      type: types.SET_TREE_UNITS,
      data: treeData[0] || {},
    });

    const ids = [];
    data.forEach(({ unitId }) => ids.push(unitId));
    setExpandedNodes([...ids]);
  };
};
//setSearchPositions

export const setSearchPositions = (
  searchTeam,
  positions,
  searchCurrentPositions
) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_POSITION,
      data: "",
    });

    dispatch({
      type: types.SEARCH_CURRENT_POSITIONS,
      data: !!searchCurrentPositions.length
        ? searchCurrentPositions
        : positions,
    });

    let data = [];

    if (!searchTeam) {
      data = searchCurrentPositions;
    } else {
      data = [...positions].filter(({ name }) =>
        name.toLowerCase().includes(searchTeam.toLowerCase())
      );
    }

    dispatch({
      type: types.SET_POSITIONS,
      data,
    });
  };
};

export const getUnitPositions = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_POSITIONS,
        data: [],
      });

      dispatch({
        type: types.SEARCH_CURRENT_POSITIONS,
        data: [],
      });

      dispatch({
        type: types.SEARCH_POSITION,
        data: "",
      });

      const { data } = await axios.get("/api/structure/positions/" + unitId);

      dispatch({
        type: types.SET_POSITIONS,
        data: arrangingPostions(data),
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEntitySectorPositions = (
  entitySectorId,
  isEntitySectorPositions = false
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // dispatch({
      //   type: types.SET_POSITIONS,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_CURRENT_POSITIONS,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_POSITION,
      //   data: "",
      // });
      if (isEntitySectorPositions) {
        dispatch({
          type: types.SET_ENTITY_SECTOR_POSITIONS,
          data: [],
        });
      } else {
        dispatch({
          type: types.SET_POSITIONS,
          data: [],
        });
      }

      const { data } = await axios.get(
        "/api/structure/entity-sector-positions/" + entitySectorId
      );
      if (isEntitySectorPositions) {
        dispatch({
          type: types.SET_ENTITY_SECTOR_POSITIONS,
          data: arrangingPostions(data),
        });
      } else {
        dispatch({
          type: types.SET_POSITIONS,
          data: arrangingPostions(data),
        });
      }

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//getPosition

export const getPosition = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_POSITION,
        data: null,
      });

      const { data } = await axios.get(
        "/api/structure/show/position/" + positionId
      );

      dispatch({
        type: types.SET_POSITION,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//savePosition

export const savePosition = (formData, setShowPositionForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/structure/positions", formData);

      dispatch({
        type: types.ADD_POSITION,
        data: data,
      });
      showSuccess("Saved successfully");

      setShowPositionForm(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveSharedPosition = (formData, setShowPositionForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/structure/shared-positions",
        formData
      );

      dispatch({
        type: types.ADD_POSITION,
        data: data,
      });
      showSuccess("Saved successfully");

      setShowPositionForm(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updatePosition = (
  positionId,
  formData,
  setShowUpdatePositionModal
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/structure/positions/" + positionId,
        formData
      );

      dispatch({
        type: types.UPDATE_POSITION,
        data: data,
      });
      setShowUpdatePositionModal(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//deletePosition

export const updateSharedPosition = (
  formData,
  setShowUpdateSharedPositionModal,
  setSourceOfFund,
  setPayrollGroup,
  setCostCenter
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/structure/shared-positions/" + formData.sharedId,
        formData
      );

      dispatch({
        type: types.UPDATE_POSITION,
        data: data,
      });
      setSourceOfFund(null);
      setPayrollGroup(null);
      setCostCenter(null);
      setShowUpdateSharedPositionModal(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deletePosition = (
  positionToBeRemoved,
  setConfirmRemovePosition,
  setPositionToBeRemoved
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/positions/" + positionToBeRemoved.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemovePosition(false);

      dispatch({
        type: types.DELETE_POSITION,
        data: positionToBeRemoved,
      });

      setPositionToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//deleteProfessionalCertificate

export const deleteSharedPosition = (
  positionToBeRemoved,
  setConfirmRemovePosition,
  setPositionToBeRemoved
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/shared-positions/" + positionToBeRemoved.sharedId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemovePosition(false);

      dispatch({
        type: types.DELETE_POSITION,
        data: positionToBeRemoved,
      });

      setPositionToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const deleteProfessionalCertificate = (
  selectedCertificate,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const formData = {
        experience: selectedCertificate.experience,
        professionalCertificateId:
          selectedCertificate.professionalCertificateId,
        positionId: selectedCertificate.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/delete-professional-certificate",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setSelectedCertificate(null);
      setConfirmRemoveCertificate(false);

      dispatch({
        type: types.DELETE_PROFESSIONAL_CERTIFICATE,
        data: selectedCertificate,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//deletePositionKnowledge

export const deletePositionKnowledge = (
  selectedPositionKnowledge,
  setConfirmRemovePositionKnowledge,
  setSelectedPositionKnowledge
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const formData = {
        knowledgeId: selectedPositionKnowledge.knowledgeId,
        positionId: selectedPositionKnowledge.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/delete-position-knowledge",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_POSITION_KNOWLEDGE,
        data: selectedPositionKnowledge,
      });

      setSelectedPositionKnowledge(null);
      setConfirmRemovePositionKnowledge(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePositionKnowledge = (
  formData,
  setFormData,
  setShowPositionKnowledgeForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const payload = {
        knowledgeId: formData.knowledgeId,
        positionId: formData.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/position-knowledge",
        payload
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.ADD_POSITION_KNOWLEDGE,
        data: { ...data, ...formData },
      });

      setFormData({
        knowledgeId: null,
        positionId: null,
        knowledgeName: null,
      });

      setShowPositionKnowledgeForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const setAsPlanner = (position) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/set-as-planner/" + position.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.SET_POSITION_PLANNER,
        data: position,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const setAsActing = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/set-as-acting",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully!");

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data: data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//set-as-head-unity
export const setAsHeadOfUnity = (position) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/set-as-head-unity/" + position.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.SET_POSITION_HEAD_UNITY,
        data: position,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

/*
 * @Get SalaryStructure
 * GET /api/structure/salary-structures?mainEntityId
 */

export const getSalaryStructure = (entitySectorId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/salary-structures/" + entitySectorId
      );

      dispatch({
        type: types.SET_SALARY_STRUCTURE,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get EmployeeGroup
 * GET /api/structure/employee-groups
 */

export const getEmployeeGroups = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/employee-groups");

      dispatch({
        type: types.SET_EMPLOYEE_GROUPS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get HiringMode
 * GET /api/structure/hiring-modes
 */

export const getHiringModes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/hiring-modes");

      dispatch({
        type: types.SET_HIRING_MODES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @ Get PositionEmployees
 * GET /api/structure/position-employees
 */

export const getPositionEmployees = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_EMPLOYEES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-employees/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_EMPLOYEES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

/*
 * @ Get levels
 * GET /api/structure/levels
 */

export const getLevels = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/levels");

      dispatch({
        type: types.SET_LEVELS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @ Get Echelons
 * GET /api/structure/echelons
 */

export const getEchelons = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/scales");

      dispatch({
        type: types.SET_ECHELONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @ Get Banks
 * GET /api/lookup/banks
 */

export const getBanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/banks");

      dispatch({
        type: types.SET_BANKS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @ Get Banks
 * GET /api/lookup/banks
 */

export const getMedicalInsuranceTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/medical-insurance-types");

      dispatch({
        type: types.SET_MEDICAL_INSURANCE_TYPES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get SupervisorPositions
 * GET /api/structure/supervisor-positions
 */

export const getSupervisorPositions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/supervisor-positions");

      dispatch({
        type: types.SET_SUPERVISOR_POSITIONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get getPostionQualifications
 * GET /api/structure/position-qualifications
 */
export const getJobClassificationQualifications = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-qualifications/" +
          jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_QUALIFICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

/*
 * @Get getJobClassificationExperiences
 * GET /api/structure/job-classification-experiences
 */
export const getJobClassificationExperiences = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/job-classification-experiences/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_EXPERIENCES,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getJobClassificationCompetencies = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/job-classification-competencies/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_COMPETENCIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//getJobClassificationCertificates

export const getJobClassificationCertificates = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/job-classification-certificates/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_CERTIFICATES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

/*
 * @Get getProfessionalCertificates
 * GET /api/structure/position-qualifications
 */
export const getProfessionalCertificates = (positionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/professional-certificates/" + positionId
      );

      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get getPositionKnowledges
 * GET /api/structure/position-knowledges
 */
export const getPositionKnowledges = (positionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/position-knowledges/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_KNOWLEDGES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
/*
 * @Get getDegrees
 * GET /api/structure/degrees
 */
export const getDegrees = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/degrees");

      dispatch({
        type: types.SET_DEGREES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

/*
 * @Get DegreeQualifications
 * GET /api/structure/degree-qualifications
 */
export const getQualifications = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_QUALIFICATIONS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/qualifications");

      dispatch({
        type: types.SET_QUALIFICATIONS,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_QUALIFICATIONS,
        data: [],
      });
      showError(error);
    }
  };
};

export const getCompetencies = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_COMPETENCIES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/competencies");

      dispatch({
        type: types.SET_COMPETENCIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getCertificateFields = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_CERTIFICATE_FIELDS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/certificate-fields");

      dispatch({
        type: types.SET_CERTIFICATE_FIELDS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getCertificates = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/certificates");

      dispatch({
        type: types.SET_CERTIFICATES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

/*
 * @Get knowledge
 * GET /api/lookup/knowledge
 */
export const getknowledge = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_KNOWLEDGE,
        data: [],
      });

      const { data } = await axios.get("/api/lookup/knowledge");

      dispatch({
        type: types.SET_KNOWLEDGE,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_KNOWLEDGE,
        data: [],
      });
      showError(error);
    }
  };
};

/*
 * @Get certificateTypes
 * GET /api/lookup/certificate-types
 */
export const getCertificateTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data: [],
      });

      const { data } = await axios.get("/api/lookup/certificate-types");

      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data: [],
      });
      showError(error);
    }
  };
};

/*
 * @Get professionalCertificateTypes
 * GET /api/lookup/professional-certificate-types/:certificateTypeId
 */
export const getProfessionalCertificateTypes = (certificateTypeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/lookup/professional-certificate-types/" + certificateTypeId
      );

      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data: [],
      });
      showError(error);
    }
  };
};

/********************************************* EMPLOYEEEEE***************************************** */

//setSearchEmployees

export const setSearchEmployees = (
  searchTeam,
  employees,
  searchCurrentEmployees
) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_EMPLOYEES,
      data: "",
    });

    dispatch({
      type: types.SEARCH_CURRENT_EMPLOYEES,
      data: !!searchCurrentEmployees.length
        ? searchCurrentEmployees
        : employees,
    });

    let data = [];

    if (!searchTeam) {
      data = searchCurrentEmployees;
    } else {
      data = [...employees].filter(
        ({ firstName, lastName, positionName }) =>
          firstName.toLowerCase().includes(searchTeam.toLowerCase()) ||
          lastName.toLowerCase().includes(searchTeam.toLowerCase()) ||
          positionName.toLowerCase().includes(searchTeam.toLowerCase())
      );
    }

    dispatch({
      type: types.SET_EMPLOYEES,
      data: arrangingEmployees(data),
    });
  };
};

export const getUnitEmployees = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEES,
        data: [],
      });

      dispatch({
        type: types.SEARCH_CURRENT_EMPLOYEES,
        data: [],
      });

      dispatch({
        type: types.SEARCH_EMPLOYEES,
        data: "",
      });

      const { data } = await axios.get("/api/structure/employees/" + unitId);

      dispatch({
        type: types.SET_EMPLOYEES,
        data: arrangingEmployees(data),
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEntitySectorEmployees = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // dispatch({
      //   type: types.SET_EMPLOYEES,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_CURRENT_EMPLOYEES,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_EMPLOYEES,
      //   data: "",
      // });

      const { data } = await axios.get("/api/structure/employees");

      dispatch({
        type: types.SET_EMPLOYEES,
        data: arrangingEmployees(data),
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getNationalIdDetail = (idNumber, employeeId = 0) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_NATIONAL_ID_DETAIL,
        data: null,
      });

      const { data } = await axios.get(
        "/api/structure/check-employee-nid-number-detail/" +
          idNumber +
          "/" +
          employeeId
      );

      dispatch({
        type: types.SET_NATIONAL_ID_DETAIL,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const getEmployee = (employeeId, unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEE,
        data: null,
      });

      const { data } = await axios.get(
        "/api/structure/employee/" + employeeId + "/" + unitId
      );

      dispatch({
        type: types.SET_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveEmployee = (formData, setShowEmployeeForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/employees", formData);

      dispatch({
        type: types.ADD_EMPLOYEE,
        data,
      });
      setShowEmployeeForm(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//updatePersonalInfo

export const updatePersonalInfo = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/personal-info",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Personal info updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//updateEmployment

export const updateEmployment = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/employment",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Employment updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//updateEmployeeInsurance

export const updateEmployeeInsurance = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/insurance",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Insurance updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//updateBanking

export const updateBanking = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/banking",
        formData
      );

      // dispatch({
      //   type: types.UPDATE_EMPLOYEE,
      //   data
      // });
      dispatch({ type: types.END_LOADING });
      showSuccess("Banking updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const removeSecondBank = (
  formData,
  setAddBank2,
  setConfirmRemoveBank2
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/structure/employee/remove-second-bank", formData);

      dispatch({ type: types.END_LOADING });
      showSuccess("Second Bank removed successfully");
      setAddBank2(false);
      setConfirmRemoveBank2(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSalaryIndexGrid = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SALARAY_INDEX_GRID,
        data: null,
      });

      const { data } = await axios.get("/api/structure/salary-index-grid");

      let columns = [];
      if (!!data.length) {
        const obj = data[0];
        columns = Object.keys(obj);
      }

      dispatch({
        type: types.SET_SALARAY_INDEX_GRID,
        data: {
          rows: data,
          columns: columns,
        },
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateSalaryIndexGrid = (level, scale, value, type, setValue) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // console.log(setValue);

      if (type === "number") setValue("");

      await axios.post("/api/structure/update-salary-index-grid", {
        level: level,
        scale: scale,
        value: value,
      });

      setValue(value);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      // if (type === "number") setValue("");

      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const getJobClassifications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_JOB_CLASSIFICATIONS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/job-classifications");

      dispatch({
        type: types.SET_JOB_CLASSIFICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//view-job-classifications

export const getVJobClassifications = (entityClassId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_VJOB_CLASSIFICATIONS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/v-job-classifications/" + entityClassId
      );

      dispatch({
        type: types.SET_VJOB_CLASSIFICATIONS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//shared-scopes

export const getSharedScopes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SHARED_SCOPES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/shared-scopes");

      dispatch({
        type: types.SET_SHARED_SCOPES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
export const saveJobClassificationJob = (
  formData,
  setFormData,
  setShowAddJobForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classifications",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setFormData({
        name: "",
        entityClassId: "",
        jobCategoryId: "",
        jobCategoryName: "",
        levelId: "",
        levelName: "",
      });

      setShowAddJobForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//updateJob

export const updateJob = (formData, setFormData, setShowUpdateJobForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/jobs/" + formData.jobId,
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setFormData({
        name: "",
        jobId: "",
        entityClassId: "",
      });

      setShowUpdateJobForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//updateJobClassificationField
export const updateJobClassificationField = (
  formData,
  setFormData,
  setShowUpdateJobFieldForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/job-classification-field/" +
          formData.jobClassificationId,
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setFormData({
        jobClassificationId: "",
        jobFieldId: "",
      });

      setShowUpdateJobFieldForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//saveJobClassificationAllowance

export const saveJobClassificationAllowance = (
  formData,
  setFormData,
  setAdd
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_ALLOWANCE,
        data,
      });

      setFormData({
        jobClassificationId: formData.jobClassificationId,
        allowanceId: "",
        isActive: true,
      });

      setAdd(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//job-classification-allowances
export const getJobClassificationAllowances = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_CLASSIFICATION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-allowances/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//deleteJob

export const deleteJob = (selectedJob, setConfirmRemoveJob) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/delete-job-classifications",
        selectedJob
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setConfirmRemoveJob(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteJobClassificationAllowance = (
  selectedJobClassificationAllowance,
  setConfirmRemoveJobClassificationAllowance,
  setSelectedJobClassificationAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/job-classification-allowances/" +
          selectedJobClassificationAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_ALLOWANCE,
        data: selectedJobClassificationAllowance,
      });

      setConfirmRemoveJobClassificationAllowance(false);
      setSelectedJobClassificationAllowance(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//unit-allowances
export const getUnitAllowances = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_UNIT_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/unit-allowances/" + unitId
      );

      dispatch({
        type: types.SET_UNIT_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEntitySectorAllowances = (entitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_ENTITY_SECTOR_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/entity-sector-allowances/" + entitySectorId
      );

      dispatch({
        type: types.SET_ENTITY_SECTOR_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//getPositionAllowances

export const getPositionAllowances = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-allowances/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//shared-position-allowances

export const getSharedPositionAllowances = (sharedPositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SHARED_POSITION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/shared-position-allowances/" + sharedPositionId
      );

      dispatch({
        type: types.SET_SHARED_POSITION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveUnitAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/unit-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_UNIT_ALLOWANCE,
        data,
      });

      setFormData({
        unitId: formData.unitId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//saveEntitySectorAllowance

export const saveEntitySectorAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/entity-sector-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_ENTITY_SECTOR_ALLOWANCE,
        data,
      });

      setFormData({
        entitySectorId: formData.entitySectorId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePositionAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_POSITION_ALLOWANCE,
        data,
      });

      setFormData({
        positionId: formData.positionId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveSharedPositionAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/shared-position-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_SHARED_POSITION_ALLOWANCE,
        data,
      });

      setFormData({
        sharedPositionId: formData.sharedPositionId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveEmployeeAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_EMPLOYEE_ALLOWANCE,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveUnitCreditor = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = !canEditCreditor
        ? await axios.post("/api/structure/unit-withholds", formData)
        : await axios.put(
            "/api/structure/unit-withholds/" + formData.id,
            formData
          );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        canEditCreditor ? "Updated successfully" : "Saved successfully"
      );

      dispatch({
        type: canEditCreditor
          ? types.UPDATE_UNIT_WITHHOLD
          : types.ADD_UNIT_WITHHOLD,
        data: data,
      });

      setFormData({
        unitId: formData.unitId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePositionCreditor = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = !canEditCreditor
        ? await axios.post("/api/structure/position-withholds", formData)
        : await axios.put(
            "/api/structure/position-withholds/" + formData.id,
            formData
          );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        canEditCreditor ? "Updated successfully" : "Saved successfully"
      );

      dispatch({
        type: canEditCreditor
          ? types.UPDATE_POSITION_WITHHOLD
          : types.ADD_POSITION_WITHHOLD,
        data: data,
      });

      setFormData({
        positionId: formData.positionId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveSharedPositionCreditor = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = !canEditCreditor
        ? await axios.post("/api/structure/shared-position-withholds", formData)
        : await axios.put(
            "/api/structure/shared-position-withholds/" + formData.id,
            formData
          );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        canEditCreditor ? "Updated successfully" : "Saved successfully"
      );

      dispatch({
        type: canEditCreditor
          ? types.UPDATE_SHARED_POSITION_WITHHOLD
          : types.ADD_SHARED_POSITION_WITHHOLD,
        data: data,
      });

      setFormData({
        sharedPositionId: formData.sharedPositionId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveEmployeeCreditor = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = !canEditCreditor
        ? await axios.post("/api/structure/employee-withholds", formData)
        : await axios.put(
            "/api/structure/employee-withholds/" + formData.id,
            formData
          );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        canEditCreditor ? "Updated successfully" : "Saved successfully"
      );

      dispatch({
        type: canEditCreditor
          ? types.UPDATE_EMPLOYEE_WITHHOLD
          : types.ADD_EMPLOYEE_WITHHOLD,
        data: data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveSalaryStructure = (
  formData,
  setFormData,
  setShowAddNewForm,
  setJobClassification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/salary-structures",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_SALARY_STRUCTURE,
        data,
      });

      setFormData({
        hiringModeId: "",
        employeeGroupId: "",
        scaleId: "",
        indexValue: 0,
        numberOfPosts: 0,
        name: "",
        levelId: "",
        jobClassificationId: "",
        jobFieldId: "",
        entitySectorId: 0,
      });
      setJobClassification(null);
      setShowAddNewForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//updateSalaryStructure

export const updateSalaryStructure = (
  formData,
  setFormData,
  setJobClassification,
  setShowSalaryStructure,
  id
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/salary-structures/" + id,
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_SALARY_STRUCTURE,
        data,
      });

      setFormData({
        hiringModeId: "",
        employeeGroupId: "",
        scaleId: "",
        indexValue: 0,
        numberOfPosts: 0,
        name: "",
        levelId: "",
        jobClassificationId: "",
        jobFieldId: "",
        entitySectorId: 0,
      });
      setJobClassification(null);

      setShowSalaryStructure(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteSalaryStructure = (
  selectedSalaryStructure,
  setSelectedSalaryStructure,
  setConfirmRemoveSalaryStructure
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/salary-structures/" + selectedSalaryStructure.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_SALARY_STRUCTURE,
        data: selectedSalaryStructure,
      });

      setConfirmRemoveSalaryStructure(false);
      setSelectedSalaryStructure(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteUnitAllowance = (
  selectedUnitAllowance,
  setConfirmRemoveUnitAllowance,
  setSelectedUnitAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/unit-allowances/" + selectedUnitAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_UNIT_ALLOWANCE,
        data: selectedUnitAllowance,
      });

      setConfirmRemoveUnitAllowance(false);
      setSelectedUnitAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//deleteEntitySectorAllowance

export const deleteEntitySectorAllowance = (
  selectedEntitySectorAllowance,
  setConfirmRemoveEntitySectorAllowance,
  setSelectedEntitySectorAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/entity-sector-allowances/" +
          selectedEntitySectorAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_ENTITY_SECTOR_ALLOWANCE,
        data: selectedEntitySectorAllowance,
      });

      setConfirmRemoveEntitySectorAllowance(false);
      setSelectedEntitySectorAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteEmployeeAllowance = (
  selectedEmployeeAllowance,
  setConfirmRemoveEmployeeAllowance,
  setSelectedEmployeeAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-allowances/" + selectedEmployeeAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_ALLOWANCE,
        data: selectedEmployeeAllowance,
      });

      setConfirmRemoveEmployeeAllowance(false);
      setSelectedEmployeeAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deletePositionAllowance = (
  selectedPositionAllowance,
  setConfirmRemovePositionAllowance,
  setSelectedPositionAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/position-allowances/" + selectedPositionAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_POSITION_ALLOWANCE,
        data: selectedPositionAllowance,
      });

      setConfirmRemovePositionAllowance(false);
      setSelectedPositionAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteSharedPositionAllowance = (
  selectedSharedPositionAllowance,
  setConfirmRemoveSharedPositionAllowance,
  setSelectedSharedPositionAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/shared-position-allowances/" +
          selectedSharedPositionAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_SHARED_POSITION_ALLOWANCE,
        data: selectedSharedPositionAllowance,
      });

      setConfirmRemoveSharedPositionAllowance(false);
      setSelectedSharedPositionAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteSharedPositionWithHolds = (
  selectedSharedPositionWithHold,
  setConfirmRemoveSharedPositionWithHold,
  setSelectedSharedPositionWithHold
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/shared-position-withholds/" +
          selectedSharedPositionWithHold.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_SHARED_POSITION_WITHHOLD,
        data: selectedSharedPositionWithHold,
      });

      setConfirmRemoveSharedPositionWithHold(false);
      setSelectedSharedPositionWithHold(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteUnitWithHolds = (
  selectedUnitCreditor,
  setConfirmRemoveUnitCreditor,
  setSelectedUnitCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/unit-withholds/" + selectedUnitCreditor.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_UNIT_WITHHOLD,
        data: selectedUnitCreditor,
      });

      setConfirmRemoveUnitCreditor(false);
      setSelectedUnitCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deletePositionWithHolds = (
  selectedPositionCreditor,
  setConfirmRemovePositionCreditor,
  setSelectedPositionCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/position-withholds/" + selectedPositionCreditor.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_POSITION_WITHHOLD,
        data: selectedPositionCreditor,
      });

      setConfirmRemovePositionCreditor(false);
      setSelectedPositionCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteEmployeeWithHolds = (
  selectedEmployeeCreditor,
  setConfirmRemoveEmployeeCreditor,
  setSelectedEmployeeCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-withholds/" + selectedEmployeeCreditor.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_WITHHOLD,
        data: selectedEmployeeCreditor,
      });

      setConfirmRemoveEmployeeCreditor(false);
      setSelectedEmployeeCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//unit-withholds
export const getUnitWithholds = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_UNIT_WITHHOLDS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/unit-withholds/" + unitId
      );

      dispatch({
        type: types.SET_UNIT_WITHHOLDS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionWithholds = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_WITHHOLDS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-withholds/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_WITHHOLDS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSharedPositionWithholds = (sharedPositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SHARED_POSITION_WITHHOLDS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/shared-position-withholds/" + sharedPositionId
      );

      dispatch({
        type: types.SET_SHARED_POSITION_WITHHOLDS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getJobFields = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_FIELDS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/structure/job-fields");

      dispatch({
        type: types.SET_JOB_FIELDS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEntitySectors = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_ENTITY_SECTORS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/entity-sectors");

      dispatch({
        type: types.SET_ENTITY_SECTORS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getJobClassificationEmployees = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_CLASSIFICATION_EMPOYEES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-employees/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_EMPOYEES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const lockEntitySector = (selectedEntitySector, setIsLocked) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.put(
        "/api/structure/lock-entity-sector/" + selectedEntitySector.id,
        { isLocked: selectedEntitySector.isLocked }
      );
      dispatch({ type: types.END_LOADING });
      setIsLocked(selectedEntitySector.isLocked);
      dispatch({
        type: types.UPDATE_ENTITY_SECTOR,
        data: selectedEntitySector,
      });

      showSuccess("Updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionCertificates = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/position-certificates/" + jobClassificationId
      );

      dispatch({
        type: types.SET_POSITION_CERTIFICATES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionQualifications = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_QUALIFICATIONS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-qualifications/" + jobClassificationId
      );

      dispatch({
        type: types.SET_POSITION_QUALIFICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionExperiences = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_EXPERIENCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-experiences/" + jobClassificationId
      );

      dispatch({
        type: types.SET_POSITION_EXPERIENCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPositionCompetencies = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_COMPETENCIES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-competencies/" + jobClassificationId
      );

      dispatch({
        type: types.SET_POSITION_COMPETENCIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const doVerifyRssbNumber = (documentNumber) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_VERIFY_RSSB_NUMBER,
        data: null,
      });
      console.log(documentNumber);
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/external/verify-rssb-number/" + documentNumber
      );

      dispatch({
        type: types.SET_VERIFY_RSSB_NUMBER,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

//saveRequisition
export const saveRequisition = (
  requisitionForm,
  setRequisitionForm,
  setShowAddRequisitionForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/requisitions",
        requisitionForm
      );

      showSuccess("Saved successfully");

      setShowAddRequisitionForm(false);
      dispatch({
        type: types.UPDATE_POSITION,
        data: data,
      });

      setRequisitionForm({
        positionId: "",
        numberOfPosts: "",
        isInternal: false,
        reason: "",
        willHaveWrittenExam: true,
        willHaveOralExam: true,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
