import React from "react";

import { AppBar, Box, Toolbar } from "@mui/material";

const Header2 = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className={"app-bar2"}>
        <Toolbar className="pr-2">
          <div className="container-fluid px-0 pt-4">
            <p
              className="text-uppercase text-center"
              style={{
                fontWeight: "50",
                color: "#f1f1f1",
                fontSize: "xx-large",
                marginBottom: 150 + "px",
              }}
            >
              <span>WELCOME TO EMPLOYEE SELF-SERVICE PORTAL</span>
            </p>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header2;
