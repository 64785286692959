import React, { useEffect, useState } from "react";
import OtherLeaveRequestForm from "../components/OtherLeaveRequestForm";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import { connect } from "react-redux";
import {
  cancelLeaveRequest,
  getMyOtherLeaveRequests,
} from "../../../store/leave/actions";
import { LeavePlanRow } from "./LeavePlan";
import AlertDialog from "../../profile/components/AlertDialog";
import LeaveLogsDialog from "../components/LeaveLogsDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const OtherLeave = (props) => {
  const { loading, myOtherLeaveRequests, cancelLeaveRequest, user } = props;
  const [loadingLeaveRequests, setLoadingLeaveRequests] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(null);
  const [makeAction, setMakeAction] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLogs, setShowLogs] = useState(null);
  const [showSupportingDoc, setShowSupportingDoc] = useState(null);
  const [showLeaveLetter, setShowLeaveLetter] = useState(null);

  useEffect(() => {
    if (myOtherLeaveRequests.length === 0) {
      setLoadingLeaveRequests(true);
      props.getMyOtherLeaveRequests(() => setLoadingLeaveRequests(false));
    } else setLoadingLeaveRequests(false);
    return () => {
      setLoadingLeaveRequests(true);
      setSelectedRequest(null);
      setShowRequestForm(false);
    };
  }, []);

  useEffect(() => {
    setLoadingLeaveRequests(true);
    props.getMyOtherLeaveRequests(() => setLoadingLeaveRequests(false));
  }, [
    user?.RBM_fiscalYear?.id,
    user?.currentEmployeePosition?.employeePositionId,
  ]);

  const handleCancelLeaveRequest = (requestId) => {
    cancelLeaveRequest(requestId, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        setCancelRequest(null);
      }
    });
  };

  return (
    <>
      <div className="row w-100 my-3 px-4">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <span className="text-uppercase text-primary font-weight-bold">
              Other Leave Request
            </span>

            <Button
              variant="contained"
              size="small"
              startIcon={<span className="material-icons-round">post_add</span>}
              onClick={() => setShowRequestForm(true)}
              disabled={loading || loadingLeaveRequests}
              className="mb-1"
            >
              Request Leave
            </Button>
          </div>
          <TableContainer>
            <Table size="small" aria-label="Your annual leave balance">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>Leave Reason</TableCell>
                  <TableCell>Starting On</TableCell>
                  <TableCell>Ending On</TableCell>
                  <TableCell>Leave Days</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell className="text-right">Action</TableCell>
                </TableRow>
              </TableHead>
              {loadingLeaveRequests ? (
                <TableSkeleton cols={7} isMUI />
              ) : (
                <TableBody>
                  {myOtherLeaveRequests.map((request, index) => (
                    <LeavePlanRow
                      plan={request}
                      index={index}
                      loading={loadingLeaveRequests || loading}
                      setSelectedItem={setSelectedRequest}
                      setShowForm={setShowRequestForm}
                      setCancelRequest={setCancelRequest}
                      setMakeAction={setMakeAction}
                      key={request.id}
                      setShowLogs={setShowLogs}
                      setShowLeaveLetter={setShowLeaveLetter}
                      setShowSupportingDoc={setShowSupportingDoc}
                      isForOtherRequest
                    />
                  ))}
                  {!myOtherLeaveRequests.length && (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center">
                        No leave request found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>

      {showRequestForm && (
        <OtherLeaveRequestForm
          setShowRequestForm={() => {
            setShowRequestForm(false);
            setSelectedRequest(null);
          }}
          selectedRequest={selectedRequest}
        />
      )}

      {cancelRequest && (
        <AlertDialog
          status={true}
          handleClose={() => setCancelRequest(null)}
          handleConfirm={() => handleCancelLeaveRequest(cancelRequest.id)}
          title="Cancel Leave Request?"
          desc="Are you sure you want to cancel this leave request?"
          disabled={loading || isSubmitting}
        />
      )}

      {!!showLogs && (
        <LeaveLogsDialog setShowLogs={setShowLogs} selectLeave={showLogs} />
      )}

      {((!!showLeaveLetter && !!showLeaveLetter?.letterReferenceId) ||
        (!!showSupportingDoc && !!showSupportingDoc?.fileReferenceId)) && (
        <PreviewPdfUrl
          showDocument={true}
          setShowDocument={() => {
            setShowLeaveLetter(null);
            setShowSupportingDoc(null);
          }}
          document={{
            name: `View ${
              showLeaveLetter ? "Leave Letter" : "Supporting Document"
            }`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              (showLeaveLetter?.letterReferenceId ||
                showSupportingDoc?.fileReferenceId),
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, myOtherLeaveRequests, user }) => {
  return {
    loading,
    myOtherLeaveRequests,
    user,
  };
};
export default connect(mapStateToProps, {
  getMyOtherLeaveRequests,
  cancelLeaveRequest,
})(OtherLeave);
