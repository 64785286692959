import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { connect } from "react-redux";

const Breadcrumb = (props) => {
  const { user } = props;
  const [currentEmployment, setcurrentEmployment] = useState(null);
  const location = useLocation();

  return (
    <>
      <div style={{marginTop: '0px'}}>
      <div style={{backgroundColor: "#4f81bc", height: '3px'}}></div>
      <div style={{backgroundColor: "#e5cb05",height: '3px'}}></div>
      <div style={{backgroundColor: "#199e05",height: '3px'}}></div>
    </div>

      {/* <div className="d-flex bread-crumb align-items-center ">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="#"
          className={` px-1 rounded`}
        >
          {user.currentEmployeePosition && (
            <>
              <span className="text-truncate  menu-item text-uppercase">
                {user.currentEmployeePosition.unitName}
              </span>
            </>
          )}
        </Link>
        <ChevronRightIcon className="text-light" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="#"
          className={` px-1 rounded`}
        >
          {user.currentEmployeePosition && (
            <>
              <span className="text-truncate  menu-item">
                {user.currentEmployeePosition.positionName}
              </span>
            </>
          )}
        </Link>
      </div> */}
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Breadcrumb);
