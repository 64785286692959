import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PlanAnnualLeaveForm from "../components/PlanAnnualLeaveForm";
import {
  cancelLeavePlan,
  changeLeavePlanStatus,
  getMyAnnualLeavePlan,
} from "../../../store/leave/actions";
import {
  Alert,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import AlertDialog from "../../profile/components/AlertDialog";
import LeaveLogsDialog from "../components/LeaveLogsDialog";
import ViewPostponedLeaveDetails from "../components/ViewPostponedLeaveDetails";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

const LeavePlan = (props) => {
  const {
    env,
    loading,
    user,
    myLeavePlans,
    getMyAnnualLeavePlan,
    cancelLeavePlan,
    changeLeavePlanStatus,
  } = props;
  const [loadingLeavePlans, setLoadingLeavePlans] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cancelPlan, setCancelPlan] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeAction, setMakeAction] = useState(null);
  const [showLogs, setShowLogs] = useState(null);
  const [showPostponedDetails, setShowPostponedDetails] = useState(null);
  const [plannedDays, setPlannedDays] = useState(0);
  const [showLeaveLetter, setShowLeaveLetter] = useState(null);
  const planningPeriod = {
    start: moment(user?.RBM_fiscalYear?.startOn)
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    end: moment(user?.RBM_fiscalYear?.startOn)
      .add(81, "days")
      .format("YYYY-MM-DD"),
  };

  const canPlanLeave =
    env !== "prod" ||
    moment(user?.currentDate).isBetween(
      planningPeriod.start,
      planningPeriod.end,
      "days",
      "[]"
    );

  useEffect(() => {
    if (myLeavePlans.length === 0) {
      getMyAnnualLeavePlan(setLoadingLeavePlans);
    } else setLoadingLeavePlans(false);
    return () => {
      setLoadingLeavePlans(true);
    };
  }, []);

  useEffect(() => {
    getMyAnnualLeavePlan(setLoadingLeavePlans);
  }, [
    user?.RBM_fiscalYear?.id,
    user?.currentEmployeePosition?.employeePositionId,
  ]);

  useEffect(() => {
    if (myLeavePlans.length) {
      setPlannedDays(
        myLeavePlans
          .filter((item) => item.statusId !== 7)
          .reduce((acc, plan) => acc + plan.numOfDays, 0)
      );
    } else setPlannedDays(0);
  }, [myLeavePlans]);

  const [showCreateAnnualLeave, setShowCreateAnnualLeave] = useState(false);

  const handleCancelLeavePlan = (planId) => {
    cancelLeavePlan(planId, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        setCancelPlan(null);
      }
    });
  };

  const handleChangeStatus = (plan) => {
    const formData = {
      leavePlanId: plan.id,
      postponedId: plan.postponedId,
      statusId: makeAction.status,
      comment: "",
    };
    changeLeavePlanStatus(formData, (loading, res) => {
      setIsSubmitting(loading);
      if (!loading && res) {
        setMakeAction(null);
      }
    });
  };

  return (
    <>
      <div className="row w-100 my-3 px-4">
        <div className="col-12 mb-2">
          <Alert variant="standard" severity="info" className="text-info">
            <span className="font-weight-bold mr-1">Note:</span>
            You can create up to <b>3</b> leave plans for a fiscal year.
          </Alert>
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <span className="text-uppercase text-primary font-weight-bold">
              My Leave Plans
            </span>

            <Button
              variant="contained"
              size="small"
              startIcon={<span className="material-icons-round">post_add</span>}
              onClick={() => setShowCreateAnnualLeave(true)}
              disabled={
                !canPlanLeave ||
                loading ||
                loadingLeavePlans ||
                myLeavePlans.length === 3 ||
                (user.leaveDays || 0) <= plannedDays
              }
            >
              Create Plan
            </Button>
          </div>
          <TableContainer>
            <Table size="small" aria-label="Your annual leave balance">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>Starting On</TableCell>
                  <TableCell>Ending On</TableCell>
                  <TableCell>Planned Days</TableCell>
                  <TableCell>Planned On</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell className="text-right">Action</TableCell>
                </TableRow>
              </TableHead>
              {loadingLeavePlans ? (
                <TableSkeleton cols={7} isMUI />
              ) : (
                <TableBody>
                  {myLeavePlans.map((plan, index) => (
                    <LeavePlanRow
                      plan={plan}
                      index={index}
                      loading={loadingLeavePlans || loading}
                      setSelectedItem={setSelectedPlan}
                      setShowForm={setShowCreateAnnualLeave}
                      setCancelRequest={setCancelPlan}
                      setMakeAction={setMakeAction}
                      key={plan.id}
                      setShowLogs={setShowLogs}
                      setShowPostponedDetails={setShowPostponedDetails}
                      setShowLeaveLetter={setShowLeaveLetter}
                    />
                  ))}
                  {!!myLeavePlans.length && (
                    <TableRow className="tr-hovered">
                      <TableCell colSpan={3} className="text-right">
                        Total Days Planned
                      </TableCell>
                      <TableCell colSpan={4} className="text-left">
                        {plannedDays}/{user.leaveDays || 0} Days
                      </TableCell>
                    </TableRow>
                  )}
                  {!myLeavePlans.length && (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center">
                        No leave plan found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
      {showCreateAnnualLeave && (
        <PlanAnnualLeaveForm
          showCreateAnnualLeave={showCreateAnnualLeave}
          setShowCreateAnnualLeave={() => {
            setShowCreateAnnualLeave(false);
            setSelectedPlan(null);
          }}
          selectedPlan={selectedPlan}
          allPlannedDays={plannedDays}
        />
      )}
      {cancelPlan && (
        <AlertDialog
          status={true}
          handleClose={() => setCancelPlan(null)}
          handleConfirm={() => handleCancelLeavePlan(cancelPlan.id)}
          title="Cancel Leave Plan?"
          desc="Are you sure you want to cancel this leave plan?"
          disabled={loading || loadingLeavePlans || isSubmitting}
        />
      )}
      {makeAction && (
        <AlertDialog
          status={true}
          handleClose={() => setMakeAction(null)}
          handleConfirm={() => handleChangeStatus(makeAction)}
          title={makeAction.title}
          desc={makeAction.msg}
          disabled={loading || loadingLeavePlans || isSubmitting}
        />
      )}
      {!!showLogs && (
        <LeaveLogsDialog setShowLogs={setShowLogs} selectLeave={showLogs} />
      )}
      {!!showPostponedDetails && (
        <ViewPostponedLeaveDetails
          leavePlan={showPostponedDetails}
          setLeavePlan={setShowPostponedDetails}
        />
      )}
      {!!showLeaveLetter && !!showLeaveLetter?.letterReferenceId && (
        <PreviewPdfUrl
          showDocument={true}
          setShowDocument={() => setShowLeaveLetter(null)}
          document={{
            name: `View Authorization Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              showLeaveLetter?.letterReferenceId,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ env, loading, user, fiscalYears, myLeavePlans }) => {
  return { env, loading, user, fiscalYears, myLeavePlans };
};
export default connect(mapStateToProps, {
  getMyAnnualLeavePlan,
  cancelLeavePlan,
  changeLeavePlanStatus,
})(LeavePlan);

export const LeavePlanRow = (props) => {
  const {
    index,
    plan,
    loading,
    setSelectedItem,
    setShowForm,
    setCancelRequest,
    setMakeAction,
    isForOtherRequest,
    setShowLogs,
    setShowPostponedDetails,
    setShowSupportingDoc,
    setShowLeaveLetter,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      key={plan.id}
      // hover
      sx={
        {
          // cursor: "pointer",
        }
      }
      className="tr-hovered"
    >
      <TableCell>{index + 1}</TableCell>
      {isForOtherRequest && <TableCell>{plan.leaveReason}</TableCell>}
      <TableCell>{moment(plan.startDate).format("LL")}</TableCell>
      <TableCell>{moment(plan.endDate).format("LL")}</TableCell>
      <TableCell>{plan.numOfDays} Days</TableCell>
      {!isForOtherRequest && (
        <TableCell>{moment(plan.createdOn).format("LL")}</TableCell>
      )}
      <TableCell>
        <span
          className={`badge ${
            plan.statusId === 2 || plan.statusId === 9
              ? "badge-primary"
              : plan.statusId === 4
              ? "badge-info"
              : plan.statusId === 5
              ? "badge-light"
              : plan.statusId === 6
              ? "badge-success"
              : plan.statusId === 7
              ? "badge-danger"
              : "badge-secondary"
          }`}
        >
          <span
            className="material-icons-round"
            style={{
              fontSize: "0.8rem",
              verticalAlign: "middle",
              marginRight: "0.2rem",
            }}
          >
            {plan.statusId === 2
              ? "done"
              : plan.statusId === 3
              ? "done_all"
              : plan.statusId === 4
              ? "done_all"
              : plan.statusId === 5
              ? "fast_forward"
              : plan.statusId === 6
              ? "stop"
              : plan.statusId === 7
              ? "close"
              : plan.statusId === 9
              ? "next_plan"
              : "alarm_on"}
          </span>
          <span>{plan.statusName}</span>
        </span>
      </TableCell>
      <TableCell className="text-right">
        <IconButton
          color="primary"
          size="small"
          className="ml-2"
          onClick={handleOpenMenu}
          aria-label="settings"
        >
          <Badge color="info">
            <span className="material-icons">more_vert</span>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {([1, 7].includes(plan.statusId) ||
            (!!isForOtherRequest && plan.statusId === 3)) && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedItem(plan);
                  setShowForm(true);
                }}
                className="text-primary font-weight-bolder"
              >
                Edit
              </MenuItem>
              {/* <Divider className="my-1" /> */}
            </span>
          )}

          {!!plan.postponedId && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowPostponedDetails(plan);
                }}
                className="text-primary font-weight-light"
              >
                Postpone Details
              </MenuItem>
            </span>
          )}

          {!!plan.canRequestLeave && !isForOtherRequest && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setMakeAction({
                    status: 3,
                    id: plan.id,
                    postponedId: plan.postponedId,
                    title: "Requesting Take Leave",
                    msg: "You are about to request to take leave?",
                  });
                }}
                className="text-dark font-weight-light"
              >
                Request to take leave
              </MenuItem>
            </span>
          )}

          {/* {!!plan.canTakeLeave && !isForOtherRequest && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setMakeAction({
                    status: 5,
                    id: plan.id,
                    postponedId: plan.postponedId,
                    title: "Take Leave",
                    msg: "You are about to start your leave plan",
                  });
                }}
                className="text-dark font-weight-light"
              >
                Take Your Leave
              </MenuItem>
            </span>
          )} */}

          {([1, 7].includes(plan.statusId) ||
            (!!isForOtherRequest && plan.statusId === 3)) && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setCancelRequest(plan);
                }}
                className="text-danger font-weight-light"
              >
                Cancel leave
              </MenuItem>
            </span>
          )}

          {setShowSupportingDoc && plan.fileReferenceId && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowSupportingDoc(plan);
                }}
                className="text-dark font-weight-light"
              >
                Supporting Document
              </MenuItem>
            </span>
          )}

          {setShowLeaveLetter && plan.letterReferenceId && (
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowLeaveLetter(plan);
                }}
                className="text-dark font-weight-light"
              >
                Authorization Letter
              </MenuItem>
            </span>
          )}

          <span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                handleCloseMenu();
                setShowLogs(plan);
              }}
              className="text-dark font-weight-light"
            >
              Logs
            </MenuItem>
          </span>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
