import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { changeCurrentEmployment } from "../../../store/common/actions";

const EmploymentSwitcher = (props) => {
  const { loading, user, changeCurrentEmployment } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectedEmployment = (employment) => {
    changeCurrentEmployment(
      {
        employeePositionId: employment.employeePositionId,
        employeeId: employment.id,
      },
      (status, res) => {
        setSubmitting(status);
        if (!status && res) {
          handleCloseMenu();
        }
      }
    );
  };

  const activeIds = [0, 29];

  return (
    <>
      {user.employment.length > 1 && (
        <Box className="mr-2">
          <Button
            disabled={loading || submitting || user.employment.length <= 1}
            variant="text"
            disableElevation
            onClick={handleOpenMenu}
            sx={{ textTransform: "capitalize" }}
            endIcon={
              <span className="material-icons text-light">
                keyboard_arrow_down
              </span>
            }
          >
            <div className="d-flex align-items-center">
              <div
                className="text-light text-truncate"
                style={{
                  maxWidth: "130px",
                  height: "auto",
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                }}
                title={user.currentEmployeePosition?.positionName}
              >
                <span>
                  {user?.currentEmployeePosition?.isActing ? (
                    <span
                      style={{
                        color: "#00fc39",
                        fontSize: "0.9rem",
                      }}
                    >
                      Ag.
                    </span>
                  ) : (
                    ""
                  )}
                  <span>{user?.currentEmployeePosition?.positionName}</span>
                </span>
              </div>
              <div>
                {activeIds.includes(user.currentEmployeePosition?.statusId) && (
                  <span
                    className=" font-weight-bold"
                    style={{ color: "#00fc39" }}
                  >
                    ({user.currentEmployeePosition?.statusName})
                  </span>
                )}

                {!activeIds.includes(
                  user.currentEmployeePosition?.statusId
                ) && (
                  <span
                    className="font-weight-bold"
                    style={{ color: "#ffff00" }}
                  >
                    ({user.currentEmployeePosition?.statusName})
                  </span>
                )}
              </div>
            </div>
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {user.employment.map((employment) => (
              <MenuItem
                key={employment.employeePositionId}
                onClick={() => {
                  handleSelectedEmployment(employment);
                }}
                selected={
                  employment.employeePositionId ===
                  user.currentEmployeePosition.employeePositionId
                }
              >
                <span
                  className={`${
                    activeIds.includes(employment?.statusId)
                      ? "font-weight-bold "
                      : "font-weight-light"
                  }`}
                >
                  <span>
                    {employment?.isActing ? (
                      <span
                        style={{
                          color: "green",
                        }}
                      >
                        Ag.
                      </span>
                    ) : (
                      ""
                    )}
                    <span>{employment?.positionName}</span>
                  </span>
                  {activeIds.includes(employment?.statusId) && (
                    <span style={{ color: "green" }}>
                      ({employment?.statusName})
                    </span>
                  )}

                  {!activeIds.includes(employment?.statusId) && (
                    <span>({employment?.statusName})</span>
                  )}
                </span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return { loading, user };
};
export default connect(mapStateToProps, {
  changeCurrentEmployment,
})(EmploymentSwitcher);
