import { Box } from "@mui/material";
import React, { useState } from "react";

import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const ViewAttachmentTrainingRequestDialog = (props) => {
  const { attachments } = props;

  const [showDocument, setShowDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            {showDocument && (
              <PreviewPdfUrl
                showDocument={showDocument}
                setShowDocument={setShowDocument}
                document={{
                  name: `View ${selectedDocument.name}`,
                  url:process.env.REACT_APP_FILES_URL +'/preview/'+ selectedDocument.documentReferenceId,
                }}
              />
            )}
            <table className="table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    #
                  </th>
                  <th scope="col" className="text-left">
                    name
                  </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3"></span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {attachments.map((attachment, index) => (
                  <tr
                    key={index}
                    className="appeal-hovered"
                    onClick={() => {
                      setSelectedDocument(attachment);
                      setShowDocument(true);
                    }}
                  >
                    <td className="text-left">
                      <strong> {index + 1} </strong>
                    </td>

                    <td className="text-left">
                      <strong>
                        <AttachFileIcon className="text-primary" />
                        View {attachment.name}{" "}
                      </strong>
                    </td>

                    <td className="text-right">
                      <button type="button" className="btn btn-primary m-2">
                        <OpenInNewIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};

export default ViewAttachmentTrainingRequestDialog;
