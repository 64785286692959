import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import moment from "moment";
import ActionDialog from "../../common/components/ActionDialog";
import SecondLevelAppealDialog from "../components/SecondLevelAppealDialog";
import { textCapitalized } from "../../common/components/Utils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  employeeStatifiedWithDecision,
  getAppealDetails,
  getAppealResolved,
  downloadAppeal,
} from "../../../store/e-appeal/actions";
import { getCase } from "../../../store/discipline/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PreLoader from "../../common/components/PreLoader";
import AttachMoreFiles from "./AttachMoreFiles";
import ViewExtraAttachments from "./ViewExtraAttachments";
import ViewAppealResolved from "./ViewAppealResolved";
import PrintIcon from "@mui/icons-material/Print";
import ViewCaseDialog from "../../discipline/components/ViewCaseDialog";

const ViewAppealDialog = (props) => {
  const {
    loading,
    appeal,
    viewAppeal,
    setViewAppeal,
    employeeStatifiedWithDecision,
    getAppealDetails,
    appealDetails,
    getAppealResolved,
    appealResolved,
    downloadAppeal,
    getCase,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [loading1, setLoading1] = useState(false);
  const [addNewAppealForm, setAddNewAppealForm] = useState(false);

  const [showAttachExtraFiles, setShowAttachExtraFiles] = useState(false);
  const [showAppealResolved, setAppealResolved] = useState(false);
  const [showCaseDetail, setShowCaseDetail] = useState(false);
  const [confirmDecision, setConfirmDecision] = useState(false);

  const [firstLevelAppeal, setFirstLevelAppeal] = useState(null);
  const [secondLevelAppeal, setSecondLevelAppeal] = useState(null);

  const [firstLevelAttachments, setFirstLevelAttachments] = useState([]);
  const [firstLevelDecisionAttachments, setFirstLevelDecisionAttachments] =
    useState([]);

  const [secondLevelAttachments, setSecondLevelAttachments] = useState([]);
  const [secondLevelDecisionAttachments, setSecondLevelDecisionAttachments] =
    useState([]);

  const onClose = () => {
    setViewAppeal(false);
  };

  useEffect(
    () => {
      if (appeal) getAppealDetails(appeal.referenceNo);
      if (appeal && appeal.appealIdL2) getAppealResolved(appeal.appealIdL2);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appeal]
  );

  useEffect(
    () => {
      const firstLevelAppeal = appealDetails.find(({ level }) => level === 1);
      const secondLevelAppeal = appealDetails.find(({ level }) => level === 2);

      if (firstLevelAppeal) {
        const firstLevelAttachments = firstLevelAppeal.appealAttachments.filter(
          ({ createdBy }) => createdBy === firstLevelAppeal.employeeId
        );
        const firstLevelDecisionAttachments =
          firstLevelAppeal.appealAttachments.filter(
            ({ createdBy }) => createdBy === firstLevelAppeal.decisionBy
          );

        setFirstLevelAttachments(firstLevelAttachments);
        setFirstLevelDecisionAttachments(firstLevelDecisionAttachments);
      }

      if (secondLevelAppeal) {
        const secondLevelAttachments =
          secondLevelAppeal.appealAttachments.filter(
            ({ createdBy }) => createdBy === secondLevelAppeal.employeeId
          );
        const secondLevelDecisionAttachments =
          secondLevelAppeal.appealAttachments.filter(
            ({ createdBy }) => createdBy === secondLevelAppeal.decisionBy
          );

        setSecondLevelAttachments(secondLevelAttachments);
        setSecondLevelDecisionAttachments(secondLevelDecisionAttachments);
      }

      setFirstLevelAppeal(firstLevelAppeal);
      setSecondLevelAppeal(secondLevelAppeal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appealDetails]
  );

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={viewAppeal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          className="text-primary"
          style={{
            height: "90px",
          }}
        >
          <div className="row">
            <div className="col-10">
              <h5
                style={{
                  background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {appeal && appeal.subjectL1}
              </h5>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  {appeal &&
                    ((secondLevelAppeal && secondLevelAppeal.statusId === 3) ||
                      (firstLevelAppeal &&
                        firstLevelAppeal.statusId === 3)) && (
                      <button
                        type="button"
                        onClick={() =>
                          downloadAppeal(
                            appeal.referenceNo,
                            appeal.subjectL1,
                            setIsDownloading
                          )
                        }
                        className="btn btn-outline-secondary btn-sm my-2"
                      >
                        <PrintIcon className="text-primary float-end" />
                      </button>
                    )}
                </div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div
              className="px-3 py-3"
              style={{
                position: "relative",
                height: "450px",
                overflow: "auto",
              }}
            >
              <PreLoader isLoading={loading} />
              {firstLevelAppeal && (
                <div className="w-100  mt-0 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span className="badge rounded-pill badge-primary ml-3 fw-bold">
                      First level Appeal
                    </span>
                  </div>

                  {appeal && firstLevelAppeal && !!firstLevelAppeal.caseId && (
                    <div className="w-100 d-flex flex-row justify-content-end">
                      <button
                        type="button"
                        disabled={loading1}
                        onClick={() =>
                          getCase(
                            firstLevelAppeal.caseId,
                            setLoading1,
                            setShowCaseDetail
                          )
                        }
                        className="btn btn-secondary btn-sm my-2"
                      >
                        {loading1 ? "Wait..." : "View displinarly case"}
                      </button>
                    </div>
                  )}

                  <div
                    className={`d-flex flex-row ${
                      !firstLevelAppeal.caseId ? "mt-4" : ""
                    } justify-content-start mt-0 w-100`}
                  >
                    <div className="w-100">
                      <div
                        className="card rounded-3  w-100"
                        style={{
                          // backgroundColor: "#f5f6f7"
                          background: "rgba(0,99,207,.08)",
                        }}
                      >
                        {/* <div className="card-header border-bottom border-secondary">
                          <small className="small card-title text-primary fw-bold text-truncate">
                            {textCapitalized(
                              firstLevelAppeal.employee.firstName
                            )}{" "}
                            {textCapitalized(
                              firstLevelAppeal.employee.lastName
                            )}
                          </small>
                        </div> */}
                        <div className="card-body">
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: firstLevelAppeal.message,
                            }}
                          ></p>
                          <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                            <ViewExtraAttachments
                              files={firstLevelAttachments}
                              contents={
                                <>
                                  {!loading && (
                                    <>
                                      {" "}
                                      {appeal.statusIdL1 === 1 && (
                                        <a
                                          onClick={() =>
                                            setShowAttachExtraFiles(true)
                                          }
                                          className="btn  btn-primary btn-sm btn-rounded text-capitalize"
                                          style={{
                                            height: "24px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <span
                                            className="align-items-center"
                                            style={{
                                              marginTop: "-3.3px",
                                              display: "flex",
                                              position: "relative",
                                            }}
                                          >
                                            {" "}
                                            <i className="fas fa-plus"></i> Add
                                            more files
                                          </span>
                                        </a>
                                      )}
                                    </>
                                  )}
                                </>
                              }
                            />
                          </p>
                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(firstLevelAppeal.createdOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {firstLevelAppeal && firstLevelAppeal.decision && (
                              <>
                                {/* <div className="d-flex flex-row justify-content-center mt-4">
                                  <span className="badge rounded-pill badge-success ml-3 fw-bold">
                                    First level decision
                                  </span>
                                </div> */}
                                <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                  <div
                                    className="card rounded-3 card-border-left w-100"
                                    style={{
                                      backgroundColor: "#f5f6f7",
                                    }}
                                  >
                                    <div className="card-header border-bottom border-secondary">
                                      <div className="row">
                                        <div className="col-7">
                                          <small className="small card-title text-primary fw-bold text-truncate">
                                            First Level Decision
                                          </small>

                                          <small
                                            style={{
                                              fontSize: "11px",
                                            }}
                                            className="float-end w-100 fw-bold"
                                          >
                                            {textCapitalized(
                                              firstLevelAppeal.entitySector
                                                .entity.name
                                            )}
                                            (
                                            {textCapitalized(
                                              firstLevelAppeal.entitySector
                                                .sector.name
                                            )}
                                            )
                                          </small>
                                        </div>
                                        <div className={"col-5"}>
                                          <div className="mt-1 d-flex justify-content-end align-items-end pe-0">
                                            {firstLevelAppeal &&
                                              !secondLevelAppeal &&
                                              firstLevelAppeal.decision &&
                                              firstLevelAppeal.statusId !==
                                                3 && (
                                                <>
                                                  <a
                                                    disabled={isSubmitting}
                                                    onClick={() =>
                                                      setConfirmDecision(true)
                                                    }
                                                    className="btn btn-success mt-1 btn-sm text-capitalize"
                                                    href="#!"
                                                  >
                                                    I accept the decision
                                                  </a>
                                                  <a
                                                    disabled={isSubmitting}
                                                    onClick={() =>
                                                      setAddNewAppealForm(true)
                                                    }
                                                    className="btn ml-2 mt-1 btn-sm btn-primary text-capitalize"
                                                    href="#!"
                                                  >
                                                    Appeal to second level
                                                  </a>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="small p-0 ms-2 mb-0 rounded-3">
                                        <span
                                          className={`badge badge-pill mr-3 ${
                                            firstLevelAppeal.isValid
                                              ? "badge-success"
                                              : "badge-danger"
                                          }`}
                                        >
                                          Your appeal is{" "}
                                          {!firstLevelAppeal.isValid && "not"}{" "}
                                          valid
                                        </span>
                                        <p className="pt-2">
                                          {firstLevelAppeal.decision}
                                        </p>
                                      </div>
                                      <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                        <>
                                          {!!firstLevelDecisionAttachments.length && (
                                            <ViewExtraAttachments
                                              files={
                                                firstLevelDecisionAttachments
                                              }
                                            />
                                          )}
                                        </>
                                      </p>
                                      <div className="row mt-0">
                                        <div
                                          className={"col-12"}
                                        >
                                          <div className="d-flex justify-content-end align-items-end pe-0">
                                          <p className="small mt-3 mb-0 rounded-3 text-primary float-end ">
                                            {firstLevelAppeal &&
                                              firstLevelAppeal.statusId === 3 &&
                                              !secondLevelAppeal && (
                                                <span className="badge rounded-pill badge-success mr-3 fw-bold">
                                                  Appeal accepted{" "}
                                                  {firstLevelAppeal.closedBy}
                                                </span>
                                              )}

                                            <span
                                              style={{
                                                fontSize: "11px",
                                              }}
                                            >
                                              {moment(
                                                firstLevelAppeal.decisionOn
                                              ).format("lll")}
                                            </span>
                                          </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {firstLevelAppeal && !firstLevelAppeal.decision && (
                              <div className="d-flex flex-row justify-content-start mt-3 ml-3 mr-3 w-100 float-start">
                                <div
                                  className="card rounded-3 card-border-left w-100"
                                  style={{
                                    backgroundColor: "#f5f6f7",
                                  }}
                                >
                                  <div className="card-body">
                                    <p className="small p-0 ms-2 mb-0 rounded-3 text-primary">
                                      <AccessTimeIcon /> Waiting for first level
                                      appeal response!
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {secondLevelAppeal && (
                <div className="w-100  mt-3 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span className="badge rounded-pill badge-success ml-3 fw-bold">
                      Second level Appeal
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-start mt-4  w-100 float-start">
                    <div className="w-100">
                      <div
                        className="card rounded-3"
                        style={{
                          // backgroundColor: "#f5f6f7"
                          background: "#d6f0e0",
                        }}
                      >
                        <div className="card-body">
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: secondLevelAppeal.message,
                            }}
                          ></p>

                          <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                            <>
                              {" "}
                              {!!secondLevelAttachments.length && (
                                <ViewExtraAttachments
                                  files={secondLevelAttachments}
                                  contents={
                                    <>
                                      {!loading && (
                                        <>
                                          {" "}
                                          {appeal.statusIdL2 === 1 && (
                                            <a
                                              onClick={() =>
                                                setShowAttachExtraFiles(true)
                                              }
                                              className="btn  btn-primary btn-sm btn-rounded text-capitalize"
                                              style={{
                                                height: "24px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <span
                                                className="align-items-center"
                                                style={{
                                                  marginTop: "-3.3px",
                                                  display: "flex",
                                                  position: "relative",
                                                }}
                                              >
                                                {" "}
                                                <i className="fas fa-plus"></i>{" "}
                                                Add more files
                                              </span>
                                            </a>
                                          )}
                                        </>
                                      )}
                                    </>
                                  }
                                />
                              )}
                            </>
                          </p>
                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(secondLevelAppeal.createdOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {secondLevelAppeal &&
                              secondLevelAppeal.decision && (
                                <>
                                  <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                    <div
                                      className="card rounded-3 card-border-left w-100"
                                      style={{
                                        backgroundColor: "#f5f6f7",
                                      }}
                                    >
                                      <div className="card-header border-bottom border-secondary">
                                        <div className="row">
                                          <div className="col-8">
                                            <small className="small card-title text-primary fw-bold">
                                              Second Level Decision
                                            </small>

                                            <small
                                              style={{
                                                fontSize: "11px",
                                              }}
                                              className="float-end w-100 fw-bold"
                                            >
                                              National Public Service Commission
                                            </small>
                                          </div>
                                          <div className="col-4">
                                            {appealResolved &&
                                              secondLevelAppeal &&
                                              secondLevelAppeal.statusId ===
                                                3 && (
                                                <a
                                                  onClick={() =>
                                                    setAppealResolved(true)
                                                  }
                                                  className="btn btn-success btn-sm text-capitalize float-end"
                                                  href="#!"
                                                >
                                                  View Appeal Resolution
                                                </a>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body">
                                        <div className="small p-0 ms-2 mb-0 rounded-3">
                                          <span
                                            className={`badge badge-pill mr-3 ${
                                              secondLevelAppeal.isValid
                                                ? "badge-success"
                                                : "badge-danger"
                                            }`}
                                          >
                                            Your appeal is{" "}
                                            {!secondLevelAppeal.isValid &&
                                              "not"}{" "}
                                            valid
                                          </span>
                                          <p className="pt-2">
                                            {secondLevelAppeal.decision}
                                          </p>
                                        </div>

                                        <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                          <>
                                            {!!secondLevelDecisionAttachments.length && (
                                              <ViewExtraAttachments
                                                files={
                                                  secondLevelDecisionAttachments
                                                }
                                              />
                                            )}
                                          </>
                                        </p>
                                        <p className="small mt-1 mb-0 rounded-3 text-primary float-end ">
                                          <span
                                            style={{
                                              fontSize: "11px",
                                            }}
                                          >
                                            {moment(
                                              secondLevelAppeal.decisionOn
                                            ).format("lll")}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {secondLevelAppeal &&
                              !secondLevelAppeal.decision && (
                                <div className="d-flex flex-row justify-content-start mt-3 ml-3 mr-3 w-100 float-start">
                                  <div
                                    className="card rounded-3 card-border-left w-100"
                                    style={{
                                      backgroundColor: "#f5f6f7",
                                    }}
                                  >
                                    <div className="card-body">
                                      <p className="small p-0 ms-2 mb-0 rounded-3 text-primary">
                                        <AccessTimeIcon /> Waiting for second
                                        level appeal response!
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showAppealResolved && (
                <ViewAppealResolved
                  showAppealResolved={showAppealResolved}
                  setAppealResolved={setAppealResolved}
                  contents={
                    <>
                      {appealResolved &&
                        secondLevelAppeal &&
                        secondLevelAppeal.statusId === 3 && (
                          <div className="w-100  float-start">
                            <div className="d-flex flex-row justify-content-start mt-4 w-100 float-start">
                              <div
                                className="card rounded-3 card-border-left"
                                style={{
                                  backgroundColor: "#f5f6f7",
                                }}
                              >
                                <div className="card-header border-bottom border-secondary">
                                  <small className="small card-title text-primary fw-bold text-truncate">
                                    Appeal Resolution
                                  </small>

                                  <small
                                    style={{
                                      fontSize: "11px",
                                    }}
                                    className="float-end w-100 fw-bold"
                                  >
                                    {textCapitalized(
                                      firstLevelAppeal.entitySector.entity.name
                                    )}
                                    (
                                    {textCapitalized(
                                      firstLevelAppeal.entitySector.sector.name
                                    )}
                                    )
                                  </small>
                                </div>
                                <div className="card-body w-100">
                                  <p
                                    className="small p-0 ms-2 mb-0 rounded-3"
                                    dangerouslySetInnerHTML={{
                                      __html: appealResolved.message,
                                    }}
                                  ></p>
                                  <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                    {!!appealResolved.appealResolvedAttachments
                                      .length && (
                                      <ViewExtraAttachments
                                        files={
                                          appealResolved.appealResolvedAttachments
                                        }
                                      />
                                    )}
                                  </p>
                                  <p className="small mt-2 mb-0 rounded-3 text-primary float-end ">
                                    <span
                                      style={{
                                        fontSize: "11px",
                                      }}
                                    >
                                      {moment(appealResolved.resolvedOn).format(
                                        "lll"
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  }
                />
              )}
            </div>

            {/* {secondLevelAppeal && secondLevelAppeal.statusId === 5 && (
              <div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill badge-info ml-3 fw-bold">
                    Waiting an Appeal to be Resolved.
                  </span>
                </div>
              </div>
            )} */}

            {secondLevelAppeal && secondLevelAppeal.statusId === 3 && (
              <div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill badge-danger ml-3 fw-bold">
                    Appeal closed
                  </span>
                </div>
              </div>
            )}

            {showCaseDetail && (
              <ViewCaseDialog
                showCaseDetail={showCaseDetail}
                setShowCaseDetail={setShowCaseDetail}
              />
            )}
            {isSubmitting && (
              <ActionDialog showAction={isSubmitting} action="Wait..." />
            )}

            {isDownloading && (
              <ActionDialog
                showAction={isDownloading}
                action="Downloading..."
              />
            )}

            {addNewAppealForm && (
              <SecondLevelAppealDialog
                addNewAppealForm={addNewAppealForm}
                setAddNewAppealForm={setAddNewAppealForm}
                selectedAppeal={firstLevelAppeal}
              />
            )}
            {showAttachExtraFiles && (
              <AttachMoreFiles
                showAttachExtraFiles={showAttachExtraFiles}
                setShowAttachExtraFiles={setShowAttachExtraFiles}
                level={secondLevelAppeal ? 2 : 1}
                referenceNo={appeal.referenceNo}
                appealId={
                  secondLevelAppeal ? secondLevelAppeal.id : firstLevelAppeal.id
                }
              />
            )}

            {confirmDecision && (
              <ConfirmationDialog
                confirmationDialog={confirmDecision}
                message={`Do you accept the decision?`}
                setConfirmationDialog={setConfirmDecision}
                onYes={() => {
                  employeeStatifiedWithDecision(
                    firstLevelAppeal.id,
                    setIsSubmitting,
                    setConfirmDecision
                  );
                }}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  appeal,
  appealDetails,
  appealResolved,
}) => {
  return {
    loading,
    appeal,
    appealDetails,
    appealResolved,
  };
};
export default connect(mapStateToProps, {
  employeeStatifiedWithDecision,
  getAppealDetails,
  getAppealResolved,
  downloadAppeal,
  getCase,
})(ViewAppealDialog);
