import pdf from "../../assets/icons/pdf.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { useState } from "react";

const ViewExtraAttachments = (props) => {
  const { files, contents } = props;
  const [onViewDocument, setOnViewDocument] = useState(false);
  const [document, setDocument] = useState(null);
  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    link.click();
  }

  return (
    <div className="w-100">
      {onViewDocument && document && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `${document.fileName}`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              document.docReferenceId,
          }}
        />
      )}
      <div className="row align-items-center">
        {files.map((file, index) => (
          <div key={index} className="col-4 mt-2">
            <>
              <Chip
                avatar={<Avatar alt={file.fileName} src={pdf} />}
                size="small"
                onClick={() => {
                  setOnViewDocument(true);
                  setDocument(file);
                }}
                label={
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#0d141d",
                        fontWeight: "600",
                        width: "calc(100% - 80px)",
                        overflow: "hidden",
                        textDecoration: "none",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="text-truncate text-lowercase"
                    >
                      {file.fileName}
                    </span>
                  </>
                }
                variant="outlined"
                color="primary"
                deleteIcon={<CloudDownloadIcon />}
                onDelete={() => {
                  setOnViewDocument(true);
                  setDocument(file);
                }}
              />
            </>
          </div>
        ))}
        <div className="col mt-2">{contents}</div>
      </div>
    </div>
  );
};

export default ViewExtraAttachments;
