import React from "react";
import { IconButton } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";

const ReportingCard = ({
  title,
  overdue,
  reported,
  scored,
  pending,
  rejected,
  children,
  onDownload,
}) => {
  return (
    <div className={`card elevated service-card valid mb-2 mt-1`}>
      <div
        className={`py-4 rbm-card-header d-flex justify-content-between`}
        style={{ padding: "1.5rem 1.2rem" }}
      >
        <div className="d-flex align-items-center text-uppercase">
          <ViewListIcon />
          <div style={{ minWidth: "70px" }}>{title}</div>
        </div>
        <div className="d-flex align-items-center text-uppercase">
          <span
            className="badge badge-secondary text-left text-uppercase"
            style={{ minWidth: "90px" }}
          >
            <span
              className="badge badge-light bg-secondary text-light"
              style={{ fontSize: "12px" }}
            >
              {pending}
            </span>{" "}
            Ontrack
          </span>
          <span
            className="ml-2 badge badge-warning text-left text-uppercase"
            style={{ minWidth: "90px" }}
          >
            <span
              className="badge badge-light bg-warning text-light"
              style={{ fontSize: "12px" }}
            >
              {overdue}
            </span>{" "}
            Overdue
          </span>

          <span
            className="ml-2 badge badge-info text-uppercase"
            style={{ minWidth: "90px" }}
          >
            <span
              className="badge badge-light bg-info text-light"
              style={{ fontSize: "12px" }}
            >
              {reported}
            </span>{" "}
            Reported
          </span>
          <span
            className="ml-2 badge badge-success text-left text-uppercase"
            style={{ minWidth: "90px" }}
          >
            <span
              className="badge badge-light bg-success text-light"
              style={{ fontSize: "12px" }}
            >
              {scored}
            </span>{" "}
            Scored
          </span>
          <span
            className="mx-2 badge badge-danger text-left text-uppercase"
            style={{ minWidth: "90px" }}
          >
            <span
              className="badge badge-light bg-danger text-light"
              style={{ fontSize: "12px" }}
            >
              {rejected}
            </span>{" "}
            Rejected
          </span>
          <IconButton
            disabled={!onDownload}
            color="primary"
            size="small"
            onClick={onDownload}
          >
            <i className="fas fa-download"></i>
          </IconButton>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default ReportingCard;
