import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  createOtherLeaveRequest,
  geLeaveTypes,
  geGetLeaveReasons,
  calculateReturnDate,
  getHolidaysInPeriod,
  getMySupervisor,
} from "../../../store/leave/actions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { connect } from "react-redux";
import moment from "moment";

const OtherLeaveRequestForm = (props) => {
  const {
    loading,
    leaveTypes,
    selectedRequest,
    setShowRequestForm,
    createOtherLeaveRequest,
    geLeaveTypes,
    geGetLeaveReasons,
    calculateReturnDate,
    getHolidaysInPeriod,
    getMySupervisor,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingController, setLoadingController] = useState({
    supervisors: false,
    holidays: false,
  });
  const [formData, setFormData] = useState({
    leaveTypeId: "",
    leaveReasonId: "",
    startDate: null,
    numOfDays: 0,
    comment: "",
    leaveRequestId: "",
    supportingDocument: null,
  });
  const [errors, setErrors] = useState({
    leaveTypeHasError: false,
    leaveTypeErrorMessage: "",
    leaveReasonHasError: false,
    leaveReasonErrorMessage: "",
    startDateHasError: false,
    startDateErrorMessage: "",
    messageHasError: false,
    messageErrorMessage: "",
    numOfDaysHasError: false,
    numOfDaysErrorMessage: "",
  });
  const [leaveReasons, setLeaveReasons] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newNumOfDays, setNewNumOfDays] = useState(0);
  const [holidaysDuringLeavePeriod, setHolidaysDuringLeavePeriod] = useState(
    []
  );
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    if (!leaveTypes.length) {
      geLeaveTypes(setLoadingData);
    } else {
      setLoadingData(false);
    }
    if (selectedRequest) {
      setFormData({
        leaveTypeId: selectedRequest.leaveTypeId,
        leaveReasonId: selectedRequest.leaveReasonId,
        startDate: new Date(selectedRequest.startDate),
        numOfDays: selectedRequest.numOfDays,
        comment: selectedRequest.employeeComment || "",
        leaveRequestId: selectedRequest.id,
      });
      setSelectedType({
        id: selectedRequest.leaveTypeId,
        name: selectedRequest.leaveType,
      });
      setSelectedReason({
        id: selectedRequest.leaveReasonId,
        name: selectedRequest.leaveReason,
      });
    }

    if (!supervisors.length) {
      getMySupervisor((loading, data) => {
        setLoadingController({ ...loadingController, supervisors: loading });
        if (!loading && data) {
          setSupervisors(data);
          setLoadingController({ ...loadingController, supervisors: false });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (formData.leaveTypeId) {
      geGetLeaveReasons(formData.leaveTypeId, (loading, data) => {
        setLoadingData(loading);
        if (!loading) {
          setLeaveReasons(data);
        }
      });
    }
  }, [formData.leaveTypeId]);

  useEffect(() => {
    if (leaveReasons.length === 1) {
      setFormData({ ...formData, leaveReasonId: leaveReasons[0].id });
    }
  }, [leaveReasons]);

  useEffect(() => {
    if (!!selectedReason && !!formData.numOfDays && formData.startDate) {
      calculateReturnDate(
        {
          startDate: formData.startDate,
          numOfDays: formData.numOfDays,
        },
        (loading, data) => {
          setLoadingData(loading);
          if (!loading && data) setEndDate(data);
        }
      );
    }
  }, [formData.startDate, newNumOfDays]);

  useEffect(() => {
    if (!!endDate && !holidaysDuringLeavePeriod[0]) {
      getHolidaysInPeriod(
        {
          startDate: formData.startDate,
          endDate: endDate,
        },
        (loading, data) => {
          setLoadingController({ ...loadingController, holidays: loading });
          if (!loading && data) {
            setHolidaysDuringLeavePeriod(data);
            setLoadingController({ ...loadingController, holidays: false });
          }
        }
      );
    }
  }, [endDate]);

  const onClose = () => {
    setFormData({
      leaveTypeId: "",
      leaveReasonId: "",
      startDate: null,
      numOfDays: 0,
      comment: "",
      leaveRequestId: "",
    });
    setSelectedType(null);
    setSelectedReason(null);
    setErrors({
      leaveTypeHasError: false,
      leaveTypeErrorMessage: "",
      leaveReasonHasError: false,
      leaveReasonErrorMessage: "",
      startDateHasError: false,
      startDateErrorMessage: "",
      messageHasError: false,
      messageErrorMessage: "",
      numOfDaysHasError: false,
      numOfDaysErrorMessage: "",
    });
    setShowRequestForm(false);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return (day === 0 || day === 6) && selectedType?.id !== 3;
  };

  const onSubmitLeaveRequest = () => {
    let hasError = false;
    if (!formData.leaveTypeId) {
      setErrors({
        ...errors,
        leaveTypeHasError: true,
        leaveTypeErrorMessage: "Leave type is required",
      });
      hasError = true;
    }
    if (!formData.leaveReasonId) {
      setErrors({
        ...errors,
        leaveReasonHasError: true,
        leaveReasonErrorMessage: "Leave reason is required",
      });
      hasError = true;
    }
    if (!formData.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        startDateErrorMessage: "Start date is required",
      });
      hasError = true;
    }
    if (formData.numOfDays === 0) {
      setErrors({
        ...errors,
        numOfDaysHasError: true,
        numOfDaysErrorMessage: "Number of days is required",
      });
      hasError = true;
    }
    if (
      !!formData &&
      ([2, 3, 6].includes(formData.leaveTypeId) ||
        [4, 5, 7, 14, 18, 19].includes(formData.leaveReasonId)) &&
      !formData.supportingDocument
    ) {
      setErrors({
        ...errors,
        fileHasError: true,
        fileErrorMessage: "Supporting document is required",
      });
      hasError = true;
    }

    if (
      !!formData &&
      !!formData.supportingDocument &&
      formData.supportingDocument.size > 1048576
    ) {
      setErrors({
        ...errors,
        fileHasError: true,
        fileErrorMessage: "Supporting document file size cannot exceed 1MB.",
      });
      hasError = true;
    }
    if (hasError) return;

    const finaleData = new FormData();
    finaleData.append("leaveTypeId", formData.leaveTypeId);
    finaleData.append("leaveReasonId", formData.leaveReasonId);
    finaleData.append("startDate", formData.startDate);
    finaleData.append("numOfDays", formData.numOfDays);
    finaleData.append("comment", formData.comment);
    finaleData.append("leaveRequestId", formData.leaveRequestId);
    finaleData.append("supportingDocument", formData.supportingDocument);

    setIsSubmitting(true);
    createOtherLeaveRequest(
      { formData: finaleData, leaveRequestId: formData.leaveRequestId },
      (loading, res) => {
        setIsSubmitting(loading);
        if (!loading && res) {
          onClose();
        }
      }
    );
  };

  return (
    <Dialog
      // onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          {selectedRequest ? "UPDATE" : ""} OTHER LEAVE REQUEST
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      {(loadingData || isSubmitting) && <LinearProgress />}
      <DialogContent className="py-1">
        <div className="row">
          {/* leave form */}
          <div className="col-12 col-sm-6">
            <Autocomplete
              disabled={!leaveTypes.length || isSubmitting || loadingData}
              size="small"
              id="leaveTypes"
              defaultValue={null}
              value={selectedType}
              options={leaveTypes.filter(
                (type) =>
                  type.allowedDays !== 0 ||
                  type.allowedLeaves > type.takenLeaves
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, leaveType) => {
                if (leaveType) {
                  setFormData({
                    ...formData,
                    leaveTypeId: leaveType.id,
                    numOfDays: 0,
                    startDate: null,
                    leaveReasonId: "",
                    supportingDocument: null,
                  });
                  setSelectedType(leaveType);
                  setSelectedReason(null);
                  setErrors({
                    ...errors,
                    leaveTypeHasError: false,
                    leaveTypeErrorMessage: "",
                  });
                  setEndDate(null);
                  setHolidaysDuringLeavePeriod([]);
                }
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, leaveType) => (
                <Box component="li" {...props}>
                  {leaveType.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Leave type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              className="mb-3"
            />

            <Autocomplete
              disabled={!leaveReasons.length || isSubmitting || loadingData}
              size="small"
              id="leaveReasons"
              defaultValue={null}
              value={selectedReason}
              options={leaveReasons}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, leaveReason) => {
                if (leaveReason) {
                  setFormData({
                    ...formData,
                    leaveReasonId: leaveReason.id,
                    startDate: null,
                    numOfDays: leaveReason.allowedDays === 1 ? 1 : 0,
                  });
                  setSelectedReason(leaveReason);
                  setErrors({
                    ...errors,
                    leaveReasonHasError: false,
                    leaveReasonErrorMessage: "",
                  });
                  setEndDate(null);
                  setHolidaysDuringLeavePeriod([]);
                }
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, leaveReason) => (
                <Box component="li" {...props}>
                  <div>
                    <span className="d-block text-primary">
                      {leaveReason.name}{" "}
                      <span
                        className="ml-2 badge badge-secondary text-left  text-uppercase"
                        style={{ minWidth: "126px" }}
                      >
                        <span
                          className="badge badge-light bg-white text-secondary"
                          style={{ fontSize: "11px" }}
                        >
                          {leaveReason.allowedDays}
                        </span>{" "}
                        {leaveReason.isWorkingDays
                          ? "Working Days"
                          : "Calendar Days"}
                      </span>
                    </span>
                    <Divider />
                    <Divider />
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Leave Reason"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              className={`mb-${selectedReason ? 2 : 3}`}
            />

            {selectedReason && selectedReason.description && (
              <Alert
                icon={false}
                variant="outlined"
                severity="info"
                className="mb-3"
              >
                {selectedReason.description}
              </Alert>
            )}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="d-flex align-items-center mb-3">
                <DesktopDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  disabled={loading}
                  value={formData.startDate}
                  id="orderLeaveStartDate"
                  onChange={(date) => {
                    setFormData({ ...formData, startDate: date });
                    setErrors({
                      ...errors,
                      startDateHasError: false,
                      startDateErrorMessage: "",
                    });
                    setEndDate(null);
                    setHolidaysDuringLeavePeriod([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      required={true}
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        // autoComplete: "filterStatus",
                      }}
                      {...params}
                    />
                  )}
                  // shouldDisableDate={isWeekend}
                />
                <TextField
                  error={errors.numOfDaysHasError}
                  helperText={errors.numOfDaysErrorMessage}
                  fullWidth
                  size="small"
                  label="Leave Days"
                  type="number"
                  name="leaveDays"
                  variant="outlined"
                  defaultChecked
                  placeholder="Leave Days"
                  className="ml-3"
                  disabled={
                    loading ||
                    !selectedReason ||
                    !formData.startDate ||
                    isSubmitting ||
                    selectedReason?.allowedDays === 1
                  }
                  value={formData.numOfDays}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!!selectedReason?.allowedDays
                          ? `/${selectedReason?.allowedDays}`
                          : ""}
                      </InputAdornment>
                    ),
                  }}
                  onBlur={() => {
                    if (formData.numOfDays === "")
                      setFormData({
                        ...formData,
                        numOfDays: 0,
                      });
                    else if (newNumOfDays !== formData.numOfDays)
                      setNewNumOfDays(formData.numOfDays);

                    setEndDate(null);
                    setHolidaysDuringLeavePeriod([]);
                  }}
                  onKeyDown={() => {
                    if (+formData.numOfDays === 0)
                      setFormData({
                        ...formData,
                        numOfDays: "",
                      });
                  }}
                  onChange={(e) => {
                    const numOfDays = e.target.value;
                    if (
                      (+numOfDays <= selectedReason?.allowedDays ||
                        selectedReason?.allowedDays === 0) &&
                      +numOfDays >= 0
                    ) {
                      setFormData({
                        ...formData,
                        numOfDays: +numOfDays || 0,
                      });
                    }
                    setErrors({
                      ...errors,
                      numOfDaysHasError: false,
                      numOfDaysErrorMessage: "",
                    });
                  }}
                />
              </div>
            </LocalizationProvider>

            {endDate && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="d-flex align-items-center mb-3">
                  <DesktopDatePicker
                    label="End date"
                    inputFormat="dd/MM/yyyy"
                    id="otherLeaveEndDate"
                    value={endDate}
                    disabled
                    onChange={(date) => {
                      setFormData({ ...formData, endDate: date });
                      setErrors({
                        ...errors,
                        endDateHasError: false,
                        endDateErrorMessage: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => e.preventDefault()}
                        required={true}
                        fullWidth
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        {...params}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            )}

            <TextField
              error={errors.messageHasError}
              helperText={errors.messageErrorMessage}
              fullWidth
              size="small"
              multiline
              rows={4}
              name="employeeMessage"
              label="Message (Optional)"
              variant="outlined"
              placeholder="Message"
              className="mb-2"
              value={formData.comment}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFormData({ ...formData, comment: e.target.value });
                setErrors({
                  ...errors,
                  messageHasError: false,
                  messageErrorMessage: "",
                });
              }}
              disabled={loading}
            />

            {([2, 3, 6].includes(formData.leaveTypeId) ||
              [4, 5, 7, 14, 18, 19].includes(formData.leaveReasonId)) && (
              <div className="mb-3">
                <div className="mb-1" style={{ fontSize: "13px" }}>
                  <AttachFileIcon style={{ fontSize: "18px" }} /> Supporting
                  document{" "}
                  <small className="badge-secondary px-1 rounded">
                    (Required)
                  </small>{" "}
                  <small
                    style={{ fontSize: "12px" }}
                    className="font-weight-bold text-danger"
                  >
                    (Only PDF<span className="badge-danger">/1MB (max)</span> is
                    allowed)
                  </small>{" "}
                </div>
                <div className="custom-file ">
                  <input
                    type="file"
                    className={`custom-file-input ${
                      errors.fileHasError ? "is-invalid" : ""
                    }`}
                    id="supportingDocument"
                    accept="application/pdf"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      console.log(file);
                      setErrors({
                        ...errors,
                        fileHasError: false,
                        fileErrorMessage: "",
                      });
                      setFormData({
                        ...formData,
                        supportingDocument: file || null,
                      });
                    }}
                    disabled={isSubmitting}
                  />

                  <label
                    className="custom-file-label"
                    htmlFor="supportingDocument"
                  >
                    {formData.supportingDocument
                      ? formData.supportingDocument.name
                      : "Select file..."}
                  </label>
                  {errors.fileHasError && (
                    <small className="invalid-feedback mt-0">
                      <small>{errors.fileErrorMessage}</small>
                    </small>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* leave information */}
          <div className="col-12 col-sm-6">
            <div>
              <span className="font-weight-bold">Response Manager*</span>
              {!loadingController.supervisors && !!supervisors.length ? (
                <Stack direction="row" spacing={1} overflow="auto">
                  {supervisors.map((item) => (
                    <Chip
                      avatar={<Avatar>{item.supervisorNames[0]}</Avatar>}
                      label={item.supervisorNames}
                      key={item.supervisorId}
                    />
                  ))}
                </Stack>
              ) : !loadingController.supervisors ? (
                <Alert severity="warning">
                  You don't have a supervisor, Please contact your HR!
                </Alert>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem" }}
                />
              )}
            </div>

            {!!endDate && (
              <div
                className="mt-3 px-2 py-2"
                style={{
                  border: "0.12em solid #03a9f4",
                  borderRadius: "5px",
                }}
              >
                {!loadingController.holidays ? (
                  <div>
                    <span className="font-weight-bold">
                      Your request includes
                    </span>
                    {!!holidaysDuringLeavePeriod.length ? (
                      <div>
                        <div className="d-flex flex-column p-0 mt-2">
                          {holidaysDuringLeavePeriod.map((item, index) => (
                            <div className="m-0 mb-1" key={item.id}>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ fontSize: "15px" }}
                              >
                                <span>{item.name}</span>
                                <span
                                  key={item.id}
                                  style={{
                                    fontSize: "14px",
                                    color: "#0288d1",
                                    fontWeight: "400",
                                  }}
                                >
                                  {moment(item.date).format("LL")}
                                </span>
                              </div>
                              {holidaysDuringLeavePeriod.length !==
                                index + 1 && <Divider />}
                            </div>
                          ))}
                        </div>
                        <Divider className="my-3" />
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#0288d1",
                          }}
                          className="ml-2 mt-2 mx-0"
                        >
                          <span
                            style={{
                              fontWeight: "600",
                            }}
                            className="mr-1"
                          >
                            Note:
                          </span>
                          {!selectedReason?.isWorkingDays
                            ? "Public holidays above were included in leave days, calendar days counted."
                            : "Public holidays above weren't included in leave days, only working days counted."}
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                        }}
                        className="mt-2"
                      >
                        Your leave will not include any public holidays.
                      </div>
                    )}
                  </div>
                ) : (
                  <Box sx={{ minWidth: "100%" }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            onClick={onSubmitLeaveRequest}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={
              loading ||
              isSubmitting ||
              loadingData ||
              errors.numOfDaysHasError ||
              errors.startDateHasError ||
              errors.leaveTypeHasError ||
              errors.leaveReasonHasError
            }
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear, leaveTypes }) => {
  return { loading, selectedFiscalYear, leaveTypes };
};
export default connect(mapStateToProps, {
  createOtherLeaveRequest,
  geLeaveTypes,
  geGetLeaveReasons,
  calculateReturnDate,
  getHolidaysInPeriod,
  getMySupervisor,
})(OtherLeaveRequestForm);
