import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserPublications,
  deleteUserPublication,
  getPublicationTypes,
} from "../../../store/profile/actions";

import NoResults from "../../common/components/NoResults";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AlertDialog from "./AlertDialog";
import PublicationForm from "./PublicationForm";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";
import LinkIcon from "@mui/icons-material/Link";
import LockIcon from "@mui/icons-material/Lock";
export const Publications = (props) => {
  const {
    user,
    env,
    loading,
    onWait,
    userPublications,
    getUserPublications,
    deleteUserPublication,
    lookup,
    getPublicationTypes,
  } = props;

  useEffect(() => {
    getUserPublications();

    if (!lookup?.publicationTypes?.length) getPublicationTypes();
  }, []);

  const [showPublicationForm, setShowPublicationForm] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState(null);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="publications">
        <CardContent>
          {loading && !onWait && !userPublications.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Publications
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => setShowPublicationForm(true)}
                  size="small"
                >
                  <span className="d-none d-md-inline mr-1">Add</span> new
                </Button>
              </div>

              {userPublications.length === 0 ? (
                <NoResults />
              ) : (
                userPublications.map((publication, index) => (
                  <PublicationItemCard
                    key={publication.id}
                    index={index}
                    publication={publication}
                    loading={loading}
                    deleteUserPublication={deleteUserPublication}
                    setSelectedPublication={setSelectedPublication}
                    setShowPublicationForm={setShowPublicationForm}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showPublicationForm && (
        <PublicationForm
          onShow={showPublicationForm}
          onClose={() => {
            setShowPublicationForm(false);
            setSelectedPublication(null);
          }}
          selectedPublication={selectedPublication}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  env,
  loading,
  onWait,
  userPublications,
  lookup,
}) => ({
  user,
  env,
  loading,
  onWait,
  userPublications,
  lookup,
});

export default connect(mapStateToProps, {
  getUserPublications,
  deleteUserPublication,
  getPublicationTypes,
})(Publications);

const PublicationItemCard = (props) => {
  const {
    index,
    env,
    loading,
    publication,
    deleteUserPublication,
    setSelectedPublication,
    setShowPublicationForm,
  } = props;

  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card`}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {publication.publicationYear}
            </span>
          }
          title={<Typography>{publication.publicationType.name}</Typography>}
          subheader={
            <>
              {publication.title}
              <small className="d-block text-truncate">
                {!!publication.url && (
                  <a href={publication.url} target="_blank" rel="noreferrer">
                    <LinkIcon /> {publication.url}
                  </a>
                )}
              </small>
            </>
          }
          action={
            <>
              {publication.locked && (
                <IconButton
                  disableRipple
                  size="small"
                  className=""
                  aria-label="settings"
                >
                  <LockIcon />
                </IconButton>
              )}

              {(!publication.locked || env !== "prod") && (
                <>
                  <IconButton
                    size="small"
                    onClick={handleOpenMenu}
                    aria-label="settings"
                  >
                    <Badge color="info">
                      <span className="material-icons">more_vert</span>
                    </Badge>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 2,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <span>
                      <MenuItem
                        disabled={loading}
                        onClick={() => {
                          handleCloseMenu();
                          setSelectedPublication(publication);
                          setShowPublicationForm(true);
                        }}
                        className="text-dark font-weight-light"
                      >
                        <span className="material-icons mr-1">edit_note</span>
                        Edit
                      </MenuItem>
                    </span>

                    <span>
                      <MenuItem
                        disabled={loading}
                        onClick={() => {
                          handleCloseMenu();
                          setOnDelete(true);
                        }}
                        className="text-danger font-weight-light"
                      >
                        <span className="material-icons mr-1">remove</span>{" "}
                        Remove
                      </MenuItem>
                    </span>
                  </Menu>
                </>
              )}
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserPublication(publication.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this publication?"
          disabled={loading}
        />
      )}
    </>
  );
};
