import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import moment from "moment";

const ViewTrainingRequestLogs = (props) => {
  const { logs } = props;

  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            <div
              style={{
                width: "100%",
              }}
            >
              <p className="contact_p">
                <>
                  {!!logs.length && (
                    <>
                      <table style={{ marginTop: 24, marginBottom: 8 }}>
                        <tbody>
                          {logs.map((approval, index) => (
                            <>
                              <tr key={index+1}>
                                <th
                                  style={{
                                    width: "100%",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    border: "1px solid #4f81bc",
                                    backgroundColor: "#4f81bc",
                                    color: "#fff",
                                  }}
                                >
                                  {approval.entityName}
                                </th>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "100%",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    borderLeft: "1px solid #4f81bc",
                                    borderRight: "1px solid #4f81bc",
                                    borderBottom: "1px solid #4f81bc",
                                  }}
                                >
                                  <div className="row">
                                    {!!approval.reviewerName && !!approval.reviewerPositionName && (
                                      <div
                                        className="col-12 d-flex justify-content-left px-2 py-2"
                                       
                                      >
                                        <div
                                          className={`card border-${
                                            approval.reviewerStatusName ===
                                            "Pending"
                                              ? "warning"
                                              : approval.reviewerStatusName ===
                                                "Reviewed"
                                              ? "secondary"
                                              : "danger"
                                          }`}
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: 800,
                                            }}
                                            className={`card-header text-left text-${
                                              approval.reviewerStatusName ===
                                              "Pending"
                                                ? "warning"
                                                : approval.reviewerStatusName ===
                                                  "Reviewed"
                                                ? "secondary"
                                                : "danger"
                                            }`}
                                          >
                                            {approval.reviewerStatusName ===
                                            "Pending" ? (
                                              <>
                                                <PendingActionsIcon />
                                                PENDING FOR REVIEW
                                              </>
                                            ):approval.reviewerStatusName ===
                                            "Rejected" ? (
                                              <>
                                                <CancelPresentationIcon />
                                                REJECTED
                                              </>
                                            ) : (
                                              <>
                                                <TaskAltIcon />
                                                REVIEWED
                                              </>
                                            )}
                                          </div>
                                          <div className={`card-body`}>
                                            {approval.reviewerName ? (
                                              <>
                                                <Card sx={{ maxWidth: "100%" }}>
                                                  <CardHeader
                                                    avatar={
                                                      <Avatar
                                                        sx={{ bgcolor: "#ccc" }}
                                                      >
                                                        {approval.reviewerName.charAt(0).toUpperCase()}
                                                      </Avatar>
                                                    }
                                                    title={`${approval.reviewerName}`}
                                                    subheader={moment(
                                                      approval?.reviewerCreatedOn
                                                    ).format("lll")}
                                                    action={
                                                      <span className="text-primary">
                                                        {
                                                          approval.reviewerPositionName
                                                        }
                                                      </span>
                                                    }
                                                  />

                                                  <CardContent>
                                                    <Typography
                                                      style={{
                                                        marginTop: "-15px",
                                                      }}
                                                      variant="body2"
                                                      color="text.secondary"
                                                    >
                                                        <span dangerouslySetInnerHTML={{__html: approval.reviewerComments}}></span>
                                                      
                                                    </Typography>
                                                  </CardContent>
                                                </Card>
                                              </>
                                            ) : (
                                              <>
                                                <h5
                                                  className="card-title"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Pending...
                                                </h5>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {!!approval.verifierName && approval.reviewerStatusName!=='Rejected' && !!approval.verifierPositionName && (
                                      <div
                                        className="col-12 d-flex justify-content-left px-2 py-2"
                                    
                                      >
                                        <div
                                          className={`card border-${
                                            approval.verifierStatusName ===
                                            "Pending"
                                              ? "warning"
                                              : approval.verifierStatusName ===
                                                "Verified"
                                              ? "primary"
                                              : "danger"
                                          }`}
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: 800,
                                            }}
                                            className={`card-header text-${
                                              approval.verifierStatusName ===
                                              "Pending"
                                                ? "warning"
                                                : approval.verifierStatusName ===
                                                  "Verified"
                                                ? "primary"
                                                : "danger"
                                            }`}
                                          >
                                            {approval.verifierStatusName ===
                                            "Pending" ? (
                                              <>
                                                <PendingActionsIcon />
                                                PENDING FOR VERIFICATION
                                              </>
                                            ):approval.verifierStatusName === "Rejected" ? (
                                              <>
                                                <CancelPresentationIcon />
                                                REJECTED
                                              </>
                                            ) : (
                                              <>
                                                <TaskAltIcon />
                                                VERIFIED
                                              </>
                                            )}
                                          </div>
                                          <div className={`card-body`}>
                                            {approval.verifierName ? (
                                              <>
                                              <Card sx={{ maxWidth: "100%" }}>
                                                  <CardHeader
                                                    avatar={
                                                      <Avatar
                                                        sx={{ bgcolor: "#ccc" }}
                                                      >
                                                        {approval.verifierName.charAt(0).toUpperCase()}
                                                      </Avatar>
                                                    }
                                                    title={`${approval.verifierName}`}
                                                    subheader={moment(
                                                      approval?.verifierCreatedOn
                                                    ).format("lll")}
                                                    action={
                                                      <span className="text-primary">
                                                        {
                                                          approval.verifierPositionName
                                                        }
                                                      </span>
                                                    }
                                                  />

                                                  <CardContent>
                                                    <Typography
                                                      style={{
                                                        marginTop: "-15px",
                                                      }}
                                                      variant="body2"
                                                      color="text.secondary"
                                                    >
                                                      <span dangerouslySetInnerHTML={{__html: approval.verifierComments}}></span>
                                                     
                                                    </Typography>
                                                  </CardContent>
                                                </Card>
                                                
                                              </>
                                            ) : (
                                              <>
                                                <h5
                                                  className="card-title"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Pending...
                                                </h5>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {!!approval.approverName && approval.verifierStatusName!=='Rejected' && approval.reviewerStatusName!=='Rejected' && !!approval.approverPositionName && (
                                      <div
                                        className="col-12 d-flex justify-content-left px-2 py-2"
                                        
                                      >
                                        <div
                                          className={`card border-${
                                            approval.approverStatusName ===
                                            "Pending"
                                              ? "warning"
                                              : approval.approverStatusName ===
                                                "Approved"
                                              ? "success"
                                              : "danger"
                                          }`}
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: 800,
                                            }}
                                            className={`card-header text-${
                                              approval.approverStatusName ===
                                              "Pending"
                                                ? "warning"
                                                : approval.approverStatusName ===
                                                  "Approved"
                                                ? "success"
                                                : "danger"
                                            }`}
                                          >
                                            {approval.approverStatusName ===
                                            "Pending" ? (
                                              <>
                                                <PendingActionsIcon />
                                                PENDING FOR APPROVAL
                                              </>
                                            ):approval.approverStatusName ===
                                            "Rejected" ? (
                                              <>
                                                <CancelPresentationIcon />
                                               REJECTED
                                              </>
                                            ) : (
                                              <>
                                                <TaskAltIcon />
                                                APPROVED
                                              </>
                                            )}
                                          </div>
                                          
                                          <div className={`card-body`}>
                                            {approval.approverName ? (
                                              <>
                                               
                                               <Card sx={{ maxWidth: "100%" }}>
                                                  <CardHeader
                                                    avatar={
                                                      <Avatar
                                                        sx={{ bgcolor: "#ccc" }}
                                                      >
                                                        {approval.approverName.charAt(0).toUpperCase()}
                                                      </Avatar>
                                                    }
                                                    title={`${approval.approverName}`}
                                                    subheader={moment(
                                                      approval?.approverCreatedOn
                                                    ).format("lll")}
                                                    action={
                                                      <span className="text-primary">
                                                        {
                                                          approval.approverPositionName
                                                        }
                                                      </span>
                                                    }
                                                  />

                                                  <CardContent>
                                                    <Typography
                                                      style={{
                                                        marginTop: "-15px",
                                                      }}
                                                      variant="body2"
                                                      color="text.secondary"
                                                    >
                                                      <span dangerouslySetInnerHTML={{__html: approval.approverComments}}></span>
                                                    </Typography>
                                                  </CardContent>
                                                </Card>
                                                
                                              </>
                                            ) : (
                                              <>
                                                <h5
                                                  className="card-title"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Pending...
                                                </h5>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              </p>
            </div>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};

export default ViewTrainingRequestLogs;
