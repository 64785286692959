import React, { useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { connect } from "react-redux";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// import { getConvertedFileToBase64 } from "../../../store/profile/actions";

const PreviewPdfDocument = (props) => {
  const {
    showDocument,
    setShowDocument,
    document,
    docType,
    convertedFileToBase64,
    getConvertedFileToBase64,
  } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // useEffect(
  //   () => {
  //     if (docType !== "url") {
  //       getConvertedFileToBase64({
  //         attachmentId: document.attachmentId,
  //         attachmentType: document.attachmentType,
  //       });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [document]
  // );

  const onClose = () => {
    setShowDocument(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDocument}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="text-primary">
        <Typography variant="h6" noWrap component="div">
          <AttachFileIcon className="mb-1 mr-2 text-dark" />
          {document.documentName}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ height: "600px" }} dividers>
        <div style={{ height: "100%" }}>
          {docType === "url" ? (
            <iframe
              src={`${document.documentUrl}`}
              style={{ width: "100%", height: "100%" }}
              frameBorder="0"
            ></iframe>
          ) : (
            convertedFileToBase64 && (
              <iframe
                src={`${convertedFileToBase64}`}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              ></iframe>
            )
          )}
        </div>
      </DialogContent>
      {/* <DialogActions className="d-flex justify-content-center py-4"></DialogActions> */}
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading, convertedFileToBase64 }) => {
  return { user, loading, convertedFileToBase64 };
};

export default connect(mapStateToProps, {
  // getConvertedFileToBase64,
})(PreviewPdfDocument);
