import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { connect } from "react-redux";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const Breadcrumb = (props) => {
  const { user } = props;
  const location = useLocation();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center ">
        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/payroll/salary"
          className={` px-1 rounded   ${
            location.pathname.includes("/payroll/salary") ? " active  " : " "
          }`}
        >
          <span className="text-truncate  menu-item">Payroll</span>
        </Link> */}

        {/*  <HorizontalRuleIcon className="rotated" /> */}

        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/payroll/deductions"
          className={`  px-1 rounded  ${
            location.pathname.includes("/payroll/deductions")
              ? "active"
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Deductions</span>
        </Link> */}
        {/*  <HorizontalRuleIcon className="rotated" />
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/payroll/pay-history"
          className={` px-1 rounded   ${
            location.pathname.includes("/payroll/pay-history")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Pay History</span>
        </Link> */}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Breadcrumb);
