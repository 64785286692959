export const currencyFormat = (num, currency = "RWF") => {
  return (
    (Number.isInteger(num)
      ? (num || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : ((num || 0) * 1.0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")) +
    " " +
    currency
  );
};

export const totalSum = (data, colum) => {
  var total = 0;
  if (!!data.length)
    data.forEach(function (item, index) {
      total += item[colum];
    });
  return total;
};

export const textCapitalized = (text, isTextCapitalize = true) => {
  if (text) {
    const lowerText = text.toLowerCase();
    const capitalized = isTextCapitalize ? (
      <span className="text-capitalize">
        {lowerText.charAt(0).toUpperCase() + lowerText.slice(1)}
      </span>
    ) : (
      lowerText.charAt(0).toUpperCase() + lowerText.slice(1)
    );
    return capitalized;
  }
  return "";
};

export const downloadFile = (fileURL, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};

export const trainingAttachments = [
  {
    key: "SHORT_TERM",
    length: 1,
    documents: [
      {
        id: 1,
        name: "a letter of request for authorization for training by the Institution through line Ministry",
      },
      {
        id: 2,
        name: "a selection report ",
      },
      {
        id: 3,
        name: "an invitation letter and the program from the training institute or training organisers",
      },
      {
        id: 4,
        name: "an employee’s appointment letter ",
      },
      {
        id: 5,
        name: "a copy of the air ticket or Airline booking (Itinerary)",
      },
      {
        id: 6,
        name: "a copy of the National Identity Card",
      },
      {
        id: 7,
        name: "an employment contract for contractual staff ",
      },
    ],
  },
  {
    key: "LONG_TERM",
    length: 2,
    documents: [
      {
        id: 1,
        name: "a letter of request for authorization for training by the Institution through line Ministry (if applicable) through the Ministry of Education",
      },
      {
        id: 2,
        name: "a selection report ",
      },
      {
        id: 3,
        name: "a signed contract with High Education Council (HEC)",
      },
      {
        id: 4,
        name: "signed Training contract with the Employer reflecting the years the employee will serve after completion of studies.",
      },
      {
        id: 5,
        name: "admission letter from the University",
      },
      {
        id: 6,
        name: "a copy of the air ticket or Airline booking (Itinerary)",
      },
      {
        id: 7,
        name: "an employee’s Appointment letter",
      },
      {
        id: 8,
        name: "a copy of the National Identity Card",
      },
    ],
  },
];

export const titles = ["Mr", "Mrs", "Miss"];
export const employmentCategories = [
  "Permanent staff",
  "Contractual staff",
  "On Probation",
];
export const highestAcademicLevels = [
  "A2",
  "Bachelor’s level",
  "Master’s level",
  "PhD level",
];
export const trainingAreas = [
  "Infrastructure",
  "Agriculture",
  "Natural Resources",
  "Investment",
  "Trade and Industry",
  "ICT",
  "Health",
  "Education",
  "Other",
];
export const trainingCategories = [
  { id: 1, key: "SHORT_TERM", name: "Short term training" },
  { id: 2, key: "LONG_TERM", name: "Long term training" },
  { id: 3, key: "SHORT_TERM", name: "Professional Certification" },
];
export const trainingFunds = [
  "Government",
  "Development Partner",
  "Both",
  "Self-Sponsored",
  "Other",
];
export const expectedTrainingAwards = [
  "Completion certificate",
  "Professional Certificate",
  "Diploma",
  "Post graduate",
  "Master",
  "PhD",
];
export const trainingInitiators = [
  "Institution",
  "Supervising Entity/Line Ministry",
  "Employee",
  "Other",
];

export const requestStatusColor = (data = [], status = 0) => {
  const hasStatus = data.filter((row) => {
    return row.statusIds.find((id) => id === status);
  });

  return !!hasStatus.length ? hasStatus[0].className : "";
};

export const openCenteredPopup = (url, width = 550, height = 450) => {
  // Calculate the position to center the popup window on the screen
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  // Options for the popup window
  const popupOptions = `
    width=${width},
    height=${height},
    left=${left},
    top=${top},
    resizable=yes,
    scrollbars=yes
  `;

  // Open the popup window
  window.open(url, "_blank", popupOptions);
};

export const generateProbationData = (array) => {
  //   const result =array.reduce((acc, quarter) => {
  //     return acc.concat(quarter.activities);
  // }, [])
  return [
    {
      quarterId: 1,
      activities: array.flatMap((quarter) => quarter.activities),
    },
  ];
};

export const getContractActivitySummary = (data) => {
  if (!data[0]) return null;

  let totalActivities = 0;
  let contractId = null;

  data.forEach((quarter) => {
    totalActivities += quarter.activities.length;

    if (quarter.activities.length > 0 && !contractId) {
      contractId = quarter.activities[0].contractId;
    }
  });

  return {
    totalActivities: totalActivities,
    contractId: contractId,
  };
};
export const getFaultDictionaryFormat = (faults) => {
  // Extract fault names
  const faultNames = faults.map((fault) => fault.faultName);

  // Repeat fault names accordingly and format them
  const formattedFaults = faultNames.map(
    (name, index) => `${name.toLowerCase()}`
  );

  // Join the formatted fault names with 'and' for the last element
  const formattedString =
    formattedFaults.slice(0, -1).join(", ") +
    " and " +
    formattedFaults.slice(-1);
  return formattedString;
};
