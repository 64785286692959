import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MyDisciplineCases from "./pages/MyDisciplineCases";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/discipline/user-guide" component={UserGuide} />
        <Route path="/discipline/my-cases" component={MyDisciplineCases} />

        <Route
          path="/discipline"
          component={() => <Redirect to="/discipline/my-cases" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
