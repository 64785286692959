import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Tabs,
  Tab,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip
} from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import { useTheme } from "@mui/material/styles";

import { getEmployeeBankAccounts } from "../../../store/payroll/actions";
import { connect } from "react-redux";

import { currencyFormat } from "../../common/components/Utils";
import PreLoader from "../../common/components/PreLoader";

import EmployeeAllowances from "./EmployeeAllowances";
import EmployeeDeductions from "./EmployeeDeductions";
import CurrencyFormat from "react-currency-format";
import InprogressPayroll from "./InprogressPayroll";
import PayHistory from "./PayHistory";
import ContentLayout from "../../common/components/ContentLayout";

const Salary = (props) => {
  useEffect(() => {
    document.title = "Employee Portal - Payroll | Salary";
  });

  const { loading, user, employeeBankAccounts, getEmployeeBankAccounts } =
    props;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [employee, setEmployee] = useState(
    user?.currentEmployeePosition || null
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showLoader, setShowLoader] = useState(false);
  useEffect(
    () => {
      if (!employeeBankAccounts.length)
        getEmployeeBankAccounts(employee.id, setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employee.id]
  );

  const [isVerifying, setIsVerifying] = useState(false);

  const bank1 = () =>
    employeeBankAccounts.find(
      ({ isSecondAccount }) => isSecondAccount === false
    );
  const bank2 = () =>
    employeeBankAccounts.find(
      ({ isSecondAccount }) => isSecondAccount === true
    );

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-12">
            <React.Fragment>
              <div className="row mt-4">
                <div className="col-sm-12 mb-3 d-flex justify-content-center">
                  <Tabs
                    // orientation="vertical"
                    // variant="scrollable"
                    scrollButtons="auto"
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      icon={
                        <span className="material-icons mr-1">pending</span>
                      }
                      label="Payroll Inprogress"
                      {...a11yProps(0)}
                    />

                    <Tab
                      icon={
                        <span className="material-icons mr-1">payments</span>
                      }
                      label="Pay History"
                      {...a11yProps(2)}
                    />
                    <Tab
                      icon={
                        <span className="material-icons mr-1">
                          settings_applications
                        </span>
                      }
                      label="Payroll Settings"
                      {...a11yProps(3)}
                    />

                    <Tab
                      className={employee.employeeGroupId === 3 ? "d-none" : ""}
                      icon={
                        <span className="material-icons mr-1">
                          bookmark_add
                        </span>
                      }
                      label="Allowances"
                      {...a11yProps(4)}
                    />

                    <Tab
                      icon={
                        <span className="material-icons mr-1">
                          bookmark_remove
                        </span>
                      }
                      label="Deductions"
                      {...a11yProps(5)}
                    />

                    {/* <Tab
                              icon={
                                <span className="material-icons mr-1">
                                  history
                                </span>
                              }
                              label="Arrears"
                              {...a11yProps(6)}
                            />
                            {employee.employeeGroupId === 3 && (
                              <Tab
                                icon={
                                  <span className="material-icons mr-1">
                                    description
                                  </span>
                                }
                                label="Contract"
                                {...a11yProps(7)}
                              />
                            )} */}
                  </Tabs>
                </div>

                <div className="col-sm-12 ">
                  <TabPanel value={value} index={0}>
                    <React.Fragment>
                      <InprogressPayroll />
                    </React.Fragment>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <React.Fragment>
                      <div
                        className="card rounded px-3"
                        style={{
                          border: "1px solid #078ece",
                        }}
                      >
                        <PayHistory />
                      </div>
                    </React.Fragment>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <React.Fragment>
                      <div
                        className="card rounded px-3 py-3"
                        style={{
                          border: "1px solid #078ece",
                        }}
                      >
                        {(employee.employeeGroupId === 1 ||
                          employee.employeeGroupId === 2 ||
                          employee.employeeGroupId === 4) && (
                          <div
                            style={{ overflow: "auto" }}
                            className="d-flex justify-content-center  align-items-center"
                          >
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              className="bg-secondary text-light"
                              size="small"
                              label={
                                <>
                                  Level:{" "}
                                  {employee.levelName +
                                    "." +
                                    employee.scaleName}
                                </>
                              }
                            />
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>

                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={<>Index: {employee.index}</>}
                            />
                            <span className="px-2">{"X"}</span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={<>IndexValue: {employee.indexValue}</>}
                            />
                            {/* <span className="px-2">{"="}</span> */}
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              color="primary"
                              size="small"
                              label={
                                <>
                                  BasePay:{" "}
                                  {currencyFormat(
                                    employee.indexValue * employee.index
                                  )}
                                  RWF
                                </>
                              }
                            />
                          </div>
                        )}

                        <React.Fragment>
                          <div
                            className=" mt-3 p-3"
                            style={{
                              border: "1px solid #d1cdcd",
                              // backgroundColor: "#fafafa",
                            }}
                          >
                            <div className="row">
                              <div className="col-12 text-left">
                                <h6 className="mb-2">
                                  Social Security & Medical Insurance
                                </h6>
                              </div>

                              <div className="col-12 text-left">
                                <List
                                  disablePadding
                                  sx={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    backgroundColor: "transparent",
                                    // border: `1px solid #c8c6c6`,
                                  }}
                                  dense
                                  className="mt-2"
                                >
                                  <ListItem
                                    disablePadding
                                    style={{ cursor: "default" }}
                                    secondaryAction={
                                      <span className="d-flex align-items-center">
                                        {/* <button
                                      type="button"
                                      className="btn btn-secondary btn-sm py-1 px-1"
                                      disabled={loading}
                                    >
                                      <span className="material-icons">
                                        cloud_download
                                      </span>
                                    </button> */}
                                        {!!employee.rssbNumber &&
                                          !employee.rssbVerified && (
                                            <span className="d-block text-center">
                                              <span className="d-flex align-items-center text-danger">
                                                <span className="material-icons">
                                                  warning
                                                </span>
                                                <span>RSSB Not Velified</span>
                                              </span>
                                              {!!employee.rssbNumber &&
                                                !isVerifying && (
                                                  <span
                                                    className="btn btn-link btn-sm p-0 m-0"
                                                    // onClick={() =>
                                                    // doVerifyRssbNumber(
                                                    //   employee,
                                                    //   setIsVerifying
                                                    // )
                                                    // }
                                                  >
                                                    Verify now
                                                  </span>
                                                )}
                                              {isVerifying && (
                                                <span className="btn btn-link btn-sm p-0 m-0">
                                                  Verifying...
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        {!!employee.rssbNumber &&
                                          employee.rssbVerified && (
                                            <span className="d-flex align-items-center text-success">
                                              <span className="material-icons">
                                                verified
                                              </span>
                                              <span>RSSB Velified</span>
                                            </span>
                                          )}
                                      </span>
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar
                                        style={{
                                          border: "1px solid #c8c6c6",
                                          backgroundColor: "#fff",
                                        }}
                                        className=" text-dark "
                                      >
                                        <span className="material-icons">
                                          security
                                        </span>
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <span>
                                          RSSB No:{" "}
                                          {!!employee.rssbNumber && (
                                            <Chip
                                              style={{
                                                borderRadius: "0px",
                                              }}
                                              size="small"
                                              label={
                                                <strong>
                                                  {employee.rssbNumber}
                                                </strong>
                                              }
                                              variant="outlined"
                                            />
                                          )}
                                          {!employee.rssbNumber && (
                                            <span className="text-danger">
                                              --not set--
                                            </span>
                                          )}
                                        </span>
                                      }
                                      secondary={
                                        <span>
                                          Medical Insurance:{" "}
                                          <span
                                            className={`text-uppercase badge  ${
                                              employee.medicalInsuranceTypeId ===
                                              0
                                                ? "badge-danger "
                                                : "badge-primary"
                                            }`}
                                          >
                                            {employee.medicalInsuranceTypeName}
                                          </span>
                                        </span>
                                      }
                                    />
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                        <Divider />
                        {!showLoader && (
                          <React.Fragment>
                            <div
                              className=" p-3 mt-3"
                              style={{
                                border: "1px solid #d1cdcd",
                                // backgroundColor: "#fafafa",
                              }}
                            >
                              <div className="row">
                                <div className="col-12 text-left">
                                  <h6 className="mb-2">Bank accounts</h6>

                                  <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                      <List
                                        sx={{
                                          width: "100%",
                                          borderRadius: "8px",
                                          border: `1px solid #c8c6c6`,
                                        }}
                                        dense
                                        className={`${
                                          !!bank1() ? "bg-light" : ""
                                        }  mt-2`}
                                      >
                                        <ListItem
                                          style={{ cursor: "default" }}
                                          secondaryAction={
                                            <span className="d-flex align-items-center">
                                              <span className="badge badge-primary">
                                                Primary account
                                              </span>

                                              {/* <span
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      border:
                                                        "1px solid #c8c6c6",
                                                    }}
                                                    className="p-1 rounded ml-2"
                                                  >
                                                    <IconButton
                                                      size="small"
                                                      edge="end"
                                                      color="primary"
                                                      onClick={() => {
                                                        if (!!bank1())
                                                          setBankAccount(
                                                            bank1()
                                                          );
                                                        else
                                                          setBankAccount({
                                                            ...bankAccount,
                                                            isSecondAccount: false,
                                                          });

                                                        setShowAddBankAccount(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {!!bank1() && (
                                                        <span className="material-icons">
                                                          edit
                                                        </span>
                                                      )}
                                                      {!bank1() && (
                                                        <span className="material-icons">
                                                          add
                                                        </span>
                                                      )}
                                                    </IconButton>
                                                    
                                                  </span> */}
                                            </span>
                                          }
                                        >
                                          <ListItemAvatar>
                                            <Avatar
                                              style={{
                                                border: "1px solid #c8c6c6",
                                                backgroundColor: "#fff",
                                              }}
                                              className=" text-dark "
                                            >
                                              1
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={
                                              <>
                                                {bank1() && (
                                                  <Chip
                                                    style={{
                                                      borderRadius: "0px",
                                                    }}
                                                    size="small"
                                                    label={
                                                      <strong>
                                                        {bank1()?.accountNumber}
                                                      </strong>
                                                    }
                                                    variant="outlined"
                                                  />
                                                )}

                                                {!bank1() && (
                                                  <span className="lead text-danger">
                                                    --Not set--
                                                  </span>
                                                )}
                                              </>
                                            }
                                            secondary={
                                              <>
                                                {bank1() && (
                                                  <span>
                                                    {bank1()?.bank?.name}{" "}
                                                  </span>
                                                )}
                                              </>
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </div>
                                    {!!bank1() && (
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <List
                                          sx={{
                                            width: "100%",
                                            borderRadius: "8px",
                                            border: `${
                                              !!bank2()
                                                ? "1px solid #c8c6c6"
                                                : "none"
                                            } `,
                                          }}
                                          dense
                                          className={`${
                                            !!bank2() ? "bg-light" : ""
                                          }  mt-2`}
                                        >
                                          <ListItem
                                            style={{
                                              cursor: "default",
                                            }}
                                            secondaryAction={
                                              <span className="d-flex align-items-center">
                                                <span>
                                                  {!!bank2() && (
                                                    <span className="badge badge-secondary">
                                                      Secondary account
                                                    </span>
                                                  )}
                                                  {!!bank2()?.accountNumber && (
                                                    <span className="d-block text-center">
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                        className={`badge badge-light text-info`}
                                                      >
                                                        RWF
                                                      </span>

                                                      <CurrencyFormat
                                                        value={
                                                          bank2()?.amount || 0
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                      />
                                                    </span>
                                                  )}
                                                </span>
                                              </span>
                                            }
                                          >
                                            {!!bank2() && (
                                              <ListItemAvatar>
                                                <Avatar
                                                  style={{
                                                    border: "1px solid #c8c6c6",
                                                    backgroundColor: "#fff",
                                                  }}
                                                  className=" text-dark "
                                                >
                                                  2
                                                </Avatar>
                                              </ListItemAvatar>
                                            )}
                                            <ListItemText
                                              primary={
                                                <>
                                                  {bank2() && (
                                                    <Chip
                                                      size="small"
                                                      style={{
                                                        borderRadius: "0px",
                                                      }}
                                                      label={
                                                        <strong>
                                                          {
                                                            bank2()
                                                              ?.accountNumber
                                                          }
                                                        </strong>
                                                      }
                                                      variant="outlined"
                                                    />
                                                  )}

                                                  {!bank2() && (
                                                    <span className="lead d-block text-right mr-4">
                                                      --No second account--
                                                    </span>
                                                  )}
                                                </>
                                              }
                                              secondary={
                                                <>
                                                  {bank2() && (
                                                    <span>
                                                      {" "}
                                                      {bank2()?.bank?.name}{" "}
                                                    </span>
                                                  )}
                                                </>
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        <PreLoader isLoading={showLoader} />

                        <FormGroup
                          row
                          className=" mt-3 d-flex justify-content-center border border-info bg-light"
                        >
                          <FormControlLabel
                            className="mb-0"
                            style={{ cursor: "none" }}
                            control={
                              <Checkbox
                                // disabled
                                // disabled={loading}
                                disableRipple
                                style={{ cursor: "none" }}
                                checked={employee?.payTPR || false}
                              />
                            }
                            label="Pay TPR"
                          />
                          <FormControlLabel
                            className="mb-0"
                            style={{ cursor: "none" }}
                            control={
                              <Checkbox
                                // disabled
                                // disabled={loading}
                                disableRipple
                                style={{ cursor: "none" }}
                                checked={employee?.payCSR || false}
                              />
                            }
                            label="Pay CSR"
                          />
                          <FormControlLabel
                            className="mb-0"
                            style={{ cursor: "none" }}
                            control={
                              <Checkbox
                                // disabled
                                // disabled={loading}
                                disableRipple
                                style={{ cursor: "none" }}
                                checked={employee?.payMedical || false}
                              />
                            }
                            label="Pay Medical"
                          />
                          <FormControlLabel
                            className="mb-0"
                            style={{ cursor: "none" }}
                            control={
                              <Checkbox
                                // disabled
                                // disabled={loading}
                                disableRipple
                                style={{ cursor: "none" }}
                                checked={employee?.payMaternity || false}
                              />
                            }
                            label="Pay Maternity"
                          />
                          <FormControlLabel
                            className="mb-0"
                            style={{ cursor: "none" }}
                            control={
                              <Checkbox
                                // disabled
                                // disabled={loading}
                                disableRipple
                                style={{ cursor: "none" }}
                                checked={employee?.payCBHI || false}
                              />
                            }
                            label="Pay CBHI"
                          />
                        </FormGroup>
                      </div>
                    </React.Fragment>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <React.Fragment>
                      <div
                        className="card rounded px-3"
                        style={{
                          border: "1px solid #078ece",
                        }}
                      >
                        <EmployeeAllowances selectedEmployee={employee} />
                      </div>
                    </React.Fragment>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <React.Fragment>
                      <div
                        className="card rounded px-3 py-3"
                        style={{
                          border: "1px solid #078ece",
                        }}
                      >
                        <EmployeeDeductions selectedEmployee={employee} />
                      </div>
                    </React.Fragment>
                  </TabPanel>
                  {/* <TabPanel value={value} index={5}>
                            <React.Fragment>
                              <div
                                className="card rounded px-3"
                                style={{
                                  border: "1px solid #078ece",
                                }}
                              >
                                <EmployeeArrears selectedEmployee={employee} />
                              </div>
                            </React.Fragment>
                          </TabPanel> */}
                  {/* {employee.employeeGroupId === 3 && (
                            <TabPanel value={value} index={6}>
                              <React.Fragment>
                                <EmployeeContract selectedEmployee={employee} />
                              </React.Fragment>
                            </TabPanel>
                          )} */}
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  // banks,
  // medicalInsuranceTypes,
  // verifyRssbNumber,
  employeeBankAccounts,
}) => {
  return {
    loading,
    onWait,
    user,
    // banks,
    // medicalInsuranceTypes,
    // verifyRssbNumber,
    employeeBankAccounts,
  };
};
export default connect(mapStateToProps, {
  // doVerifyRssbNumber,
  // addOrUpdateMedical,
  getEmployeeBankAccounts,
  // addOrUpdateEmployeeBankAccount,
  // deleteEmployeeBankAccount,
  // getEmployeeArrears,
})(Salary);
