import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";
import logoSmall from "../../assets/logo-small.png";

import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { connect } from "react-redux";
import { logout, selectModule } from "../../../store/common/actions";
import EntitySwitcher from "./EntitySwitcher";
import { isEmpty } from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { textCapitalized } from "./Utils";
import DigitalCertificateDialog from "./DigitalCertificateDialog";

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    drawerState,
    setDrawerState,
    user,
    logout,
    ippisModules,
    selectedModule,
    selectModule,
  } = props;

  const [anchorElUser, setAnchorElUser] = useState(null);
  const userMenuOpen = Boolean(anchorElUser);
  const [showDigitalCertificateDialog, setShowDigitalCertificateDialog] =
    useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className="app-bar">
        <Toolbar className="pr-2">
          {!isEmpty(user) &&
            (location.pathname !== "/" || location.pathname !== "/home") && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                className="mr-2"
                onClick={() => {
                  setDrawerState(!drawerState);
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

          <Typography component="div" sx={{ flexGrow: 1 }} className="left">
            <img
              src={logoSmall}
              className="brand_logo mr-2 d-none d-md-inline"
              alt="Logo"
            />
            <strong>
              <span style={{ fontWeight: "bolder" }}>IPPIS</span>{" "}
            </strong>
            |{" "}
            <span
              style={{ color: "#078ece" }}
              className="d-none d-md-inline text-uppercase "
            >
              Employee Self-Service Portal
            </span>{" "}
            {/* 
            {isEmpty(selectedModule) && (
              <span className="ml-2 d-none d-md-inline text-dark text-uppercase ">
                Employee Self-Service Portal
              </span>
            )}

            {!isEmpty(selectedModule) && (
              <span className="ml-2 d-none d-md-inline text-dark text-uppercase ">
                {selectedModule.fullName}
              </span>
            )}

            {!isEmpty(selectedModule) && (
              <strong className="d-inline d-md-none text-uppercase">
                <span> {selectedModule.shortName}</span>
              </strong>
            )} */}
          </Typography>

          {!isEmpty(user) && !isEmpty(user.currentEmployeePosition) && (
            <span className="d-none d-md-inline">
              <EntitySwitcher />
            </span>
          )}

          {isEmpty(user) && isEmpty(user.currentEmployeePosition) && (
            <div className="mr-2">
              <a
                className=" my-sm-0  text-uppercase mx-1"
                rel="noreferrer"
                href="http://recruitment.mifotra.gov.rw/"
                target="_blank"
              >
                E-Recruitment
              </a>{" "}
              |{" "}
              <a
                className=" my-sm-0  text-uppercase  mx-1"
                rel="noreferrer"
                href="https://ippis.rw/"
                target="_blank"
              >
                IPPIS
              </a>{" "}
              |{" "}
              <Link
                className=" my-sm-0  text-uppercase text-primary  mx-1"
                to="#"
              >
                About IPPIS
              </Link>
            </div>
          )}

          {!isEmpty(user) && (
            <div className="roles text-primary  d-flex">
              <strong className=" d-flex ">
                <span className="role text-truncate mr-2 text-capitalize">
                  {textCapitalized(user.firstName)}
                </span>
                {/* <ArrowLeftIcon /> */}
              </strong>
            </div>
          )}
          {showDigitalCertificateDialog && (
            <DigitalCertificateDialog
              user={user}
              showDialog={showDigitalCertificateDialog}
              setShowDialog={setShowDigitalCertificateDialog}
            />
          )}
          {!isEmpty(user) && (
            <>
              <IconButton onClick={handleOpenUserMenu} className="p-1">
                {!!user.profileImage && (
                  <Avatar
                    alt="Profile picture"
                    src={`data:image/png;base64,${user.profileImage}`}
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
                {!user.profileImage && (
                  <Avatar
                    alt="Profile picture"
                    src={defaultProfile}
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorElUser}
                id="account-menu"
                open={userMenuOpen}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem disabled className="text-capitalize">
                  {textCapitalized(user.lastName)}
                </MenuItem> */}
                {/* <Divider /> */}

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/user/profile");
                  }}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    setShowDigitalCertificateDialog(true);
                  }}
                >
                  <ListItemIcon>
                    <span className="material-icons mr-1">card_membership</span>
                  </ListItemIcon>
                  My digital signature
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/user/account");
                  }}
                >
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  My account
                </MenuItem>
                <Divider />

                <MenuItem
                  className="text-danger"
                  onClick={() => {
                    handleCloseUserMenu();
                    logout(history);
                  }}
                >
                  <ListItemIcon className="text-danger">
                    <LogoutIcon />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>

              {/* <span className="d-inline d-lg-none">
                <IconButton
                  edge="start"
                  size="small"
                  color="inherit"
                  aria-label="menu"
                  className="ml-2"
                  onClick={handleOpenModulesMenu}
                >
                  <span className="material-icons">more_vert</span>
                </IconButton>
                <Menu
                  anchorEl={anchorElModules}
                  open={modulesMenuOpen}
                  onClose={handleCloseModulesMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflowY: "scroll",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>IPPIS MODULES</MenuItem>
                  <Divider />

                  {ippisModules.map((mod) => (
                    <MenuItem
                      key={mod.shortName}
                      onClick={() => {
                        handleCloseModulesMenu();
                        history.push(mod.to);
                      }}
                    >
                      <ListItemIcon>
                        <img src={mod.icon} width="20" alt="" />
                      </ListItemIcon>
                      {mod.shortName}
                    </MenuItem>
                  ))}
                </Menu>
              </span> */}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const mapStateToProps = ({
  user,
  selectedUserEntity,
  loading,
  ippisModules,
  selectedModule,
}) => {
  return { user, selectedUserEntity, loading, ippisModules, selectedModule };
};
export default connect(mapStateToProps, { logout, selectModule })(Header);
