const types = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  CLEAN_STATE: "CLEAN_STATE",
  SET_STRONG_PASSWORD: "SET_STRONG_PASSWORD",
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
  SET_SELECTED_MODULE: "SET_SELECTED_MODULE",
  SET_SELECTED_ENTITY_SECTOR: "SET_SELECTED_ENTITY_SECTOR",
  SET_IPPIS_MODULES: "SET_IPPIS_MODULES",
  SET_ENV: "SET_ENV",
  START_WAIT: "START_WAIT",
  END_WAIT: "END_WAIT",
  SET_ANALYTICS: "SET_ANALYTICS",
  SET_CURRENT_EMPLOYMENT: "SET_CURRENT_EMPLOYMENT",
};

export default types;
