import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MyTrainingRequests from "./pages/MyTrainingRequests";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/training/user-guide" component={UserGuide} />
        <Route path="/training/my-requests" component={MyTrainingRequests} />

        <Route
          path="/training"
          component={() => <Redirect to="/training/my-requests" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
