import React from "react";
import { Button, Card, Divider, Tooltip } from "@mui/material";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import CustomProgressLine from "./CustomProgressLine";

export const formatPercentage = (value, optional) => {
  const decimal =
    optional && optional.decimal !== undefined ? optional.decimal : 2;
  const showPercentage =
    optional && optional.showPercentage !== undefined
      ? optional.showPercentage
      : true;
  const formattedValue =
    value !== 100 ? (parseFloat(value) || 0).toFixed(decimal) : value;
  return formattedValue + (showPercentage ? "%" : "");
};

// loading type boolean optional
// disabled type boolean optional
// labels type
// [{ value: number, title: string, type: "number"|"percentage" }]
// buttons type optional
// [{ fun: function, title: string }]
// progress type
// { title: string, value: number(0-100), weight?:number(0,100),hasUnApprovedIndicators?:boolean }

const RBMPageHeader = ({
  labels,
  buttons,
  progress,
  loading,
  disabled,
  noShadow,
  height,
}) => {
  return (
    <>
      {/* <Card
      variant="outlined"
      elevation={0}
      className="  "
    > */}
      {/* progress */}
      <div
        className={`w-100 rounded mb-2 py-3 px-3 ${
          noShadow ? "" : "rbm-shadow"
        } `}
      >
        <CustomProgressLine
          value={progress.value}
          hasUnApprovedIndicators={progress.hasUnApprovedIndicators}
          title={progress.title}
          titleWarning={progress.titleWarning}
          weight={progress.weight}
          height={progress.height}
        />
        {/* label counts */}
        <div className="d-flex mt-2 flex-column flex-md-row justify-content-between">
          <div className="row w-100 justify-content-md-between justify-content-center align-items-center">
            {labels.map((item, index) => (
              <div className="col text-center" key={index}>
                <span className="circle-label">{item.title}</span>

                <div
                  className="circle mx-auto my-0 "
                  style={{
                    backgroundColor: "#088dce",
                    // backgroundColor: loading ? "#ddd" : "#088dce",
                  }}
                >
                  {item.type === "percentage"
                    ? formatPercentage(item.value)
                    : item.value}
                </div>
              </div>
            ))}

            {!!buttons && !!buttons[0] && (
              <div className="col">
                {buttons.map((item, index) => (
                  <div className="w-100 mt-3" key={index}>
                    <Tooltip title={item.title} placement="bottom">
                      <span
                        style={{
                          backgroundColor: "#fff",
                          width: "48px",
                        }}
                        className="p-0 text-center"
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={disabled || loading}
                          onClick={item.fun}
                          className="ml-2"
                          color="primary"
                        >
                          <span className="material-icons">download</span>
                          {item.title}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* </Card> */}
    </>
  );
};

export default RBMPageHeader;
