import eapealTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...eapealTypes };

export const getApplealTypes = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get("/api/e-appeal/appeal-types");

      dispatch({
        type: types.SET_APPEAL_TYPES,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

//my-appeals

export const getMyAppeals = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get("/api/e-appeal/my-appeals");

      dispatch({
        type: types.SET_MY_APPEALS,
        data,
      });
      dispatch({
        type: types.SET_APPEAL,
        data: !!data.length ? data[0] : null,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const submitFirstLevelAppeal = (
  formDatas,
  setFormData,
  setIsSubmitting,
  onClose,
  history
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      await axios.post("/api/e-appeal/my-appeals", formDatas);

      dispatch({ type: types.END_LOADING });
      showSuccess("Appeal to first level submitted successfully");

      setFormData({
        appealTypeId: 0,
        entitySectorId: 0,
        message: "",
        attachedFiles: [],
        tagInstitutions: [],
      });

      setIsSubmitting(false);
      history.push("/e-appeal/my-appeals");
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
//

export const submitSecondLevelAppeal = (
  formDatas,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post("/api/e-appeal/my-appeals", formDatas);
      const { appealDetails, appeal } = data;
      showSuccess("Appeal to second level submitted successfully");

      setFormData({
        appealId: 0,
        message: "",
        attachedFiles: [],
        appealTypeId:"",
        entitySectorId:"",
        level: 0,
        caseId:0,
        subject:""
      });

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      showError(error);
    }
  };
};

export const attachMoreFiles = (
  formDatas,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/e-appeal/attach-more-files",
        formDatas,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("File attached successfully");

      setFormData({
        attachedFiles: [],
      });

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data,
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const appealReaded = (applealId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/api/e-appeal/open-appeal", {
        applealId: applealId,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: data,
      });
      dispatch({
        type: types.SET_APPEAL,
        data: data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const employeeStatifiedWithDecision = (
  appealId,
  setIsSubmitting,
  setConfirmDecision
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);
      const { data } = await axios.post(
        "/api/e-appeal/employee-close-first-level-appeal",
        {
          appealId: appealId,
        }
      );
      const { appealDetails, appeal } = data;
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
      setIsSubmitting(false);
      setConfirmDecision(false);
      showSuccess("Appeal closed successfully");
    } catch (error) {
      setIsSubmitting(false);
      showError(error);
    }
  };
};

export const setAppeal = (appeal) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
    } catch (error) {}
  };
};

export const getAppealDetails = (referenceNo) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: [],
      });
      const { data } = await axios.get(
        "/api/e-appeal/appeal-details/" + referenceNo
      );

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getAppealResolved = (appealId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_APPEAL_RESOLVED,
        data: null,
      });
      const { data } = await axios.get(
        "/api/e-appeal/resolved-appeals/" + appealId
      );

      dispatch({
        type: types.SET_APPEAL_RESOLVED,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const downloadAppeal = (referenceNo, subjectL1, setIsDownloading) => {
  return async (dispatch) => {
    setIsDownloading(true);
    try {
      const { data } = await axios.get(
        "/api/e-appeal/download-appeal/" + referenceNo,
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `${subjectL1.replace(/\s/g, "_") + "_" + referenceNo}.pdf`,
        ".pdf"
      );

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      showError(error);
    }
  };
};
