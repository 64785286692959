import React from "react";
import Breadcrumb from "./Breadcrumb";
import { Link, useLocation } from "react-router-dom";
const MenuBar = () => {
  const location = useLocation();

  return (
    <>
      <nav>
        <div
          className="d-flex flex-wrap justify-content-between mt-0  align-items-center px-4"
          style={{
            backgroundColor: "#078ece",
            borderBottom: "1px solid #078ece",
            borderTop: "1px solid #078ece",
          }}
        >
          <Breadcrumb />

          
        </div>
      </nav>
      <div style={{ marginTop: "0px" }}>
        <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
        <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
      </div>
    </>
  );
};

export default MenuBar;
