import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { formatPercentage } from "./RBMPageHeader";

// progress type
// { title: string, value: number(0-100), weight?:number(0,100),hasUnApprovedIndicators?:boolean,hidePercentage?:boolean }

const CustomProgressLine = ({
  value,
  title,
  titleWarning,
  weight,
  hasUnApprovedIndicators,
  hidePercentage = false,
  height,
}) => {
  if (!title && !weight)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: hidePercentage ? 0 : 1 }}>
          <LinearProgress
            variant="determinate"
            value={value}
            color={
              value < 50
                ? "error"
                : value < 70
                ? "warning"
                : value < 90
                ? "primary"
                : "success"
            }
            style={{ borderRadius: height ? 0 : 5, height: height || 6 }}
          />
        </Box>
        {!hidePercentage ? (
          <span
            className={`text-${
              value < 50
                ? "danger"
                : value < 70
                ? "warning"
                : value < 90
                ? "primary"
                : "success"
            }`}
            style={{ fontSize: "10px", width: 35 }}
          >
            {formatPercentage(value)}
          </span>
        ) : null}
      </Box>
    );
  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{ fontSize: "12px" }}
      >
        <div
          className="d-flex align-items-center font-weight-bold"
          style={{ color: "black" }}
        >
          <div className="d-flex align-items-center">
            {title}
            {!!titleWarning && (
              <span
                className="ml-2 mb-1 badge badge-warning text-left text-uppercase"
                style={{ minWidth: "90px" }}
              >
                {titleWarning}
              </span>
            )}
          </div>
          {weight && (
            <div
              className={`d-flex align-items-center badge bg-${
                100 - +weight === 0 ? "success" : "danger"
              } ml-1`}
            >
              <i className="fas fa-weight-hanging mr-1"></i>
              {formatPercentage(weight, {
                decimal: 1,
                showPercentage: false,
              })}
            </div>
          )}
          {hasUnApprovedIndicators && (
            <Tooltip title="Some indicators are not approved" className="ml-1">
              <ErrorRoundedIcon className="text-danger" fontSize="small" />
            </Tooltip>
          )}
        </div>
        <span
          className={
            value < 50
              ? "text-danger"
              : value < 70
              ? "text-warning"
              : value < 100
              ? "text-primary"
              : "text-success"
          }
        >
          {formatPercentage(value)}
        </span>
      </div>
      <LinearProgress
        variant="determinate"
        value={value}
        color={
          value < 50
            ? "error"
            : value < 70
            ? "warning"
            : value < 100
            ? "primary"
            : "success"
        }
        style={{ borderRadius: height ? 0 : 5, height: height || 6 }}
      />
    </>
  );
};

CustomProgressLine.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.any,
  weight: PropTypes.any,
  hasUnApprovedIndicators: PropTypes.bool,
};
export default CustomProgressLine;
