import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";
import { currencyFormat } from "../../common/components/Utils";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useState } from "react";
import DownloadPayHistoryDialog from "./DownloadPayHistoryDialog";

const PayHistoryDetailDialog = (props) => {
  const {
    showPayHistoryDetail,
    setShowPayHistoryDetail,
    selectedPayHistory,
    setSelectedPayHistory,
    selectedEmployee,
    setShowDownloadPaySlip,
    disableDownload
  } = props;

  const onClose = () => {
    setShowPayHistoryDetail(false);
    setSelectedPayHistory(null);
  };
  const [showDownloadPayHistory, setShowDownloadPayHistory] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPayHistoryDetail}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-dark pb-2 ">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            Payroll Detail |{" "}
            <strong className="text-primary">
              {selectedEmployee?.lastName} {selectedEmployee?.firstName}
            </strong>{" "}
          </Typography>
          <p
            className="text-uppercase"
            style={{
              fontSize: "1rem",
              fontWeight: 600,
              letterSpacing: "2px",
              color: "#088dce",
              lineHeight: "0rem",
            }}
          >
            {selectedPayHistory.payrollType} {selectedPayHistory.month},
            {selectedPayHistory.year}
          </p>
          {!disableDownload && (
            <Tooltip title="Download">
              <IconButton
                className="p-0"
                color="primary"
                // size="large"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
                onClick={() => {
                  setShowDownloadPaySlip(true);
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </Tooltip>
          )}

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ height: "75vh" }} className="py-0">
          {showDownloadPayHistory && (
            <DownloadPayHistoryDialog
              showDownloadPayHistory={showDownloadPayHistory}
              setShowDownloadPayHistory={setShowDownloadPayHistory}
              selectedPayHistory={selectedPayHistory}
            />
          )}
          <div className="user-contact ">
            <div className="user-contact-2 border-dashed border-gray-300 border-dashed rounded">
              <div className="container mt-3">
                <h3>
                  <AccountBoxIcon />
                  <span className="ml-1">Employee Details</span>
                </h3>
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <td
                        style={{ width: "96px" }}
                        className="text-right contact_p"
                      >
                        Names:
                      </td>
                      <td className="contact_span">
                        {selectedEmployee?.lastName}{" "}
                        {selectedEmployee?.firstName}
                      </td>
                      <td rowSpan={4}>
                        <div className="d-flex  flex-column">
                          <div className="d-flex  flex-wrap justify-content-end">
                            <div className="border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1">
                              <div className="fw-semibold text-gray-400  text-center">
                                Net Lumpsum
                              </div>
                              <div className="fs-6 fw-bold text-gray-700  text-center">
                                {currencyFormat(selectedPayHistory.netLumpsum)}
                              </div>
                            </div>

                            <div className="border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1">
                              <div className="fw-semibold text-gray-400  text-center">
                                Partial Net
                              </div>
                              <div className="fs-6 fw-bold text-gray-700  text-center">
                                {currencyFormat(selectedPayHistory.partialNet)}
                              </div>
                            </div>

                            <div className="border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1">
                              <div className="fw-semibold text-gray-400  text-center">
                                Withheld
                              </div>
                              <div className="fs-6 fw-bold text-gray-700  text-center">
                                {currencyFormat(selectedPayHistory.withHolds)}
                              </div>
                            </div>

                            <div className="border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1">
                              <div className="fw-semibold text-gray-400 text-center">
                                Net Salary
                              </div>
                              <div className="fs-6 fw-bold text-gray-700  text-center">
                                {currencyFormat(selectedPayHistory.netSalary)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Level:</td>
                      <td className="contact_span">
                        {selectedPayHistory.levelName}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Index:</td>
                      <td className="contact_span">
                        {selectedPayHistory.index}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Index Value:</td>
                      <td className="contact_span">
                        {selectedPayHistory.indexValue || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Position:</td>
                      <td className="contact_span" colSpan={2}>
                        {selectedPayHistory.positionName || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Unit:</td>
                      <td className="contact_span" colSpan={2}>
                        {selectedPayHistory.unitName || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right contact_p">Institution:</td>
                      <td className="contact_span" colSpan={2}>
                        {selectedPayHistory.entityName || "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-6">
              <div className="user-contact" style={{ height: "100%" }}>
                <div
                  className="user-contact-2 border-dashed border-gray-300 border-dashed rounded"
                  style={{ height: "100%" }}
                >
                  {" "}
                  <div className="container mt-3">
                    <h3>
                      <AccountBalanceWalletIcon />
                      <span className="ml-1">Allowances</span>
                      <Chip
                        color="primary"
                        style={{ borderRadius: "0px", fontWeight: "bold" }}
                        className="float-right mr-3"
                        label={currencyFormat(
                          selectedPayHistory.grossSalary +
                            selectedPayHistory.lumpsum
                        )}
                        variant="outlined"
                      />
                    </h3>
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td
                            style={{ width: "150px" }}
                            className="contact_span text-right"
                          >
                            {currencyFormat(selectedPayHistory.basePay)}
                          </td>
                          <td className="contact_p">Base Pay</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right">
                            {currencyFormat(
                              selectedPayHistory.housingAllowance
                            )}
                          </td>
                          <td className=" contact_p">Housing</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right">
                            {currencyFormat(
                              selectedPayHistory.transportAllowance
                            )}
                          </td>
                          <td className=" contact_p">Transport</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(
                              selectedPayHistory.taxableAllowance
                            )}
                          </td>
                          <td className="contact_p">Taxable</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right">
                            {currencyFormat(
                              selectedPayHistory.exemptedAllowance
                            )}
                          </td>
                          <td className=" contact_p">Exempted</td>
                        </tr>

                        <tr>
                          <td className="contact_span text-right text-primary">
                            {currencyFormat(selectedPayHistory.grossSalary)}
                          </td>
                          <td className="contact_span text-primary">
                            Gross Salary
                          </td>
                        </tr>

                        <tr>
                          <td className="contact_span text-right text-primary">
                            {currencyFormat(selectedPayHistory.lumpsum)}
                          </td>
                          <td className="contact_span text-primary">Lumpsum</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="user-contact">
                <div className="user-contact-2  border-dashed border-gray-300 border-dashed rounded">
                  <div className="container mt-3">
                    <h3>
                      <PaymentsIcon />
                      <span className="ml-1">Deductions</span>
                      <Chip
                        color="primary"
                        style={{ borderRadius: "0px", fontWeight: "bold" }}
                        className="float-right"
                        label={currencyFormat(
                          selectedPayHistory.pensionEmployee +
                            selectedPayHistory.maternityEmployee +
                            selectedPayHistory.medicalEmployee +
                            selectedPayHistory.cbhi +
                            selectedPayHistory.tpr +
                            selectedPayHistory.tprLumpSum +
                            selectedPayHistory.withHolds
                        )}
                        variant="outlined"
                      />
                    </h3>
                    <div className="row no-gutters">
                      <div className="col-6 p-2">
                        <table
                          style={{ width: "100%" }}
                          className="table table-sm table-borderless"
                        >
                          <tbody>
                            <tr>
                              <td
                                className="text-center text-uppercase"
                                colSpan={2}
                                style={{
                                  backgroundColor: "rgb(255, 255, 255)",
                                  border: "1px solid rgb(200, 198, 198)",
                                  fontSize: "12px",
                                }}
                              >
                                Employer
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ width: "100%" }}
                                className="contact_span text-right "
                              >
                                {currencyFormat(
                                  selectedPayHistory.pensionEmployer
                                )}
                              </td>
                              <td className="contact_p">Pansion</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(
                                  selectedPayHistory.maternityEmployer
                                )}
                              </td>
                              <td className="contact_p">Maternity</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(
                                  selectedPayHistory.medicalEmployer
                                )}
                              </td>
                              <td className="contact_p">Medical</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="text-center text-primary"
                                style={{
                                  backgroundColor: "rgb(255, 255, 255)",
                                  border: "1px solid rgba(25, 118, 210, 0.7)",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {currencyFormat(
                                  selectedPayHistory.pensionEmployer +
                                    selectedPayHistory.maternityEmployer +
                                    selectedPayHistory.medicalEmployer
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-6 p-2">
                        <table
                          style={{ width: "100%" }}
                          className="table table-sm table-borderless"
                        >
                          <tbody>
                            <tr>
                              <td
                                className="text-center text-uppercase"
                                colSpan={2}
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "rgb(255, 255, 255)",
                                  border: "1px solid rgb(200, 198, 198)",
                                }}
                              >
                                Employee
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{ width: "100%" }}
                                className="contact_span text-right "
                              >
                                {currencyFormat(
                                  selectedPayHistory.pensionEmployee
                                )}
                              </td>
                              <td className="contact_p">Pansion</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(
                                  selectedPayHistory.maternityEmployee
                                )}
                              </td>
                              <td className="contact_p">Maternity</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right">
                                {currencyFormat(
                                  selectedPayHistory.medicalEmployee
                                )}
                              </td>
                              <td className="contact_p">Medical</td>
                            </tr>

                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(selectedPayHistory.cbhi)}
                              </td>
                              <td className="contact_p">Mutuelle</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(selectedPayHistory.tpr)}
                              </td>
                              <td className="contact_p">TPR</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(selectedPayHistory.tprLumpSum)}
                              </td>
                              <td className="contact_p">TPR LumpSum</td>
                            </tr>
                            <tr>
                              <td className="contact_span text-right ">
                                {currencyFormat(selectedPayHistory.withHolds)}
                              </td>
                              <td className="contact_p">Other withholds</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions className="justify-content-center py-2"></DialogActions> */}
      </Dialog>
    </>
  );
};

export default PayHistoryDetailDialog;
