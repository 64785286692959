import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import {
  getMyRequests,
  getSingleTrainingRequest,
} from "../../../store/training/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import ReactPaginate from "react-paginate";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../common/components/TableSkeleton";
import ViewTrainingRequestDialog from "../components/ViewTrainingRequestDialog";
import BackdropLoader from "../../common/components/BackdropLoader";
import { requestStatusColor } from "../../common/components/Utils";
import ContentLayout from "../../common/components/ContentLayout";

const TrainingRequestCard = (props) => {
  const {
    request,
    setShowTrainingRequestDetails,
    getSingleTrainingRequest,
    setLoading1,
    loading1,
  } = props;
  return (
    <>
      <BackdropLoader isLoading={loading1} />
      <tr
        disabled={loading1}
        className="appeal-hovered elevated"
        onClick={() => {
          if (!loading1)
            getSingleTrainingRequest(
              request.referenceNo,
              setLoading1,
              setShowTrainingRequestDetails
            );

          // setSelectedAppeal(appeal);
          // setViewAppeal(true);
        }}
      >
        <td className="text-left">
          <strong> {request.referenceNo} </strong>
        </td>

        <td className="text-left text-truncate">
          <strong className="text-truncate"> {request.trainingTitle} </strong>
        </td>

        <td className="text-left text-truncate">{request.entityName}</td>
        <td className="text-left text-truncate">
          {request.positionName} ({request.levelName}.{request.scaleName}){" "}
        </td>

        <td className="text-right text-truncate">{request.requestType}</td>

        <td className="text-right">
          {moment(request.createdOn).format("ll")}
        </td>
        <td className="text-right">
          {moment(request.departureDate).format("ll")}
        </td>
        

        <td className="text-right">
          <span
            className={`badge py-2 px-2 badge-${requestStatusColor(
              [
                {
                  className: "success",
                  statusIds: [17],
                },
                {
                  className: "danger",
                  statusIds: [4, 8, 12, 16],
                },

                {
                  className: "primary",
                  statusIds: [2, 3, 6, 7, 10, 11, 13, 14, 15],
                },
                
                {
                  className: "warning",
                  statusIds: [18,5,9,13],
                },
              ],
              request.statusId
            )}`}
          >
            {request.trainingStatusName}
          </span>
        </td>

        <td className="text-right">
          <button
            type="button"
            disabled={loading1}
            className="btn btn-success m-2"
          >
            <OpenInNewIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

const MyTrainingRequests = (props) => {
  const { myTrainingRequests, getMyRequests, getSingleTrainingRequest } = props;
  useEffect(() => {
    document.title = "Employee Portal - Training | My Requests";
  });
  const [searchTeam, setSearchTeam] = useState("");
  const [showTrainingRequestDetails, setShowTrainingRequestDetails] =
    useState(false);
  const [loading1, setLoading1] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const theme = useTheme();

  const [filteredMyRequests, setFilteredMyAppeals] = useState([
    ...myTrainingRequests,
  ]);
  const [filterTypes, setFilterTypes] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  useEffect(() => {
    getMyRequests(setShowLoader);
  }, []);

  useEffect(() => {
    const filtered = [...myTrainingRequests].filter(
      ({
        trainingTitle,
        referenceNo,
        positionName,
        entityName,
        trainingStatusName,
      }) =>
        (trainingTitle &&
          trainingTitle.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (referenceNo &&
          referenceNo.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (positionName &&
          positionName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (entityName &&
          entityName.toLowerCase().includes(searchTeam.toLowerCase())) ||
        (trainingStatusName &&
          trainingStatusName.toLowerCase().includes(searchTeam.toLowerCase()))
    );
    setFilteredMyAppeals(filtered);
  }, [searchTeam, myTrainingRequests]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredMyRequests);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, myTrainingRequests, filteredMyRequests]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredMyRequests.length || 0;

    setItemOffset(newOffset);
  };

  const filterAppealStatus = () => {
    let tempFilteredRequestStatus = [];
    myTrainingRequests.forEach((request) => {
      if (
        !tempFilteredRequestStatus.find(
          ({ name }) => name === request.trainingStatusName
        )
      )
        tempFilteredRequestStatus.push({
          id: request.trainingStatusId,
          name: request.trainingStatusName,
        });
    });
    return tempFilteredRequestStatus;
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-lg-4">
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>Training</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                My Requests ({myTrainingRequests.length})
              </span>
            </Typography>
          </div>

          <div className="col-lg-8 mt-2">
            <div className="row">
              <div className="col-lg-4 mt-1"></div>
              <div className="col-lg-4 mt-1">
                <Autocomplete
                  size="small"
                  id="filterStatus"
                  disabled={!myTrainingRequests.length}
                  defaultValue={null}
                  value={filterStatus}
                  options={filterAppealStatus()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, filterStatus) => {
                    setFilterStatus(filterStatus || null);
                    setSearchTeam("");
                    if (filterStatus) setSearchTeam(filterStatus.name);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, filterStatus) => (
                    <Box component="li" {...props} key={filterStatus.id}>
                      {filterStatus.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      {...params}
                      label="Filter By Status"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "filterStatus", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 mt-1">
                <SearchBox
                  disabled={!myTrainingRequests.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
            </div>
          </div>
        </div>
        {showTrainingRequestDetails && (
          <ViewTrainingRequestDialog
            showTrainingRequestDetails={showTrainingRequestDetails}
            setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          />
        )}
        <div
          className="mt-3"
          style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
        >
          <table className="table-striped table-hover table-sm  fixTableHead">
            {
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    Ref. No
                  </th>
                  <th scope="col" className="text-left">
                    TrainingTitle
                  </th>
                  <th scope="col" className="text-left">
                    Institution
                  </th>
                  <th scope="col" className="text-left">
                    Position
                  </th>
                  <th scope="col" className="text-right">
                    RequestType
                  </th>
                  <th scope="col" className="text-right">
                    RequestedAt
                  </th>
                  <th scope="col" className="text-right">
                    DepartureDate
                  </th>

                  <th scope="col" className="text-right">
                    Status
                  </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3">Actions</span>
                  </th>
                </tr>
              </thead>
            }

            {showLoader ? (
              <TableSkeleton cols={9} />
            ) : (
              <tbody>
                {paginatedItems.map((request, index) => (
                  <TrainingRequestCard
                    request={request}
                    index={index}
                    key={index}
                    setLoading1={setLoading1}
                    loading1={loading1}
                    setShowTrainingRequestDetails={
                      setShowTrainingRequestDetails
                    }
                    getSingleTrainingRequest={getSingleTrainingRequest}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        <NoRecordMessage
          isEmpty={!filteredMyRequests.length && !showLoader}
          title="No Training Requests found"
        />

        {!showLoader &&
          !!paginatedItems.length &&
          myTrainingRequests.length >= itemsPerPage && (
            <div
              className="justify-content-center mt-2"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, myTrainingRequests }) => {
  return { user, loading, myTrainingRequests };
};
export default connect(mapStateToProps, {
  getMyRequests,
  getSingleTrainingRequest,
})(MyTrainingRequests);
