import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getExitTypes, saveMyExitRequest } from "../../../store/exit/actions";
import { showError } from "../../../app/toastify";

const AddExitDialog = (props) => {
  const {
    user,
    loading,
    addRequestForm,
    setAddRequestForm,
    exitTypes,
    getExitTypes,
    saveMyExitRequest,
  } = props;

  const onClose = () => {
    setAddRequestForm(false);
  };

  const [exitType, setExitType] = useState(null);

  const [formData, setFormData] = useState({
    employeePositionId: user?.currentEmployeePosition.employeePositionId,
    exitTypeId: "",
    message: "",
    effectiveOn: null,
  });

  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    messageHasError: false,
    effectiveOnHasError: false,
  });

  useEffect(() => {
    getExitTypes();
  }, []);

  const formValidator = () => {
    const error = {
      exitTypeHasError: false,
      messageHasError: false,
      effectiveOnHasError: false,
    };

    if (!exitType) {
      error.exitTypeHasError = true;
      error.hasError = true;
    }
    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }

    if (!formData.effectiveOn) {
      error.effectiveOnHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveMyExitRequest(formData, setFormData, setExitType, setAddRequestForm);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addRequestForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Request New Exit
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="m-2">
            <Autocomplete
              size="small"
              id="exitType"
              defaultValue={null}
              value={exitType}
              options={exitTypes}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, exitType) => {
                setExitType(exitType || null);
                const exitTypeId = exitType ? exitType.id : "";
                setFormData({ ...formData, exitTypeId });

                const exitTypeHasError = false;
                setErrors({ ...errors, exitTypeHasError });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, exitType) => (
                <Box component="li" {...props}>
                  {exitType.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Exit Types"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "exitType", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            {errors.exitTypeHasError && (
              <div className="text-danger m-1">Exit Type is required </div>
            )}
          </div>

          <div className="m-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Effective Date"
                inputFormat="MM/dd/yyyy"
                value={formData.effectiveOn}
                onChange={(date) => {
                  const effectiveOn = date;
                  setFormData({ ...formData, effectiveOn });
                  const effectiveOnHasError = false;
                  setErrors({ ...errors, effectiveOnHasError });
                }}
                renderInput={(params) => (
                  <TextField fullWidth size="small" {...params} />
                )}
              />
            </LocalizationProvider>
            {errors.effectiveOnHasError && (
              <div className="text-danger m-1">Effective Date is required </div>
            )}
          </div>
          <div className="m-2">
            <TextField
              required
              id="outlined-required"
              label="Comments..."
              fullWidth
              onChange={(e) => {
                const message = e.target.value;
                setFormData({ ...formData, message });
                const messageHasError = false;
                setErrors({ ...errors, messageHasError });
              }}
            />
            {errors.messageHasError && (
              <div className="text-danger mt-1">Message is required </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, exitTypes }) => {
  return {
    loading,
    user,
    exitTypes,
  };
};
export default connect(mapStateToProps, {
  getExitTypes,
  saveMyExitRequest,
})(AddExitDialog);
