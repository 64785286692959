import {
  Alert,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUserReferees,
  deleteUserReferee,
} from "../../../store/profile/actions";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import NoResults from "../../common/components/NoResults";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import AlertDialog from "./AlertDialog";
import RefereeForm from "./RefereeForm";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";
import LockIcon from "@mui/icons-material/Lock";

export const Referees = (props) => {
  const {
    user,
    env,
    loading,
    onWait,
    userReferees,
    getUserReferees,
    deleteUserReferee,
  } = props;

  const [formAction, setFormAction] = useState(null);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    getUserReferees();
  }, []);

  const handleDelete = () => {
    deleteUserReferee(deleteId);
    setDeleteId("");
  };

  const [showRefereeForm, setShowRefereeForm] = useState(false);
  const [selectedReferee, setSelectedReferee] = useState(null);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="references">
        <CardContent>
          {loading && !onWait && userReferees.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Referees
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => {
                    setShowRefereeForm(true);
                  }}
                  size="small"
                >
                  <span className="d-none d-md-inline mr-1">Add</span> new
                </Button>
              </div>

              {userReferees.length === 0 ? (
                <NoResults />
              ) : (
                userReferees.map((referee, index) => (
                  <RefereeItemCard
                    key={referee.id}
                    index={index}
                    referee={referee}
                    loading={loading}
                    deleteUserReferee={deleteUserReferee}
                    setSelectedReferee={setSelectedReferee}
                    setShowRefereeForm={setShowRefereeForm}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showRefereeForm && (
        <RefereeForm
          onShow={showRefereeForm}
          onClose={() => {
            setShowRefereeForm(false);
            setSelectedReferee(null);
          }}
          selectedReferee={selectedReferee}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, env, loading, onWait, userReferees }) => ({
  user,
  env,
  loading,
  onWait,
  userReferees,
});

const mapDispatchToProps = {
  getUserReferees,
  deleteUserReferee,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referees);

const RefereeItemCard = (props) => {
  const {
    index,
    env,
    loading,
    referee,
    deleteUserReferee,
    setSelectedReferee,
    setShowRefereeForm,
  } = props;

  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card`}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          title={
            <Typography>
              {(referee.refereeFirstName.split(" ")[0] || "") +
                " " +
                (referee.refereeLastName ||
                  referee.refereeFirstName.split(" ")[1] ||
                  "")}
            </Typography>
          }
          subheader={
            <span>
              {!!referee.refereePositionName && (
                <small className="btn-link">
                  {referee.refereePositionName
                    ? referee.refereePositionName +
                      " at " +
                      referee.refereeInstitutionName
                    : "-"}
                </small>
              )}

              <div
                className="d-flex flex-column flex-md-row align-items-center-center"
                style={{ fontSize: "12px" }}
              >
                <div className="d-flex flex-row mr-3">
                  <PhoneRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <span className="font-weight-bold ml-1">
                    {referee.refereePhoneNumber}
                  </span>
                </div>
                <div className="d-flex flex-row">
                  <MailRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <a
                    href={`mailto:${referee.refereeEmail}`}
                    className="font-weight-bold ml-1 text-dark"
                  >
                    {referee.refereeEmail}
                  </a>
                </div>
              </div>
            </span>
          }
          action={
            <>
              {referee.locked && (
                <IconButton
                  disableRipple
                  size="small"
                  className="p-0"
                  aria-label="settings"
                >
                  <LockIcon />
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={handleOpenMenu}
                aria-label="settings"
              >
                <Badge color="info">
                  <span className="material-icons">more_vert</span>
                </Badge>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 2,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <span>
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      handleCloseMenu();
                      setSelectedReferee(referee);
                      setShowRefereeForm(true);
                    }}
                    className="text-dark font-weight-light"
                  >
                    <span className="material-icons mr-1">edit_note</span>
                    Edit
                  </MenuItem>
                </span>

                {(!referee.locked || env !== "prod") && (
                  <span>
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        handleCloseMenu();
                        setOnDelete(true);
                      }}
                      className="text-danger font-weight-light"
                    >
                      <span className="material-icons mr-1">remove</span> Remove
                    </MenuItem>
                  </span>
                )}
              </Menu>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserReferee(referee.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this referee?"
          disabled={loading}
        />
      )}
    </>
  );
};
