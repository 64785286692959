import React, { useState, useEffect } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Home from "./Home";
import ToastifyMessage from "./common/components/ToastifyMessage";
import OfflineMessage from "./common/components/OfflineMessage";
import TopProgressBar from "./common/components/TopProgressBar";
import Header from "./common/components/Header";

import { getEnv, getUser, getModules } from "../store/common/actions";
import Footer from "./common/components/Footer";
import loadingGif from "./assets/loading.gif";

import ProfileLayout from "./profile/Layout";
import PayrollLayout from "./payroll/Layout";
import RBMLayout from "./rbm/Layout";
import ExitLayout from "./exit/Layout";
import EappealLayout from "./e-appeal/Layout";
import TrainingLayout from "./training/Layout";

import HomeLayout from "./dashboard/Layout";

// import DefaultDrawer from "./common/components/Drawer";
// import ProfileDrawer from "./profile/components/Drawer";
import PayrollDrawer from "./payroll/components/Drawer";
import RBMDrawer from "./rbm/components/Drawer";
import ExitDrawer from "./exit/components/Drawer";
import EappealDrawer from "./e-appeal/components/Drawer";
import TrainingDrawer from "./training/components/Drawer";
import DisciplineDrawer from "./discipline/components/Drawer";
import DisciplineLayout from "./discipline/Layout";

import ModulesMenu from "./common/components/ModulesMenu";
import { isEmpty } from "lodash";
import Header2 from "./common/components/Header2";
import EnvMessage from "./common/components/EnvMessage";
import ResetPassword from "./ResetPassword";
import HRMessage from "./common/components/HRMessage";
import LeaveLayout from "./leave/Layout";
import LeaveDrawer from "./leave/components/Drawer";

function App(props) {
  const [drawerState, setDrawerState] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    env,
    getEnv,
    user,
    loading,
    getUser,
    isFetchingUser,
    getModules,
    selectedModule,
  } = props;

  useEffect(() => {
    getEnv();
    getUser(history, location);
    // getModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(user) &&
      !isFetchingUser &&
      !location.pathname.includes("/reset-password")
    )
      return history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFetchingUser && (
        <div className="loader ">
          <img src={loadingGif} height="128" alt="loading" />
        </div>
      )}
      {!isFetchingUser && (
        <div id="main" style={{ overflowX: "visible" }}>
          <TopProgressBar />

          <ToastifyMessage />

          {!!env && env !== "prod" && <EnvMessage env={env} />}

          <OfflineMessage />

          <Header drawerState={drawerState} setDrawerState={setDrawerState} />
          <HRMessage />

          {isEmpty(user) && !location.pathname.includes("/reset-password") && (
            <Header2 />
          )}

          {/* / {!isEmpty(selectedModule) && ( */}
          {/* <div className="">
          
          </div> */}
          {/* )} */}

          {!isEmpty(user) && (
            <>
              <ModulesMenu />
              {/* {location.pathname.includes("/profile") && (
                <ProfileDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )} */}

              {location.pathname.includes("/payroll") && (
                <PayrollDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.includes("/rbm") && (
                <RBMDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}
              {location.pathname.includes("/exit") && (
                <ExitDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}
              {location.pathname.includes("/e-appeal") && (
                <EappealDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.includes("/training") && (
                <TrainingDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}
              {location.pathname.includes("/discipline") && (
                <DisciplineDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.startsWith("/leave") && (
                <LeaveDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}
            </>
          )}

          <Switch>
            <Route path="/leave" component={LeaveLayout} />
            <Route path="/profile" component={ProfileLayout} />
            <Route path="/user" component={ProfileLayout} />
            <Route path="/payroll" component={PayrollLayout} />
            <Route path="/rbm" component={RBMLayout} />
            <Route path="/exit" component={ExitLayout} />
            <Route path="/e-appeal" component={EappealLayout} />
            <Route path="/training" component={TrainingLayout} />
            <Route path="/discipline" component={DisciplineLayout} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/home" component={HomeLayout} />
            <Route path="/" component={Home} />
          </Switch>

          <Footer />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({
  env,
  user,
  loading,
  isFetchingUser,
  selectedModule,
}) => {
  return { env, user, loading, isFetchingUser, selectedModule };
};
export default connect(mapStateToProps, { getEnv, getUser, getModules })(App);
