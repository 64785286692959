import { combineReducers } from "redux";

import commonReducers from "./common/reducers";

import rbmReducers from "./rbm/reducers";

import structureReducers from "./structure/reducers";

import payrollReducers from "./payroll/reducers";

import profileReducers from "./profile/reducers";
import exitReducers from "./exit/reducers";

import e_appealReducers from "./e-appeal/reducers";
import trainingReducers from "./training/reducers";
import leaveReducers from "./leave/reducers";
import disciplineReducers from "./discipline/reducers";

export default combineReducers({
  ...commonReducers,

  ...rbmReducers,

  ...structureReducers,

  ...payrollReducers,

  ...profileReducers,
  ...exitReducers,
  ...e_appealReducers,
  ...trainingReducers,
  ...leaveReducers,
  ...disciplineReducers
});
