import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const DisciplinaryRespondToExplanationLetter = (props) => {
  const {
    recipientName,
    recipientEmail,
    recipientPhone,
    recipientAddress,
    contents,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    competentAuthorityUnit = "",
    contentsInput = "",
    headOfInstitutionPosition = "",
    headOfInstitutionEntity = "",
    headOfInstitutionAddress = "",
    subjectInput = "",
    subject = "Response to Request for Explanation",
    signContent = "",
    subTitle,
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          {/* <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div> */}
          {/* <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "}
                  </p>
                </td>
              </tr>
            </table>
          </div> */}
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName} <br />
              {recipientAddress} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              Date: {moment(new Date()).format("LL")}
            </p>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              The {headOfInstitutionPosition}, <br />
              {headOfInstitutionEntity && (
                <>
                  {headOfInstitutionEntity}, <br />
                </>
              )}
              <strong
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  textTransform: "uppercase",
                }}
              >
                {headOfInstitutionAddress}
              </strong>
            </p>
          </div>
          <div className="content2 mb-2">
            {/* <!-- Letter content goes here --> */}
            <p>{subTitle},</p>
            <p style={{ fontWeight: "bold" }}>
              Re: {enableToFillLetter ? subjectInput : <>{subject}</>}
            </p>
            <p>
              {enableToFillLetter ? (
                contentsInput
              ) : (
                <>
                  {" "}
                  <pre className="pre">{contents}</pre>{" "}
                </>
              )}
            </p>
            {!enableToFillLetter && <p>Sincerely,</p>}
          </div>
          {!enableToFillLetter && (
            <>
              {signContent}
              <div className="signature2">
                {/* <!-- Signature goes here --> */}
                <p style={{ fontWeight: "bold" }}>
                  <span style={{ fontSize: "12px" }}>
                    {competentAuthorityName}
                  </span>
                  <br /> <span>{competentAuthorityPosition}</span>
                  <br /> <span>{competentAuthorityUnit}</span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DisciplinaryRespondToExplanationLetter;
