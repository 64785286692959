import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MyAppeal from "./pages/MyAppeal";
import NewAppeal from "./pages/NewAppeal";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/e-appeal/user-guide" component={UserGuide} />
        <Route path="/e-appeal/my-appeals" component={MyAppeal} />
        <Route path="/e-appeal/new-appeal" component={NewAppeal} />

        <Route
          path="/e-appeal"
          component={() => <Redirect to="/e-appeal/new-appeal" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
