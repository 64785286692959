import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import moment from "moment";

import React, {  useState } from "react";
import { connect } from "react-redux";

const ViewExitDialog = (props) => {
  const {
    showRequestAction,
    showSelectedRequest,
    setShowSelectedRequest,
    selectedRequest
  } = props;
  const [showDraftLetter, setShowDraftLetter] = useState(false);

  const onClose = () => {
    setShowSelectedRequest(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSelectedRequest}
        fullWidth
        maxWidth={
          showRequestAction === "Approve" || !!showDraftLetter ? "md" : "sm"
        }
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Exit request</span> /{" "}
            <span className="text-primary">{selectedRequest.exitType}</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          
              <table>
                <tbody>
                  
                  <tr>
                    <th scope="row">Exit request</th>
                    <td>
                      <strong className="text-primary">
                        {selectedRequest.exitType}
                      </strong>
                      {selectedRequest.exitTypeId === 0 && (
                        <>
                          <span className="text-capitalize d-block">To</span>
                          <div className="text-capitalize d-block">
                            <div>{selectedRequest.toUnitName}</div>
                            <strong className="text-dark">
                              ({selectedRequest.toPositionName})
                            </strong>
                          </div>
                        </>
                      )}
                      {selectedRequest.exitTypeId === 6 && (
                        <>
                          <div className="text-capitalize d-block">
                            <strong className="text-primary">
                              {selectedRequest.toEntityName}
                            </strong>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Reason</th>
                    <td>{selectedRequest.reason}</td>
                  </tr>
                  <tr>
                    <th scope="row">Effective on</th>
                    <td>
                      {moment(selectedRequest.effectiveDate).format("LL")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Requested on</th>
                    <td>{moment(selectedRequest.createdOn).format("LLL")}</td>
                  </tr>
                
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {selectedRequest.statusId === 1 && (
                        <>
                          <span className="badge badge-info">Pending</span>
                        </>
                      )}
                      {selectedRequest.statusId === 2 && (
                        <span className="badge badge-success">Approved</span>
                      )}
                      {selectedRequest.statusId === 3 && (
                        <span className="badge badge-danger">Rejected</span>
                      )}
                      {(selectedRequest.statusId === 1003 ||
                        selectedRequest.statusId === 1002) && (
                        <span className="badge badge-warning">Cancelled</span>
                      )}
                    </td>
                  </tr>
                  {!!selectedRequest.statusComment && (
                    <tr
                      className={`border ${
                        selectedRequest.statusId === 2
                          ? "border-success"
                          : "border-danger"
                      }`}
                    >
                      <th scope="row">Comment</th>
                      <td>{selectedRequest.statusComment}</td>
                    </tr>
                  )}
                </tbody>
              </table>
           
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading
  };
};
export default connect(mapStateToProps, {
})(ViewExitDialog);
