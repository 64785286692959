export const defaultState = {
  loading: false,
  fetchingUser: true,
  strongPassword: true,
  user: {},
  ippisModules: [],
  selectedModule: {},
  env: null,
  onWait: false,
  analytics: {
    rbm: null,
    payrolls: [],
  },
};
