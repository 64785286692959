const types = {
  SET_MY_ACTIVITIES: "SET_MY_ACTIVITIES",
  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",
  SET_MY_CONTRACTS: "SET_MY_CONTRACTS",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",
  SET_SUBMISSIONS: "SET_SUBMISSIONS",
  DELETE_SUBMISSION: "DELETE_SUBMISSION",
  SET_FISCAL_YEARS: "SET_FISCAL_YEARS",
  SET_SELECTED_FISCAL_YEAR: "SET_SELECTED_FISCAL_YEAR",
};

export default types;
