import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { addOrUpdateUserLanguage } from "../../../store/profile/actions";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";

const languageLevels = [
  {
    id: 1,
    name: "Excellent",
  },
  {
    id: 2,
    name: "Very Good",
  },
  {
    id: 3,
    name: "Good",
  },
];

const LanguageForm = (props) => {
  const {
    user,
    loading,
    selectedLanguage,
    lookup,
    addOrUpdateUserLanguage,
    onShow,
    onClose,
  } = props;

  const [language, setLanguage] = useState(null);
  const [reading, setReading] = useState("");
  const [writting, setWritting] = useState("");
  const [speaking, setSpeaking] = useState("");

  useEffect(() => {
    setLanguage(selectedLanguage?.language || null);
    setReading(selectedLanguage?.reading || "");
    setWritting(selectedLanguage?.writting || "");
    setSpeaking(selectedLanguage?.speaking || "");
  }, []);

  const [errors, setErrors] = useState({
    languageHasError: false,
    readingHasError: false,
    writtingHasError: false,
    speakingHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      languageHasError: false,
      readingHasError: false,
      writtingHasError: false,
      speakingHasError: false,
      hasError: false,
    };

    if (!language) {
      error.languageHasError = true;
      error.hasError = true;
    }

    if (!reading) {
      error.readingHasError = true;
      error.hasError = true;
    }

    if (!writting) {
      error.writtingHasError = true;
      error.hasError = true;
    }

    if (!speaking) {
      error.speakingHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!formValidator())
      addOrUpdateUserLanguage(
        {
          id: selectedLanguage?.id || "",
          languageId: language.id,
          reading,
          speaking,
          writting,
        },
        onClose
      );
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedLanguage ? "Add " : "Update "} language
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2" style={{ minHeight: "55vh" }}>
          <form>
            <div className="form-group">
              <Autocomplete
                size="small"
                name="language"
                defaultValue={null}
                value={language}
                options={lookup?.languages || []}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, language) => {
                  setLanguage(language || null);
                  // setFormError(null);
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, language) => (
                  <Box component="li" {...props} key={language.id}>
                    {language.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Select language"
                    placeholder="Select language"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "name",
                    }}
                  />
                )}
              />
              {errors.languageHasError && (
                <small className="text-danger">Language is required</small>
              )}
            </div>
            {!!language && (
              <>
                <div className="form-group border px-3 pb-3 rounded bg-light">
                  <label htmlFor="reading-level" className="col-form-label-sm">
                    Reading
                  </label>
                  <div className="mx-0 row d-flex flex-row align-items-center justify-content-between">
                    {languageLevels.map((languageLevel, index) => (
                      <div
                        className={`col-12 bg-white mb-2 col-md d-flex flex-row align-items-center cursor-pointer border w-100 mr-1 px-1 ${
                          languageLevel.name === reading
                            ? "text-primary border-primary"
                            : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setReading(languageLevel.name);
                          setErrors({ ...errors, readingHasError: false });
                        }}
                      >
                        {languageLevel.name === reading ? (
                          <RadioButtonCheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        )}{" "}
                        {languageLevel.name}
                      </div>
                    ))}
                  </div>
                  {errors.readingHasError && (
                    <small className="text-danger">Select reading level</small>
                  )}
                </div>
                <div className="form-group border px-3 pb-3 rounded bg-light">
                  <label htmlFor="reading-level" className="col-form-label-sm">
                    Writting
                  </label>
                  <div className="mx-0 row d-flex flex-row align-items-center justify-content-between">
                    {languageLevels.map((languageLevel, index) => (
                      <div
                        className={`mb-2 bg-white col-12 col-md d-flex flex-row align-items-center cursor-pointer border w-100 mr-1 px-1 ${
                          languageLevel.name === writting
                            ? "text-primary border-primary"
                            : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setWritting(languageLevel.name);
                          setErrors({ ...errors, writtingHasError: false });
                        }}
                      >
                        {languageLevel.name === writting ? (
                          <RadioButtonCheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        )}{" "}
                        {languageLevel.name}
                      </div>
                    ))}
                  </div>
                  {errors.writtingHasError && (
                    <small className="text-danger">Select writting level</small>
                  )}
                </div>
                <div className="form-group border px-3 pb-3 rounded bg-light">
                  <label htmlFor="reading-level" className="col-form-label-sm">
                    Speaking
                  </label>
                  <div className="mx-0 row d-flex flex-row align-items-center justify-content-between">
                    {languageLevels.map((languageLevel, index) => (
                      <div
                        className={`mb-2 bg-white col-12 col-md d-flex flex-row align-items-center cursor-pointer border w-100 mr-1 px-1 ${
                          languageLevel.name === speaking
                            ? "text-primary border-primary"
                            : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setSpeaking(languageLevel.name);
                          setErrors({ ...errors, speakingHasError: false });
                        }}
                      >
                        {languageLevel.name === speaking ? (
                          <RadioButtonCheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        ) : (
                          <RadioButtonUncheckedRoundedIcon
                            style={{ fontSize: "17px", marginRight: "2px" }}
                          />
                        )}{" "}
                        {languageLevel.name}
                      </div>
                    ))}
                  </div>
                  {errors.speakingHasError && (
                    <small className="text-danger">Select speaking level</small>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Wait..." : !!selectedLanguage ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading, lookup }) => ({
  user,
  loading,
  lookup,
});

export default connect(mapStateToProps, { addOrUpdateUserLanguage })(
  LanguageForm
);
