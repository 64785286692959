import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MenuBar from "./components/MenuBar";
import LeavePlan from "./pages/LeavePlan";
import OtherLeave from "./pages/OtherLeave";
import UserGuide from "./pages/UserGuide";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/leave/leave-plans" component={LeavePlan} />
        <Route path="/leave/other-leaves" component={OtherLeave} />
        {/* <Route path="/leave/home" component={Home} /> */}

        <Route path="/leave/user-guide" component={UserGuide} />

        <Route
          children={() => <Redirect to={`/leave/leave-plans`} />}
          path="/leave"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
