import leaveTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...leaveTypes };

export const getPublicHolidays = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get("/api/leave/public-holidays");

      dispatch({
        type: types.SET_PUBLIC_HOLIDAYS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const countLeaveDays = (
  { startDate, endDate, leavePlanId, isPostponed },
  cb
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/count-leave-days/${startDate}/${endDate}/${
          leavePlanId || ""
        }${leavePlanId && isPostponed ? "/1" : ""}`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const createLeavePlan = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.post(
        `/api/leave/create-leave-plan`,
        formData
      );
      if (!formData.isForPostponement && !formData.leavePlanId) {
        dispatch({
          type: types.SET_MY_LEAVE_PLANS,
          data,
        });
        showSuccess(`Leave plan created successfully`);
      } else {
        dispatch({
          type: types.CHANGE_LEAVE_PLAN_STATUS,
          data,
        });
        showSuccess(
          `${
            !!formData.isForPostponement
              ? "Leave postponed"
              : "Leave plan updated"
          } successfully`
        );
      }
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getEmployeeAnnualLeavePlan = (query, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        "/api/leave/get-employee-leave-plan?" +
          new URLSearchParams(query).toString()
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMyAnnualLeavePlan = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get("/api/leave/get-my-leave-plan");

      dispatch({
        type: types.SET_MY_LEAVE_PLANS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const cancelLeavePlan = (leavePlanId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.delete(
        `/api/leave/cancel-leave-plan/${leavePlanId}`
      );
      dispatch({
        type: types.CANCEL_LEAVE_PLAN,
        data: leavePlanId,
      });
      showSuccess("Leave plan cancelled successfully");
      dispatch({ type: types.END_LOADING });
      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const changeLeavePlanStatus = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.put(
        `/api/leave/change-leave-plan-status`,
        formData
      );
      dispatch({
        type: types.CHANGE_LEAVE_PLAN_STATUS,
        data,
      });
      showSuccess(`Action done successfully`);
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const geLeaveTypes = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(`/api/leave/leave-types`);
      cb(false);
      dispatch({
        type: types.SET_LEAVE_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const geGetLeaveReasons = (leaveTypeId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/leave-reasons/${leaveTypeId}`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const createOtherLeaveRequest = ({ formData, leaveRequestId }, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.post(
        `/api/leave/request-other-leave`,
        formData
      );
      dispatch({
        type: types.SET_MY_OTHER_LEAVE_REQUESTS,
        data,
      });
      showSuccess(
        `Leave Request ${!!leaveRequestId ? "updated" : "created"} successfully`
      );
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getMyOtherLeaveRequests = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        "/api/leave/get-my-other-leave-requests"
      );

      dispatch({
        type: types.SET_MY_OTHER_LEAVE_REQUESTS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const cancelLeaveRequest = (leaveRequestId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.delete(
        `/api/leave/cancel-other-leave/${leaveRequestId}`
      );
      dispatch({
        type: types.CANCEL_OTHER_LEAVE_REQUEST,
        data: leaveRequestId,
      });
      showSuccess("Leave request cancelled successfully");
      dispatch({ type: types.END_LOADING });
      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const geGetLeaveLogs = (leaveId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(`/api/leave/get-leave-logs/${leaveId}`);
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPostponedLeaveDetails = (leaveId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/get-postponed-leave-details/${leaveId}`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const calculateReturnDate = ({ startDate, numOfDays }, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/calculate-return-date/${startDate}/${numOfDays}`
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getHolidaysInPeriod = ({ startDate, endDate }, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/get-holidays-in-period/${startDate}/${endDate}`
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMySupervisor = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(`/api/leave/get-my-supervisor`);

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};