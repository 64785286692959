import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  addOrUpdateUserEducation,
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
} from "../../../store/profile/actions";

const getSchoolYears = (currentDate = new Date()) => {
  const d = new Date(currentDate);
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

const EducationFormDialog = (props) => {
  const {
    loading,
    onShow,
    onClose,
    selectedEducation,

    lookup,

    addOrUpdateUserEducation,
    getCountries,
    getDegrees,
    getEducationalInstitutions,
    getQualifications,
  } = props;

  const [country, setCountry] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [degree, setDegree] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [year, setYear] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!lookup.countries) getCountries();
    if (!lookup.degrees) getDegrees();

    if (!!selectedEducation) {
      setCountry(selectedEducation?.school?.country || null);
      setInstitution(selectedEducation?.school || null);
      setDegree(selectedEducation?.qualification?.degree || null);
      setQualification(selectedEducation?.qualification || null);
      setYear(
        getSchoolYears().find(
          ({ name }) => name == selectedEducation.graduationYear
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!!country) getEducationalInstitutions(country.id);
  }, [country]);

  useEffect(() => {
    if (!!degree) getQualifications(degree.id);
  }, [degree]);

  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("id", selectedEducation?.id || "");
    formData.append("schoolId", institution.id);
    formData.append("graduationYear", +year.name);
    formData.append("qualificationId", qualification.id);
    file && formData.append("file", file[0]);

    addOrUpdateUserEducation(formData, onClose);
  };

  const filterDegrees = () =>
    lookup.degrees?.filter(({ id }) => id !== 9 && id !== 10) || [];

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedEducation ? "Add " : "Update "} qualification
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-4 mt-2">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-12 col-md-6">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={country}
                  options={lookup.countries || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, country) => {
                    setCountry(country || null);
                    setInstitution(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, country) => (
                    <Box component="li" {...props} key={country.id}>
                      {country.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Choose country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "country" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="form-group col-12 col-md-6">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={institution}
                  options={lookup.educationalInstitutions || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, institute) => {
                    setInstitution(institute || null);
                    // setFormError(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, institute) => (
                    <Box component="li" {...props} key={institute.id}>
                      {institute.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Choose institution"
                      placeholder="Choose institution"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "institution" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="form-group col-12 col-md-6">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={degree}
                  options={filterDegrees()}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, degree) => {
                    setDegree(degree || null);
                    setQualification(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, degree) => (
                    <Box component="li" {...props} key={degree.id}>
                      {degree.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Choose degree"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "degree" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="form-group col-12 col-md-6">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={qualification}
                  options={lookup.qualifications || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, qualification) => {
                    setQualification(qualification || null);
                    // setFormError(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, qualification) => (
                    <Box component="li" {...props} key={qualification.id}>
                      {qualification.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Choose qualification"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "qualification" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="form-group col-12 col-md-6">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={year}
                  options={getSchoolYears()}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, year) => {
                    setYear(year || null);
                    // setFormError(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, year) => (
                    <Box component="li" {...props} key={year.id}>
                      {year.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Year of completion"
                      placeholder="Year of completion"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "year" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className={`form-group col-12 col-md-6 `}>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="educationFile"
                    onClick={(e) => {
                      e.currentTarget.value = null;
                      setFile(null);
                      // setFormError(null);
                    }}
                    onChange={(e) => setFile(e.target.files)}
                    accept="application/pdf"
                  />
                  <label className="custom-file-label" htmlFor="educationFile">
                    {file ? file[0].name : "Attach degree"}
                  </label>
                </div>
                {/* {formError && formError.type === "attachment" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={
            loading ||
            !country ||
            !institution ||
            !degree ||
            !qualification ||
            !year ||
            (!file && !selectedEducation)
          }
          onClick={handleSubmit}
        >
          {loading ? "Wait..." : !!selectedEducation ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading, onWait, lookup }) => ({
  user,
  loading,
  onWait,
  lookup,
});

export default connect(mapStateToProps, {
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
  addOrUpdateUserEducation,
})(EducationFormDialog);
