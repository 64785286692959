import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Checkbox,
  TextField,
  Autocomplete,
  Card,
  CardHeader,
  Link,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import defaultPofile from "../../assets/default-profile.jpg";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  removeDuplicates,
  textCapitalized,
} from "../../common/components/Utils";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";

import { a11yProps } from "../../common/components/TabPanel";
import ViewCaseLogs from "./ViewCaseLogs";
import ViewCaseAttachments from "./ViewCaseAttachments";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { getFaultWithSanction } from "../../../store/discipline/actions";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
// import ViewExplanationLetterDialog from "./ViewExplanationLetterDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import RespondExplanationLetterDialog from "./RespondExplanationLetterDialog";

const ViewCaseDialog = (props) => {
  const {
    showCaseDetail,
    setShowCaseDetail,
    getFaultWithSanction,
    faults,
    caseRequest,
  } = props;

  const [caseDescription, setCaseDescription] = useState(
    caseRequest?.request?.caseDescription || null
  );

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!faults.length) getFaultWithSanction();
  }, []);

  // useEffect(() => {
  //   if (!!faults.length) {
  //     let _tmpFaults = [];
  //     faults.forEach((fault) => {
  //       if (!caseRequest?.faults.find(({ faultId }) => fault.id === faultId)) {
  //         _tmpFaults.push(fault);
  //       }
  //     });
  //     setFilteredFaults(_tmpFaults);
  //   }
  // }, [caseRequest, faults]);

  useEffect(() => {
    setCaseDescription(caseRequest?.request?.caseDescription || null);
  }, [caseRequest]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowCaseDetail(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCaseDetail}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>View Case #{caseRequest?.request?.id} </h5>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col">{/* xxx */}</div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className="text-capitalize"
                  label="Details"
                  {...a11yProps(0)}
                />

                {/* <Tab
                  className="text-capitalize"
                  label="Comments"
                  {...a11yProps(1)}
                /> */}
                <Tab
                  className="text-capitalize"
                  label="Attachments
             "
                  {...a11yProps(1)}
                />
                <Tab
                  className="text-capitalize"
                  label="Administrative letters"
                  {...a11yProps(2)}
                />
              </Tabs>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          {tabValue === 0 && (
            <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <div className="card service-card valid mb-2 mt-1">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      I. Identification
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                          <div className="row mt-0">
                            <div className="col-3 justify-content-center text-uppercase text-center">
                              <div
                                className="card mb-4 pb-2"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-center">
                                  <div
                                    style={{
                                      height: "auto",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <>
                                      {caseRequest?.profilePicture && (
                                        <img
                                          className="mt-0 rounded  text-center"
                                          src={
                                            "data:image/jpg;base64," +
                                            caseRequest?.profilePicture
                                          }
                                          style={{
                                            border: "1px solid #a6d3df",
                                            height: "128px",
                                            // width: "130px",
                                            overflow: "hidden",
                                            // borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                      {!caseRequest?.profilePicture && (
                                        <img
                                          className="mt-0  rounded text-center"
                                          src={defaultPofile}
                                          style={{
                                            // border: "1px solid #a6d3df",
                                            height: "128px",
                                            width: "100px",
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </>
                                  </div>

                                  {caseRequest?.request?.isAvailable && (
                                    <span
                                      className="text-success font-weight-bolder"
                                      style={{
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    >
                                      <span
                                        className="material-icons d-block"
                                        style={{ fontSize: "48px" }}
                                      >
                                        check_circle_outline
                                      </span>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="mt-n1 d-block"
                                      >
                                        Available
                                      </span>
                                    </span>
                                  )}
                                  {!caseRequest?.request?.isAvailable && (
                                    <>
                                      <span
                                        className="text-danger font-weight-bolder"
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                        }}
                                      >
                                        <span
                                          className="material-icons d-block"
                                          style={{ fontSize: "48px" }}
                                        >
                                          highlight_off
                                        </span>
                                        <span
                                          style={{ fontSize: "12px" }}
                                          className="mt-n1 d-block"
                                        >
                                          {caseRequest?.request
                                            ?.employeeStatusId === 3 ? (
                                            <>Suspended</>
                                          ) : (
                                            <>Exited</>
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <div
                                className="card"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-left">
                                  <div className="row">
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              Employee Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.employeeId
                                                }{" "}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Names:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {caseRequest?.request
                                                  ?.employeeTitle
                                                  ? " ." +
                                                    caseRequest?.request
                                                      ?.employeeTitle
                                                  : ""}
                                                <span className="ml-1">
                                                  {textCapitalized(
                                                    caseRequest?.request
                                                      ?.lastName
                                                  )}{" "}
                                                  {textCapitalized(
                                                    caseRequest?.request
                                                      ?.firstName
                                                  )}
                                                </span>
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              DOB:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {moment(
                                                  caseRequest?.request
                                                    ?.dateOfBirth
                                                ).format("ll")}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              National Id:{" "}
                                              <span className="contact_span">
                                                {caseRequest?.request?.idNumber}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Gender:{" "}
                                              <span className="contact_span">
                                                {caseRequest?.request
                                                  ?.gender === "M"
                                                  ? "Male"
                                                  : "Female"}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Civil Status:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.civilStatus
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              PhoneNumber:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.phoneNumber
                                                }{" "}
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              Personal Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {caseRequest?.request?.email}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Other Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  caseRequest?.request
                                                    ?.professionalEmail
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Card
                                className={`rounded hoverable-card mb-2 mt-2 py-2`}
                                variant="outlined"
                                sx={{
                                  backgroundColor: `#cdeef8`,
                                  border: "none",
                                }}
                              >
                                <CardHeader
                                  className="pb-0 pt-0"
                                  avatar={
                                    <Tooltip
                                      title={
                                        <>
                                          {caseRequest?.request?.employeeTitle
                                            ? " ." +
                                              caseRequest?.request
                                                ?.employeeTitle
                                            : ""}
                                          <span className="ml-1">
                                            {textCapitalized(
                                              caseRequest?.request?.lastName
                                            )}
                                            {textCapitalized(
                                              caseRequest?.request?.firstName
                                            )}
                                          </span>
                                        </>
                                      }
                                    >
                                      <Box
                                        sx={{
                                          position: "relative",
                                          display: "inline-flex",
                                          cursor: "default",
                                        }}
                                      >
                                        <span className="material-icons text-primary mr-1">
                                          info
                                        </span>
                                      </Box>
                                    </Tooltip>
                                  }
                                  title={
                                    <span className="d-flex align-items-center flex-wrap justify-content-between ">
                                      <span className="d-flex align-items-center flex-wrap">
                                        <span className="">
                                          <span className="d-flex align-items-center  flex-wrap">
                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-primary mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalCases || 0}
                                              </span>
                                              All cases
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-warning mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalOngoing || 0}
                                              </span>
                                              Ongoing
                                            </span>
                                            <span
                                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-success mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalSanctioned || 0}
                                              </span>
                                              Sanctioned Cases
                                            </span>

                                            <span
                                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-secondary mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalClosed || 0}
                                              </span>
                                              Closed Cases
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                // borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-danger mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalRejected || 0}
                                              </span>{" "}
                                              Rejected
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  }
                                />
                              </Card>
                            </div>
                          </div>
                          {caseRequest?.request?.hasPendingExitRequest && (
                            <ErrorMessage
                              hasError={true}
                              message={<>Employee has pending exist request!</>}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Unit
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {caseRequest?.request?.unitName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Job position
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {caseRequest?.request?.positionName} (
                              {caseRequest?.request?.levelName}.
                              {caseRequest?.request?.scaleName})
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header  d-flex justify-content-between align-items-center"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      II. Faults
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <table className="table-striped table-hover table-sm  fixTableHead">
                            <thead>
                              <tr style={{ backgroundColor: "#f0f2f5" }}>
                                <th scope="col" className="text-left">
                                  Fault
                                </th>

                                <th scope="col" className="text-left">
                                  Category
                                </th>

                                <th scope="col" className="text-left">
                                  Sanction
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {caseRequest?.faults.map((fault, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    <strong> {fault.faultName} </strong>
                                  </td>
                                  <td>{fault.faultCategoryName} Level</td>
                                  <td>{fault.faultSanctionName}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      III. Description
                    </div>
                    <div className="card-body">
                      <fieldset className="form-group border p-2">
                        <legend
                          className="w-auto px-2"
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            // lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Case Description
                        </legend>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ marginTop: "-5px" }}
                        >
                          {caseDescription}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {/* {tabValue === 1 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewCaseLogs logs={caseRequest.logs} />
                </Typography>
              </React.Fragment>
            </Box>
          )} */}

          {tabValue === 1 && (
            <Box className="mt-1" sx={{ width: "100%", minHeight: "80vh" }}>
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewCaseAttachments caseId={caseRequest?.request?.id} />
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {[2].includes(tabValue) && (
            <Box className="mt-1" sx={{ width: "100%", minHeight: "80vh" }}>
              <div className="card service-card valid mb-2 mt-3">
                <div
                  className="card-header"
                  style={{ padding: "0.25rem 1.2rem" }}
                >
                  Administrative letters
                </div>
                <div className="card-body">
                  {!!caseRequest?.request?.requestExplanationDocRefId && (
                    <ViewExplanationLetterCard caseRequest={caseRequest} />
                  )}
                  {!!caseRequest?.request?.employeeResponseDocRefId && (
                    <ViewResponseToExplanationLetterCard
                      caseRequest={caseRequest}
                    />
                  )}

                  {!!caseRequest?.request?.provisionalSuspensionDocRefNo && (
                    <ProvisionalSuspensionCard caseRequest={caseRequest} />
                  )}
                  {!!caseRequest?.request?.explanationFeedbackDocRefNo && (
                    <ProvideFeedBackLetterCard caseRequest={caseRequest} />
                  )}

                  {!!caseRequest?.request?.sanctionDocReferenceNo && (
                    <SanctionLetterCard caseRequest={caseRequest} />
                  )}
                </div>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions className="py-3 mx-4 d-flex justify-content-center">
          {!caseRequest?.request?.employeeResponseDocRefId && (
            <ViewResponseToExplanationLetterCard
              faults={faults}
              onClose={onClose}
              caseRequest={caseRequest}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, caseRequest, faults }) => {
  return {
    user,
    loading,
    caseRequest,
    faults,
  };
};
export default connect(mapStateToProps, {
  getFaultWithSanction,
})(ViewCaseDialog);

const ViewExplanationLetterCard = (props) => {
  const { caseRequest } = props;

  const [onViewProvDocument, setOnViewProvDocument] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded card border-primary hoverable-card`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  Letter requesting you to provide a written explanation on the
                  administrative faults.
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.requestExplanationDocRefId && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.requestExplanationDocRefId
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `Letter requesting you to provide a written explanation on the administrative faults.`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.requestExplanationDocRefId,
            }}
          />
        )}
      </div>
    </>
  );
};

const ViewResponseToExplanationLetterCard = (props) => {
  const { caseRequest, faults, onClose } = props;

  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showRespondToExplanationLetter, setShowRespondToExplanationLetter] =
    useState(false);
  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded card border-primary hoverable-card`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong
                  className={
                    caseRequest?.request?.employeeResponseDocRefId
                      ? ""
                      : "text-primary"
                  }
                >
                  {caseRequest?.request?.employeeResponseDocRefId ? (
                    <>
                      Letter providing a response to the requested explanation
                      on the suspected disciplinary faults.
                    </>
                  ) : (
                    <>
                      Template Letter providing a written explanation on the
                      suspected disciplinary faults.
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                {showRespondToExplanationLetter && (
                  <RespondExplanationLetterDialog
                    showRespondToExplanationLetter={
                      showRespondToExplanationLetter
                    }
                    setShowRespondToExplanationLetter={
                      setShowRespondToExplanationLetter
                    }
                    caseRequest={caseRequest?.request || null}
                    caseRequestFaults={caseRequest?.faults || []}
                    faults={faults}
                    closeModal={onClose}
                  />
                )}

                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.employeeResponseDocRefId && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.employeeResponseDocRefId
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                  {!caseRequest?.request?.employeeResponseDocRefId && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2"
                        startIcon={
                          <span className="material-icons-outlined">
                            task_alt
                          </span>
                        }
                        onClick={() => {
                          setShowRespondToExplanationLetter(true);
                        }}
                      >
                        Respond
                      </Button>
                    </>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: ` My letter providing a written explanation on the suspected disciplinary faults.`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.employeeResponseDocRefId,
            }}
          />
        )}
      </div>
    </>
  );
};

const SanctionLetterCard = (props) => {
  const { caseRequest } = props;

  const [onViewProvDocument, setOnViewProvDocument] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-${
            !!caseRequest?.request?.sanctionDocReferenceNo
              ? "success"
              : "primary"
          }`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  View {caseRequest?.request?.caseSanctionName || ""} Letter
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.sanctionDocReferenceNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request?.sanctionDocReferenceNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View ${
                caseRequest?.request?.caseSanctionName || ""
              } letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.sanctionDocReferenceNo,
            }}
          />
        )}
      </div>
    </>
  );
};

const ProvideFeedBackLetterCard = (props) => {
  const { caseRequest, onClose, hasPermissions } = props;

  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showCaseReviewerStatusId, setShowCaseReviewerStatusId] = useState(0);
  const [showAction, setShowAction] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-primary`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  View feedback letter for the employee's explanation
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.explanationFeedbackDocRefNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.explanationFeedbackDocRefNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View feedback letter for the employee's explanation`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.explanationFeedbackDocRefNo,
            }}
          />
        )}
      </div>
    </>
  );
};

const ProvisionalSuspensionCard = (props) => {
  const { caseRequest } = props;
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-primary`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>View provisional suspension letter</strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.provisionalSuspensionDocRefNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.provisionalSuspensionDocRefNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View provisional suspension letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.provisionalSuspensionDocRefNo,
            }}
          />
        )}
      </div>
    </>
  );
};
