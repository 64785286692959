import { Skeleton, Chip } from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { showError } from "../../toastify";
import NumberFormat from "react-number-format";
import CurrencyFormat from "react-currency-format";

import moment from "moment";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getEmployeeAllowances } from "../../../store/payroll/actions";
const EmployeeAllowances = (props) => {
  const { selectedEmployee, employeeAllowances, getEmployeeAllowances } = props;
  const [showLoader, setShowLoader] = useState(false);
  useEffect(
    () => {
      if (!employeeAllowances.length)
        getEmployeeAllowances(
          selectedEmployee.employeePositionId,
          setShowLoader
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEmployee.id]
  );
  return (
    <>
      <div style={{ minHeight: "45vh" }}>
        <div>
          <React.Fragment>
            <div style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
              <table className="table-striped table-hover table-sm  fixTableHead mt-3">
                <thead>
                  <tr>
                    <th
                      colSpan={4}
                      style={{ textAlign: "center" }}
                      className="py-1"
                    >
                      ACTIVE ALLOWANCES
                    </th>
                  </tr>
                </thead>

                {showLoader ? (
                  <TableSkeleton cols={4} />
                ) : (
                  <tbody>
                    {employeeAllowances.map((employeeAllowance, paIndex) => (
                      <tr
                        key={paIndex}
                        style={{
                          background: paIndex % 2 === 0 ? "" : "#f6f7f7",
                          borderBottom: "2px dotted rgb(7, 142, 206)!important",
                        }}
                      >
                        <td>{paIndex + 1}</td>
                        <td style={{ width: "40%" }}>
                          {employeeAllowance.allowanceName}

                          {!!employeeAllowance.startDate && (
                            <span className="badge badge-fill text-uppercase text-primary d-block text-right mt-n2">
                              {" "}
                              <small>
                                Effective From:{" "}
                                {moment(employeeAllowance.startDate).format(
                                  "yyyy-MM-DD"
                                )}
                              </small>
                            </span>
                          )}
                        </td>
                        <td className="text-left">
                          <span>
                            <Chip
                              style={{ borderRadius: "0px" }}
                              variant="outlined"
                              className="small-chip"
                              size="small"
                              label={employeeAllowance.allowanceTypeName}
                            />
                          </span>
                        </td>

                        <td className="text-right">
                          {employeeAllowance.calculationMethodId === 1 ? (
                            <CurrencyFormat
                              value={employeeAllowance.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            <>
                              {employeeAllowance.amount}
                              {!!employeeAllowance.basePayrollComponentName && (
                                <span
                                  className="badge badge-light text-uppercase text-info"
                                  style={{ fontSize: "14px" }}
                                >
                                  {"/ " +
                                    employeeAllowance.basePayrollComponentName}
                                </span>
                              )}
                            </>
                          )}

                          <span
                            className={`badge mr-1 badge-${
                              employeeAllowance.calculationMethodId === 1
                                ? "light text-info"
                                : "secondary"
                            }`}
                          >
                            {" "}
                            {employeeAllowance.calculationMethodId === 1
                              ? "RWF"
                              : "RATE"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <NoRecordMessage
                isEmpty={!employeeAllowances.length && !showLoader}
                title="No result found"
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, employeeAllowances }) => {
  return {
    loading,
    onWait,
    employeeAllowances,
  };
};
export default connect(mapStateToProps, { getEmployeeAllowances })(
  EmployeeAllowances
);
