import React from "react";
import PropTypes from "prop-types";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

const TableSkeleton = ({ cols = 12, rows = 5, isMUI = false }) => {
  const arrayRows = Array.from(Array(rows).keys());
  const arrayCols = Array.from(Array(cols).keys());

  if (isMUI)
    return (
      <TableBody>
        {arrayRows.map((tRow) => (
          <TableRow key={tRow + Math.random()}>
            {arrayCols.map((tData) => (
              <TableCell key={tData + Math.random()}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  key={tRow + Math.random()}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  return (
    <tbody>
      {arrayRows.map((tRow) => (
        <tr key={tRow + Math.random()}>
          {arrayCols.map((tData) => (
            <td key={tData + Math.random()}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                key={tRow + Math.random()}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

TableSkeleton.propTypes = {
  cols: PropTypes.number,
  rows: PropTypes.number,
};

export default TableSkeleton;
