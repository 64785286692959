import React, { useState, useEffect } from "react";
import {
  getRehabilitations,
  deleteRehabilitation,
} from "../../../store/exit/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  IconButton,
  Badge,
  Menu,
  Button,
  Divider,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import moment from "moment";
import ConfirmationDialog from "../../rbm/components/ConfirmationDialog";
import StatusComentDialog from "../components/StatusComentDialog";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import AddRehabilitationDialog from "../components/AddRehabilitationDialog";
import PreviewPdfDocument from "../../common/components/PreviewPdfDocument";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ContentLayout from "../../common/components/ContentLayout";
import TableSkeleton from "../../common/components/TableSkeleton";
const ITEM_HEIGHT = 60;

const RehabilitationCard = (props) => {
  const {
    user,
    rehabilitation,
    index,
    setConfirmRemoveRequest,
    setSelectedRequest,
    setShowStatusComent,
    setSupportingDocument,
    setShowDocument,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <tr  key={index} className={"appeal-hovered elevated"}>
      <td>{index + 1}</td>
      <td>{rehabilitation.positionName}</td>
      <td>{rehabilitation.institutionName}</td>
      <td>
        {!!rehabilitation.blacklistCreatedOn
          ? moment(rehabilitation.blacklistCreatedOn).format("yyyy-MM-DD")
          : ""}
      </td>
      <td>
        {!!rehabilitation.rehabitationCreatedOn
          ? moment(rehabilitation.rehabitationCreatedOn).format("yyyy-MM-DD")
          : ""}
      </td>
      <td>
        {rehabilitation.rehabitationStatus === "New" && (
          <span className="badge badge-primary">
            {rehabilitation.rehabitationStatus}
          </span>
        )}
        {rehabilitation.rehabitationStatus === "Rejected" && (
          <span className="badge badge-danger">
            {rehabilitation.rehabitationStatus}
          </span>
        )}
        {rehabilitation.rehabitationStatus === "Approved" && (
          <span className="badge badge-success">
            {rehabilitation.rehabitationStatus}
          </span>
        )}
      </td>
      <td>
        <IconButton
          size="small"
          className="ml-2"
          onClick={handleOpenMenu}
          aria-label="settings"
        >
          <Badge color="info">
            <span className="material-icons">more_vert</span>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              maxHeight: ITEM_HEIGHT * 4.5,
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {rehabilitation.rehabitationStatus !== "New" && (
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedRequest(rehabilitation);
                  setShowStatusComent(true);
                }}
                className="text-dark font-weight-light"
              >
                <ListItemIcon>
                  <FeedbackIcon fontSize="small" />
                </ListItemIcon>
                Feedback
              </MenuItem>
              {user.currentEmployeePosition && <Divider className="my-1" />}
            </span>
          )}
          {user.currentEmployeePosition && (
            <span>
              <MenuItem
                disabled={rehabilitation.rehabitationStatus !== "New"}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedRequest(rehabilitation);
                  setConfirmRemoveRequest(true);
                }}
                className="text-danger font-weight-light"
              >
                <ListItemIcon>
                  <DeleteForeverRoundedIcon fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </span>
          )}
          <span>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setSupportingDocument({
                  attachmentId: rehabilitation.id,
                  documentUrl: rehabilitation.supportingDocumentUrl,
                  attachmentType: "REHABILITATION_SUPPORT_DOC",
                  documentName: "Rehabilitation Supporting Document",
                });
                setShowDocument(true);
              }}
              className="text-info font-weight-light"
            >
              <ListItemIcon>
                <VisibilityRoundedIcon fontSize="small" />
              </ListItemIcon>
              View Supporting Doc
            </MenuItem>
          </span>
        </Menu>
      </td>
    </tr>
  );
};

const Rehabilitations = (props) => {
  const {
    user,
    loading,
    rehabilitations,
    getRehabilitations,
    deleteRehabilitation,
  } = props;

  const [searchTeam, setSearchTeam] = useState("");

  const [filteredRehabilitations, setfilteredRehabilitations] = useState([
    ...rehabilitations,
  ]);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmRemoveRequest, setConfirmRemoveRequest] = useState(false);
  const [addRequestForm, setAddRequestForm] = useState(false);

  const [showStatusComent, setShowStatusComent] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [supportingDocument, setSupportingDocument] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    document.title = "Employee Portal - Exit | My Requests";
  });

  useEffect(
    () => {
      if (!rehabilitations.length) getRehabilitations(setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...rehabilitations].filter(
      ({ rehabitationStatus, positionName, institutionName }) =>
        rehabitationStatus.toLowerCase().includes(searchTeam.toLowerCase()) ||
        positionName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        institutionName.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setfilteredRehabilitations(filtered);
    paginate(filtered);
  }, [searchTeam, rehabilitations]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(
    () => {
      paginate(filteredRehabilitations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, rehabilitations, filteredRehabilitations]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredRehabilitations.length;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-lg-4">
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>Exit</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                Rehabilitations ({rehabilitations.length})
              </span>
            </Typography>
          </div>

          <div className="col-lg-8 mt-0">
            <div className="row">
              <div className="col-lg-4 mt-1"></div>
              <div className="col-lg-5 mt-1">
                <SearchBox
                  disabled={!rehabilitations.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
              <div className="col-lg-3 mt-1">
                <button
                  disabled={!user.blacklist}
                  onClick={() => {
                    setAddRequestForm(true);
                  }}
                  className="btn btn-primary  float-right"
                >
                  <i className="fas fa-plus fa-1x  mr-1"></i> New Request
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
        >
          <table className="table-striped table-hover table-sm  fixTableHead">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th className="px-1">#</th>
              <th className="px-1">Position</th>
              <th className="px-1">Institution</th>

              <th className="px-1">Blacklist End On</th>

              <th className="px-1">Created On</th>

              <th className="px-1">Status</th>

              <th className="px-1">Actions</th>
            </tr>
          </thead>
          {showLoader ? (
              <TableSkeleton cols={7} />
            ) : (
              <tbody>
              {paginatedItems.map((rehabilitation, index) => (
                <RehabilitationCard
                  user={user}
                  key={index}
                  index={index}
                  rehabilitation={rehabilitation}
                  setSelectedRequest={setSelectedRequest}
                  setConfirmRemoveRequest={setConfirmRemoveRequest}
                  setShowStatusComent={setShowStatusComent}
                  setSupportingDocument={setSupportingDocument}
                  setShowDocument={setShowDocument}
                />
              ))}
            </tbody>
            )}
         
        </table>
        <NoRecordMessage
          isEmpty={!filteredRehabilitations.length && !showLoader}
          title="No record found."
          message={user.blacklist ? "Why not" : "You are not in blacklist"}
          textLink={user.blacklist ? "request new rehabilitation?" : ""}
          onClickLink={() => {
            if (user.blacklist) setAddRequestForm(true);
          }}
        />
        <div
          className="justify-content-center mb-3"
          style={{ overflow: "hidden" }}
        >
          {!!paginatedItems.length &&
            rehabilitations.length >= itemsPerPage && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            )}
        </div>

        {addRequestForm && (
          <AddRehabilitationDialog
            addRequestForm={addRequestForm}
            setAddRequestForm={setAddRequestForm}
          />
        )}

        {showStatusComent && (
          <StatusComentDialog
            showStatusComent={showStatusComent}
            selectedRequest={selectedRequest}
            setShowStatusComent={setShowStatusComent}
          />
        )}
        {showDocument && (
          <PreviewPdfDocument
            showDocument={showDocument}
            document={supportingDocument}
            docType="url"
            setShowDocument={setShowDocument}
          />
        )}

        {confirmRemoveRequest && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveRequest}
            message={`Are you sure you want to remove this rehabilitation?`}
            setConfirmationDialog={setConfirmRemoveRequest}
            onYes={() => {
              deleteRehabilitation(
                selectedRequest,
                setConfirmRemoveRequest,
                setSelectedRequest
              );
            }}
          />
        )}
        </div>
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, rehabilitations }) => {
  return { user, loading, rehabilitations };
};
export default connect(mapStateToProps, {
  getRehabilitations,
  deleteRehabilitation,
})(Rehabilitations);
