import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getContractItems,
  downloadContractPerformanceReport,
} from "../../../store/rbm/actions";
import QuarterCard from "../components/QuarterCard";
import RBMPageHeader from "../components/RBMPageHeader";
import ReportingCard from "../components/ReportingCard";
import { generateProbationData } from "../../common/components/Utils";

const MyActivities = (props) => {
  useEffect(() => {
    document.title =
      "RBM My Contract | " +
      selectedContract.fiscalYear +
      " - " +
      selectedContract.positionName +
      " - " +
      selectedContract.contractType +
      " contract";
  }, []);

  const {
    selectedContract,
    user,
    myActivities,
    loading,
    getContractItems,
    downloadContractPerformanceReport,
  } = props;

  const totalActivities = () =>
    !!myActivities[0]
      ? (myActivities[0]?.activities || []).length +
        (myActivities[1]?.activities || []).length +
        (myActivities[2]?.activities || []).length +
        (myActivities[3]?.activities || []).length
      : 0;

  const [contractSummary, setContractSummary] = useState({
    dueActivities: 0,
    rejectedActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,
    score: 0,
  });

  const [contractItems, setContractItems] = useState([]);

  useEffect(
    () => {
      if (
        !myActivities[0] ||
        !myActivities[0]?.activities[0] ||
        (!!myActivities[0] &&
          myActivities[0]?.activities[0]?.contractId !== selectedContract.id)
      )
        getContractItems(selectedContract.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedContract]
  );

  useEffect(() => {
    if (!!selectedContract && !!myActivities[0]) {
      if ([3, 4].includes(selectedContract.contractTypeId)) {
        setContractItems(generateProbationData(myActivities));
      } else {
        setContractItems(myActivities);
      }
    }
  }, [selectedContract, myActivities]);

  useEffect(() => {
    const summary = {
      dueActivities: 0,
      rejectedActivities: 0,
      reportedActivities: 0,
      scoredActivities: 0,
      ontrackActivities: 0,
      score: 0,
    };

    myActivities.forEach((quarter) => {
      quarter.activities.forEach((activity) => {
        summary.score += activity.score;

        if (activity.statusId === 1) summary.ontrackActivities++;
        if (activity.statusId === 2) summary.dueActivities++;
        if (activity.statusId === 3) summary.reportedActivities++;
        if (activity.statusId === 4) summary.scoredActivities++;
        if (activity.statusId === 5) summary.rejectedActivities++;
      });
    });

    setContractSummary(summary);
  }, [myActivities]);

  const [contractScore, setContractScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setContractScore(selectedContract.totalScore);
    }, 500);
  }, [selectedContract]);

  console.log({ selectedContract });

  return (
    <>
      <RBMPageHeader
        labels={[]}
        buttons={[]}
        progress={{
          title: "YOUR SCORE",
          titleWarning:
            selectedContract.scoredActivities ===
              selectedContract.numOfActivities &&
            selectedContract.competencyTotalScore === 0 &&
            "Competency score not available",
          value: !loading ? contractScore : 0,
          height: 12,
        }}
      />

      {!loading && totalActivities() === 0 && (
        <div className="jumbotron jumbotron-fluid text-center">
          <p className="lead">Empty contract</p>
        </div>
      )}

      {loading && totalActivities() === 0 && (
        <>
          <Skeleton variant="rectangular" className="mb-2 mt-3" height={115} />
          <Skeleton variant="rectangular" className="mb-2" height={115} />
          <Skeleton variant="rectangular" className="mb-2" height={115} />
        </>
      )}

      {totalActivities() > 0 && (
        <ReportingCard
          title={`${totalActivities() || 0} Items`}
          overdue={contractSummary.dueActivities}
          rejected={contractSummary.rejectedActivities}
          reported={contractSummary.reportedActivities}
          scored={contractSummary.scoredActivities}
          pending={contractSummary.ontrackActivities}
          onDownload={() =>
            downloadContractPerformanceReport(selectedContract.id)
          }
        >
          {contractItems.map((quarter) => (
            <QuarterCard
              totalWeight={0}
              quarter={quarter}
              key={quarter.quarterId}
              contractTypeId={selectedContract.contractTypeId}
              contractType={selectedContract.contractType}
            />
          ))}
        </ReportingCard>
      )}
    </>
  );
};

const mapStateToProps = ({
  myActivities,
  loading,
  user,
  strongPassword,
  myActivitiesSummary,
  subordinates,
}) => {
  return {
    myActivities,
    loading,
    user,
    strongPassword,
    myActivitiesSummary,
    subordinates,
  };
};
export default connect(mapStateToProps, {
  getContractItems,
  downloadContractPerformanceReport,
})(MyActivities);
